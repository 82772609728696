import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { backIcon } from "../../../components/svg-icons";
import Ads from "../../../components/ads";
import {
  findDealers,
  setDealerFindProps,
  resetDealerFindProps,
} from "../../../store/actions";
import { Box } from "@mui/material";
import "./index.css";

const DealerResults = (props) => {
  const { dispatch, loading, dealers_found, dealers, dealers_total } = props;
  const { t, i18n } = useTranslation(["dealerSearch"]);
  const history = useHistory();
  const { city } = useParams();
  const [paginationStep, setPaginationStep] = useState(1);

  const dealersPerPage = 5;
  const totalPages = Math.ceil(dealers_found / dealersPerPage);

  const goBack = () => {
    history.goBack();
  };

  /**
   *-----------------------------------------------
   * PAGINATION LOGIC
   *-----------------------------------------------
   *
   * @param {Number} currentPage
   */
  const paginationChangeHandler = (currentPage) => {
    dispatch(
      setDealerFindProps(
        "fromCount",
        dealersPerPage * currentPage - dealersPerPage
      )
    );
    setPaginationStep(currentPage);
  };

  /**
   *-----------------------------------------------
   * GO TO dealers-ads
   *-----------------------------------------------
   * @param { Number } idad
   */
  const previewDealerHandler = (idad) => {
    history.push({
      pathname: `/${i18n.language}/results/`,
    });
  };

  useEffect(() => {
    dispatch(findDealers());
  }, [paginationStep]);

  useEffect(() => {
    return () => {
      dispatch(resetDealerFindProps());
    };
  }, []);

  useEffect(() => {
    if (city) {
      dispatch(setDealerFindProps("location", city));
    }
    dispatch(findDealers());
  }, [dispatch, city]);

  return (
    <div className="dealer-results">
      <header>
        <div onClick={goBack}>
          {backIcon}
          {/* <p className="dealers-found">{dealers_found}</p> */}
          {/* <p className="no-margin flex">{t("dealerSearch:dealers-found")}</p> */}
        </div>
      </header>
      <Box className="dealer-page-main">
        <Ads
          data={dealers}
          loading={loading}
          totalPages={totalPages}
          activePage={paginationStep}
          paginationHandler={paginationChangeHandler}
          previewAd={previewDealerHandler}
          ads_total={dealers_total}
          path="dealer"
          showHeader={false}
        ></Ads>
      </Box>
    </div>
  );
};

const stateToProps = (state) => {
  const { loading, dealers_found, dealers, dealers_total } = state.car;
  return {
    loading,
    dealers_found,
    dealers,
    dealers_total,
  };
};
export default connect(stateToProps)(DealerResults);
