import {
  //car index
  GETMAKE,
  GETMODEL,
  GETFUELTYPE,
  GETBODYTYPE,
  GETVERSION,
  CREATEAD,
  SEARCH,
  SETSEARCHPROPS,
  RESETSEARCHPROPS,
  FINDADS4,
  FINDDEALERS,
  SETFINDPROPS,
  RESETFINDPROPS,
  RESETDEALERFINDPROPS,
  SETFUELTYPECHECK,
  GETAD,
  RENDERSAVEDSEARCH,
  RESETSELECTEDAD,
  CHECKBOXCHANGEHANDLER,
  GETADSFOROWNER,
  DELETEADS,
  GETINTERIORCOLORFORLOCALE,
  GETLOCALE,
  GETBODYTYPEFORLOCALE,
  RESETCHECKBOX,
  RESETADS,
  GETCONDITIONFORLOCALE,
  GETUPHOLSTERYFORLOCALE,
  SETGENERALDATA,
  SETVEHICLEDATA,
  SETCONDITIONMAINTENANCE,
  SETOPTIONALEQUIPMENTSIDS,
  SETOPTIONALEURONORMIDS,
  SETFUELCONSUPTION,
  ISADCREATED,
  RESETCARMODEL,
  RESETREADYTOREPORT,
  GETMOTORSFORMAKEMODELFUELBODY,
  GETTRANSMISSIONFORLOCALE,
  GETBODYCOLORFORLOCALE,
  RESETCHECKBOXESINOBJECT,
  GETCARDATAFORMOTORID,
  RESETCARDATA,
  RESETMOTORS,
  SETENGINEENVIRONMENT,
  SETVEHICLELOCATION,
  SETDESCRIPTION,
  SETROOTADDATA,
  RESETADPROPS,
  // SETPUBLISHAD,
  PUBLISHAD,
  UNPUBLISHAD,
  GETADSFORIDS,
  GETDOORS,
  GETSEATS,
  GETEURONORM,
  GETTRAILERHITCH,
  GETENERGYEFFICIENCE,
  GETVEHICLESCOUNTBYBODYTYPE,
  CUSTOMSETFINDPROPS,
  REMOVECARMODEL,
  REPORTAD,
  FETCHEPAPER,
  GETCARSOFTHEDAY,
  GETDRIVETYPE,
  FETCHARTICLES,
  GETCYLINDERS,
  GETTRAILERLOAD,
  GETOPT,
  SETDEALERFINDPROPS,
  GETMERCHANT2,
  GETVEHICLESFORSERIALNO,
  GETVEHICLESFORREGDOCNO,
  RESETERRORS18AND24,
  SET_TARGA_VEHICLE_TYPE,
  RESETVEHICLEDATA18AND24,
  SET_ROOT_DATA_PROPS,
  GETENGINETYPE,
  GETENVIRONMENTALSTICKER,
} from "./constants";

// car index

export const setSearchProps = (key, value) => ({
  type: SETSEARCHPROPS,
  key,
  value,
});

export const resetSearchProps = () => ({ type: RESETSEARCHPROPS });

export const customSetFindProps = (key, value) => ({
  type: CUSTOMSETFINDPROPS,
  key,
  value,
});

export const setDealerFindProps = (key, value) => ({
  type: SETDEALERFINDPROPS,
  key,
  value,
});

export const removeCarModel = (index) => ({
  type: REMOVECARMODEL,
  index,
});

export const setFindProps = (key, value, valueType) => ({
  type: SETFINDPROPS,
  key,
  value,
  valueType,
});

export const resetFindProps = () => ({ type: RESETFINDPROPS });

export const resetDealerFindProps = () => ({ type: RESETDEALERFINDPROPS });

export const resetCarModel = (index = null) => ({ type: RESETCARMODEL, index });

export const resetReadyToReport = () => ({
  type: RESETREADYTOREPORT,
});

export const changeFueltype = (idfueltype) => ({
  type: SETFUELTYPECHECK,
  idfueltype: idfueltype,
});

export const renderSavedSearch = (search_data) => ({
  type: RENDERSAVEDSEARCH,
  search_data,
});

export const resetSelectedAd = () => ({ type: RESETSELECTEDAD });

export const checkboxChange = (arrayName, id, propName) => ({
  type: CHECKBOXCHANGEHANDLER,
  arrayName,
  id,
  propName,
});

export const resetCheckbox = (arrayName) => ({
  type: RESETCHECKBOX,
  arrayName,
});

export const resetAds = () => ({ type: RESETADS });

export const isAdCreated = (payload) => ({ type: ISADCREATED, payload });

export const resetCheckboxesInObject = (objectName) => ({
  type: RESETCHECKBOXESINOBJECT,
  objectName,
});

export const resetAdProps = () => ({ type: RESETADPROPS });

export const resetCarData = () => ({ type: RESETCARDATA });

export const resetMotors = () => ({ type: RESETMOTORS });

// export const setPublishAd = (boolean) => ({ type: SETPUBLISHAD, boolean });

// Set ad data
export const setRootAdData = (params) => ({ type: SETROOTADDATA, params });

export const setRootDataProps = (params) => ({
  type: SET_ROOT_DATA_PROPS,
  params,
});

export const setGeneralData = (params) => ({ type: SETGENERALDATA, params });

export const setVehicleData = (params) => ({ type: SETVEHICLEDATA, params });

export const setConditionMaintenance = (params) => ({
  type: SETCONDITIONMAINTENANCE,
  params,
});

export const setOptionalEquipmentIds = (params) => ({
  type: SETOPTIONALEQUIPMENTSIDS,
  params,
});

export const setOptionalEuroNorm = (params) => ({
  type: SETOPTIONALEURONORMIDS,
  params,
});

export const setEngineEnviroment = (params) => ({
  type: SETENGINEENVIRONMENT,
  params,
});

export const setVehicleLocation = (params) => ({
  type: SETVEHICLELOCATION,
  params,
});

export const setFuelConsuption = (params) => ({
  type: SETFUELCONSUPTION,
  params,
});

export const setAdDescription = (description) => ({
  type: SETDESCRIPTION,
  description,
});

// socket
export const createAd = (adData, photosLength, adImages) => ({
  type: CREATEAD,
  adData,
  photosLength,
  adImages,
});

export const publishAd = (idad) => ({ type: PUBLISHAD, idad });

export const unpublishAd = (idad) => ({ type: UNPUBLISHAD, idad });

export const deleteAds = (idad) => ({ type: DELETEADS, idad });

export const searchAds = () => ({ type: SEARCH });

export const findAds4 = () => ({ type: FINDADS4 });

export const findDealers = (payload) => ({
  type: FINDDEALERS,
  payload,
});

export const getAdsForOwner = () => ({ type: GETADSFOROWNER });

export const getAdById = (idad) => ({ type: GETAD, idad });

export const getMerchantByUid = (uid) => ({ type: GETMERCHANT2, uid });

export const getLocale = () => ({ type: GETLOCALE });

export const getMake = () => ({ type: GETMAKE });

export const getCarModel = (id, index = null, bothModel = true) => ({
  type: GETMODEL,
  idmake: id,
  index,
  bothModel,
});

export const getFuelType = () => ({ type: GETFUELTYPE });

export const getBodyType = () => ({ type: GETBODYTYPE });

export const getBodyTypeForLocale = () => ({ type: GETBODYTYPEFORLOCALE });

export const getConditionForLocale = () => ({ type: GETCONDITIONFORLOCALE });

export const getUpholsteryForLocale = () => ({ type: GETUPHOLSTERYFORLOCALE });

export const getInteriorColorForLocale = () => ({
  type: GETINTERIORCOLORFORLOCALE,
});

export const getTransmissionForLocale = () => ({
  type: GETTRANSMISSIONFORLOCALE,
});

export const getBodyColorForLocale = () => ({
  type: GETBODYCOLORFORLOCALE,
});

export const getVersion = (params) => ({
  type: GETVERSION,
  idmake: params.idmake,
  idmodel: params.idmodel,
});

export const getMotorsForMakeModelFuelBody = (params) => ({
  type: GETMOTORSFORMAKEMODELFUELBODY,
  params,
});

export const getCarDataByMotorId = (idmotor) => ({
  type: GETCARDATAFORMOTORID,
  idmotor,
});

export const getAdsForIds = (ids) => ({ type: GETADSFORIDS, ids });

export const getDoors = () => ({ type: GETDOORS });

export const getSeats = () => ({ type: GETSEATS });

export const getEuroNorm = () => ({ type: GETEURONORM });

export const getEquipment = () => ({ type: GETOPT });

export const getTrailerHitch = () => ({ type: GETTRAILERHITCH });

export const getTrailerLoad = () => ({ type: GETTRAILERLOAD });

export const getEnergyEfficience = () => ({ type: GETENERGYEFFICIENCE });

export const getVehiclesCountByBodyType = () => ({
  type: GETVEHICLESCOUNTBYBODYTYPE,
});

export const reportAd = (idad, note) => ({
  type: REPORTAD,
  params: [idad, note],
});

export const fetchEPaper = () => ({
  type: FETCHEPAPER,
});

export const getCarsOfTheDay = () => ({ type: GETCARSOFTHEDAY });

export const getDriveType = () => ({ type: GETDRIVETYPE });

export const fetchRevueArticles = (lang) => ({
  type: FETCHARTICLES,
  lang,
});

export const getCylinders = () => ({ type: GETCYLINDERS });

export const getVehiclesForCode18 = (code18, complete = true) => ({
  type: GETVEHICLESFORSERIALNO,
  params: { code18, complete },
});

export const getVehiclesForCode24 = (code24, complete = true) => ({
  type: GETVEHICLESFORREGDOCNO,
  params: { code24, complete },
});

export const setTargaVehicleType = (vehicleType) => ({
  type: SET_TARGA_VEHICLE_TYPE,
  vehicleType,
});

export const resetErrors18And24 = () => ({ type: RESETERRORS18AND24 });
export const resetVehicleData18And24 = () => ({
  type: RESETVEHICLEDATA18AND24,
});

export const getEngineType = () => ({ type: GETENGINETYPE });

export const getEmissionSticker = () => ({ type: GETENVIRONMENTALSTICKER });
