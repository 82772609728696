import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDown } from "../../../../../components/svg-icons/index";

const AccordionSection = ({ section, t }) => {
  return (
    <Accordion key={section.id}>
      <AccordionSummary
        expandIcon={<ArrowDown />}
        aria-controls={`section${section.id}`}
        id={`section${section.id}`}
      >
        <h4 className="subtitle">{t(`footer:${section.subtitle}`)}</h4>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <label>
            {Array.isArray(section.label) &&
              section.label.map((item, index) => (
                <div key={index}>
                  {item.bulleted ? (
                    <p style={{ paddingLeft: "20px", paddingTop: "0px" }}>
                      {"\u2022 "}
                      {t(`footer:${item.text}`)}
                    </p>
                  ) : (
                    <p>{t(`footer:${item.text}`)}</p>
                  )}
                </div>
              ))}
            {!section.subAccordions && !Array.isArray(section.label) && (
              <p>{t(`footer:${section.label}`)}</p>
            )}
          </label>
        </div>
        {section.subAccordions &&
          section.subAccordions.map((subAccordion, index) => (
            <Accordion
              key={`${section.id}-${index}`}
              style={{
                marginLeft: "27px",
              }}
            >
              <AccordionSummary
                expandIcon={<ArrowDown />}
                aria-controls={`sub-section${section.id}-sub-${index}`}
                id={`sub-section${section.id}-sub-${index}`}
              >
                <h4 className="subtitle">
                  {t(`footer:${subAccordion.subtitle}`)}
                </h4>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <label>
                    {Array.isArray(subAccordion.label) &&
                      subAccordion.label.map((item, index) => (
                        <div key={index} className="sub-accordion">
                          {item.bulleted ? (
                            <p style={{ paddingTop: "0px" }}>
                              {"\u2022 "}
                              {t(`footer:${item.text}`)}
                            </p>
                          ) : (
                            <p>
                              {item.link ? (
                                <a
                                  href={item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {item.link}
                                </a>
                              ) : (
                                t(`footer:${item.text}`)
                              )}
                            </p>
                          )}
                        </div>
                      ))}
                    {!Array.isArray(subAccordion.label) && (
                      <p>{t(`footer:${subAccordion.label}`)}</p>
                    )}
                  </label>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionSection;
