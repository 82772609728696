import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams, Prompt } from "react-router-dom";
import { flushSync } from "react-dom";
import history from "../../lib/history";
import { scroll, formatDateString } from "../../lib/utils";
import Make from "./01-basic-info/index";
import VehicleDetails from "./02-vehicle-details/index";
import ImageUpload from "./03-vehicle-photos/index";
import ContactDetails from "./04-contact-details/index";
import Stepper from "../../components/stepper/index";
import Button from "../../components/button/index";
import SimpleLoader from "../../components/simple-loader";
import {
  createAd,
  resetAdPhotos,
  addMerchtCard,
  getCarModel,
  isAdCreated,
  resetUploadFinished,
  resetCheckboxesInObject,
  getMotorsForMakeModelFuelBody,
  getCarDataByMotorId,
  resetAdProps,
  setVehicleLocation,
  getAdById,
  setAdImages,
  deleteAdImages,
  clearCommonData,
  resetFindProps,
  setRootAdData,
  resetVehicleData18And24,
} from "../../store/actions";
import { defaultLocation } from "../../data";
import UserPackages from "../profile/merchant/02-merchant-packages";
import Confirmation from "./06-confirmation";
import AdDetected from "../sell/07-ad-detected/index";
import ScrollToTop from "../../lib/scroll-to-top";
import LeavePagePrompt from "../../components/LeavePagePrompt";
import ModelAndTypeSelect from "./00-identify-vehicle/components/model-and-type-select";
import "./index.css";

const Sell = (props) => {
  const {
    dispatch,
    adCreated,
    loading,
    ad_images,
    commonLoading,
    profileLoading,
    uploadFinished,
    profileData,
    ad_data,
    general_data,
    vehicle_data,
    engine_environment,
    vehicle_location,
    fuel_consumption,
    ad_description,
    condition_maintenance,
    optional_equipmentids,
    ad,
    adImagesToDelete,
    vehicleData18,
    vehicleData24,
  } = props;
  const { t, i18n } = useTranslation("common,sell");
  const { idad } = useParams();
  const [isCreateAdPage] = useState(() => (idad ? false : true));
  const [procedeToMakePage, setProcedeToMakePage] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [nextButton, setNextButton] = useState(true);
  const [localProfileData, setLocalProfileData] = useState(
    JSON.parse(JSON.stringify(profileData))
  );
  const [isOnTargaMobileView, setIsOnTargaMobileView] = useState(false);
  const phoneTypes = [
    { name: "Business", id: 1 },
    { name: "Private", id: 2 },
  ];
  const [locationChoise, setLocationChoise] = useState(null);
  const [isDraftSaved, setIsDraftSaved] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [errorsForRootParams, setErrorsForRootParams] = useState({});
  const [errorDates, setErrorDates] = useState({});
  const [activeInfo, setActiveInfo] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);

  /**
   *------------------------------------------------
   * DATE VALIDATION ON VEHICLE DETAILS ( CONDITIONAL MAINTENENCE )
   *------------------------------------------------
   */

  const checkSingleValue = (propertyName, value, errors) => {
    if (value && (!value?.month || !value?.year)) {
      errors[propertyName] = {
        message: t("common:choose-both-dates"),
      };
    }
  };

  const isConditionMaintenanceDatesValid = (condition_maintenance) => {
    const requiredProperties = ["lasttechnicalservice"];

    let isValid = true;
    let errors = {};
    for (const property of requiredProperties) {
      let value = condition_maintenance[property];

      if (Object.keys(value ?? {}).length === 0) {
        value = null;
      }
      checkSingleValue(property, value, errors);
    }
    isValid = Object.keys(errors).length === 0;
    setErrorDates(errors);
    return isValid;
  };

  /**
   *------------------------------------------------
   * GET LOCATION DATA FORM USER PROFILE
   *------------------------------------------------
   * @returns {Object} user address
   */

  const getLocationData = () => {
    let data = {};
    Object.keys(profileData.address_data).forEach((key) => {
      data = { ...data, [key]: profileData.address_data[key] };
    });
    return data;
  };

  const shouldPromptOnLeave = () =>
    Object.keys(ad_data).length > 0 && activePage < 7;

  /**
   *-----------------------------------------------
   * SELL NAVIGATION ARRAY
   *-----------------------------------------------
   */
  const steps = [
    {
      label:
        vehicleData24?.length > 0
          ? t("sell:select-model-and-type")
          : t("sell:select-make&model"),
      subLabel: "test",
      step: 1,
      component:
        vehicleData24?.length > 0 || vehicleData18?.length > 0 ? (
          <ModelAndTypeSelect
            vehicleData18={vehicleData18}
            vehicleData24={vehicleData24}
            setActivePage={setActivePage}
            setNextButton={setNextButton}
            goToNextPage={(cb) => handleNext(cb)}
            setProcedeToMakePage={setProcedeToMakePage}
            setIsOnTargaMobileView={setIsOnTargaMobileView}
          />
        ) : (
          <Make
            locationChoise={locationChoise}
            setLocationChoise={setLocationChoise}
            continueWithoutVehicleRegistration={setProcedeToMakePage}
            procedeToMakePage={procedeToMakePage}
            setNextButton={setNextButton}
            getLocationData={getLocationData}
            setIsOnTargaMobileView={setIsOnTargaMobileView}
          />
        ),
    },
    {
      label: t("common:vehicle-details"),
      step: 2,
      component: (
        <VehicleDetails
          setNextButton={setNextButton}
          locationChoise={locationChoise}
          errorDates={errorDates}
          errorsForRootParams={errorsForRootParams}
          vehicleData18={vehicleData18}
          vehicleData24={vehicleData24}
        />
      ),
    },
    {
      label: t("sell:upload-images"),
      step: 3,
      component: (
        <ImageUpload
          activeError={activeInfo}
          setActiveError={setActiveInfo}
          isCreateAdPage={isCreateAdPage}
        />
      ),
    },
    {
      label: t("sell:contact-details"),
      step: 4,
      component: (
        <ContactDetails
          profileData={localProfileData}
          setLocalProfileData={setLocalProfileData}
          phoneTypes={phoneTypes}
          errorMessages={errorMessages}
        />
      ),
    },
    {
      label: t("sell:listing-options"),
      step: 5,
      component: <UserPackages />,
    },
    {
      label: t("sell:confirmation"),
      step: 6,
      component: <Confirmation />,
    },
    {
      label: isCreateAdPage
        ? t("sell:your-ad-is-detected")
        : t("sell:your-ad-is-updated"),
      step: 7,
      component: <AdDetected isCreateAdPage={isCreateAdPage} />,
      skipSubTitle: true,
    },
  ];

  /**
   *-----------------------------------------------
   * JOIN AD DATA INTO SINGLE OBJECT
   *-----------------------------------------------
   */
  const mergeAdDataObjects = () => {
    let data = { ...ad_data };
    if (Object.keys(vehicle_location).length > 0) {
      delete vehicle_location.place;
      data = { ...data, vehiclelocation: vehicle_location };
    } else {
      delete data.vehiclelocation;
    }
    if (ad_description !== null) {
      data = { ...data, description: ad_description };
    } else {
      delete data.description;
    }
    if (Object.keys(general_data).length > 0) {
      // Check if power exists and is a string; convert to integer if needed
      if (general_data.power) {
        let powerValue = general_data.power;
        if (typeof powerValue === "string") {
          powerValue = parseInt(powerValue, 10);
        }
      }
      data = { ...data, generaldata: general_data };
    } else {
      delete data.generaldata;
    }
    if (Object.keys(vehicle_data).length > 0) {
      data = { ...data, vehicledata: vehicle_data };
    } else {
      delete data.vehicledata;
    }
    if (Object.keys(engine_environment).length > 0) {
      if (Object.keys(fuel_consumption).length > 0) {
        data = {
          ...data,
          engineenvironment: {
            ...engine_environment,
            fuelconsumption: { ...fuel_consumption },
          },
        };
      } else {
        data = { ...data, engineenvironment: engine_environment };
      }
    } else {
      delete data.engineenvironment;
    }
    if (Object.keys(condition_maintenance).length > 0) {
      let formattedConditionMaintenance = {};
      for (const key in condition_maintenance) {
        if (Object.prototype.hasOwnProperty.call(condition_maintenance, key)) {
          const value = condition_maintenance[key];
          if (key === "lasttechnicalservice") {
            formattedConditionMaintenance[key] = formatDateString(
              value.month,
              value.year
            );
          } else {
            formattedConditionMaintenance[key] = value;
          }
        }
      }
      data = { ...data, conditionmaintenance: formattedConditionMaintenance };
    } else {
      delete data.conditionmaintenance;
    }
    if (Object.keys(optional_equipmentids).length > 0) {
      data = { ...data, optionalequipmentids: optional_equipmentids };
    } else {
      delete data.optionalequipmentids;
    }
    return data;
  };

  /**
   *-----------------------------------------------
   * CREATE AD
   *-----------------------------------------------
   */
  const createAdHandler = () => {
    const data = mergeAdDataObjects();
    if (adImagesToDelete?.length > 0 && data.adID) {
      dispatch(deleteAdImages(adImagesToDelete, data.adID));
      return null;
    }
    // add filter bc don't want to send already created images
    const newImages = ad_images.filter((item) => !item?.created);
    dispatch(setAdImages(newImages));
    const imagesSum = newImages.length;
    dispatch(createAd(data, imagesSum));
    localStorage.removeItem("previewAd");
  };

  /**
   *-----------------------------------------------
   * RESET ALL AD DEPENDING PROPERTIES
   *-----------------------------------------------
   */
  const resetSellProps = () => {
    setIsDraftSaved(false);
    setActivePage(1);
    dispatch(resetAdProps());
    dispatch(resetAdPhotos());
    dispatch(isAdCreated(false));
    dispatch(resetUploadFinished());
    dispatch(resetCheckboxesInObject("fuel_type"));
    dispatch(resetCheckboxesInObject("body_type"));
    dispatch(resetVehicleData18And24());
  };

  /**
   *-----------------------------------------------
   * GO TO PREVIOUS STEP
   *-----------------------------------------------
   */
  const handlePrevious = () => {
    if (activePage === 6) {
      setActivePage((prevActiveStep) => prevActiveStep - 2);
    } else if (
      activePage === 1 &&
      (vehicleData24?.length > 0 || vehicleData18.length > 0)
    ) {
      setProcedeToMakePage(false);
      resetSellProps();
    } else {
      setActivePage((prevActiveStep) => prevActiveStep - 1);
    }
  };

  /**
   *-----------------------------------------------
   * HANDLE NEXT STEP (perform optional aactions)
   *-----------------------------------------------
   */
  const handleNext = (cb) => {
    switch (activePage) {
      case 1:
        setActivePage((prevActiveStep) => prevActiveStep + 1);
        break;
      case 2:
        const errors = [];
        if (!ad_data?.mileage || ad_data?.mileage === "") {
          errors.push({
            name: "mileage",
            message: "common:required-error-message",
            target: "#mileage-scroll-target",
          });
        }
        if (!ad_data?.price || ad_data?.price === "") {
          errors.push({
            name: "price",
            message: "common:required-error-message",
            target: "#price-scroll-target",
          });
        }
        if (errors.length > 0) {
          const errorData = {};
          errors.forEach((error, index) => {
            if (index === 0) {
              scroll(error.target);
            }
            errorData[error.name] = error.message;
          });
          setErrorsForRootParams(errorData);
          return;
        }
        if (!isConditionMaintenanceDatesValid(condition_maintenance)) {
          const conditionalMaintenanceAccordion = document.getElementById(
            "conditionalMaintenanceAccordion"
          );
          if (conditionalMaintenanceAccordion) {
            const offset = 110;
            const scrollPosition =
              conditionalMaintenanceAccordion.offsetTop - offset;
            window.scrollTo({
              top: scrollPosition,
              behavior: "smooth",
            });
          }
          return;
        }
        setActivePage((prevActiveStep) => prevActiveStep + 1);
        break;
      case 3:
        if (ad_images.length < 4) {
          setActiveInfo(true);
        } else setActivePage((prevActiveStep) => prevActiveStep + 1);
        break;
      case 4:
        const errorsProfile = [];
        if (!localProfileData?.country || localProfileData?.country === "") {
          errorsProfile.push({
            name: "country",
            message: "common:required-error-message",
            target: "#country-scroll-target",
          });
        }
        if (!localProfileData?.city || localProfileData?.city === "") {
          errorsProfile.push({
            name: "city",
            message: "common:required-error-message",
            target: "#city-scroll-target",
          });
        }
        if (errorsProfile.length > 0) {
          const errorData = {};
          errorsProfile.forEach((error, index) => {
            if (index === 0) {
              scroll(error.target);
            }
            errorData[error.name] = error.message;
          });
          setErrorMessages(errorData);
        } else {
          if (localProfileData?.country !== "Switzerland") {
            delete localProfileData?.address_data?.region;
          }
          if (!localProfileData?.location) {
            localProfileData.location = {
              latitude: defaultLocation.lat,
              longitude: defaultLocation.lng,
            };
          }
          dispatch(addMerchtCard(localProfileData));
          createAdHandler();
          setActivePage((prevActiveStep) => prevActiveStep + 2);
        }
        break;
      case 5:
        setActivePage((prevActiveStep) => prevActiveStep + 1);
        break;
      case 6:
        setActivePage((prevActiveStep) => prevActiveStep + 1);
        break;
    }
    try {
      cb?.();
    } catch (error) {
      console.log("error while calling callback", error);
    }
  };

  /**
   *-----------------------------------------------
   * HANDLE PREVIEW AD
   *-----------------------------------------------
   */
  const previewAdHandler = () => {
    flushSync(() => {
      setShowPrompt(false);
    });
    const params = new URLSearchParams();
    const data = mergeAdDataObjects();
    let photos = [];

    if (ad_images.length > 0) {
      photos = ad_images.map((item) => item.src);
    }
    localStorage.setItem("previewAd", true);
    localStorage.setItem("activePage", activePage);
    if (isDraftSaved) {
      localStorage.setItem("isDraftSaved", isDraftSaved);
    }
    // Check if editAd is not already set and set it to true
    if (!localStorage.getItem("editAd")) {
      localStorage.setItem("editAd", "true");
    }
    history.push({
      pathname: `/${i18n.language}/ad/`,
      search: params.toString(),
      state: {
        activePage: activePage,
        adData: { ...data, photos: photos },
        showPrompt: true,
      },
    });
  };

  /**
   *---------------------------------------------------
   * RESET AD PROPS ON PAGE INIT
   *---------------------------------------------------
   */
  useEffect(() => {
    const previewAd = localStorage.getItem("previewAd");
    const isDraftSaved = localStorage.getItem("isDraftSaved");
    const savedActivePage = localStorage.getItem("activePage") || 1;
    if (previewAd) {
      setActivePage(Number(savedActivePage));
      setProcedeToMakePage(true);
      if (isDraftSaved) {
        setIsDraftSaved(isDraftSaved);
        localStorage.removeItem("isDraftSaved");
      }
    } else {
      resetSellProps();
      if (!isCreateAdPage) {
        dispatch(getAdById(idad));
        setActivePage(1);
        setProcedeToMakePage(true);
      } else {
        setProcedeToMakePage(false);
      }
    }
    if (savedActivePage !== 1 || !isCreateAdPage) {
      setNextButton(false);
    }
    if (isCreateAdPage) {
      dispatch(setRootAdData({}));
    }
    return () => {
      if (!localStorage.getItem("procedeToMakePage")) {
        setProcedeToMakePage(false);
      } else {
        localStorage.removeItem("procedeToMakePage");
      }
      dispatch(clearCommonData(["deletedAdImages", "recoverPasswordSuccess"]));
      localStorage.removeItem("activePage");
      dispatch(resetFindProps());
      if (localStorage.getItem("editAd")) {
        const isGoingToAdDetails = localStorage.getItem("previewAd") === "true";
        if (!isGoingToAdDetails) {
          localStorage.removeItem("editAd");
        }
      }
    };
  }, []);

  /**
   *---------------------------------------------------
   * FETCH MOTORS OR RESET CAR DATA IF MOTOR IS DELETED
   *---------------------------------------------------
   */
  useEffect(() => {
    const previewAd = localStorage.getItem("previewAd");
    if (!previewAd) {
      const { idmake, idmodel, idfueltype, idbodytype } = ad_data;
      if (idmake && idmodel && idfueltype && idbodytype) {
        dispatch(
          getMotorsForMakeModelFuelBody([
            idmake,
            idmodel,
            idfueltype,
            idbodytype,
          ])
        );
      }
    } else {
      localStorage.removeItem("previewAd");
    }
  }, [ad_data.idmake, ad_data.idmodel, ad_data.idfueltype, ad_data.idbodytype]);

  /**
   *-----------------------------------------------
   * SET LOCAL PROFILE DATA
   *-----------------------------------------------
   */
  useEffect(() => {
    setLocalProfileData(profileData);
  }, [profileData]);

  /**
   *-----------------------------------------------------
   * CHANGE VEHICLELOCATIONDATA IF ADDRESS DATA CHANGES
   *-----------------------------------------------------
   */
  useEffect(() => {
    if (locationChoise === 1) {
      const data = getLocationData();
      dispatch(setVehicleLocation(data));
    }
  }, [profileData?.address_data]);

  /**
   *-----------------------------------------------
   * FETCH CAR MODELS
   *-----------------------------------------------
   */
  useEffect(() => {
    if (!!ad_data.idmake) {
      dispatch(getCarModel(ad_data.idmake));
    }
  }, [ad_data.idmake]);

  /**
   *-----------------------------------------------
   * FETCH CAR DATA [doorcount, seats etc.]
   *-----------------------------------------------
   */
  useEffect(() => {
    if (general_data.equipmentline) {
      dispatch(getCarDataByMotorId(general_data.equipmentline));
    }
  }, [general_data.equipmentline]);

  useEffect(() => {
    if (
      !isCreateAdPage &&
      ad?.uidowner &&
      profileData?.uid &&
      ad?.uidowner !== profileData?.uid
    ) {
      history.push(`/${i18n.language}/home`);
    }
  }, [ad, profileData]);

  return (
    <SimpleLoader loading={loading || commonLoading || profileLoading}>
      <ScrollToTop dependency={[activePage]} />
      {showPrompt && (
        <Prompt
          when={shouldPromptOnLeave()}
          message={() => t("common:prompt-leave-page")}
        />
      )}
      <LeavePagePrompt />
      <div className="sell">
        <div>
          <Stepper
            stepsData={steps}
            activePage={activePage}
            adCreated={adCreated}
            uploadFinished={uploadFinished}
          />
          <div className="sell-main-content">
            <div
              className={`layout-header ${
                activePage === 7 || (activePage === 1 && isOnTargaMobileView)
                  ? "layout-header-hidden"
                  : ""
              }`}
            >
              <div>
                {!steps[activePage - 1]?.skipSubTitle && (
                  <>
                    <h3 className="primary-color">
                      {isOnTargaMobileView ? " " : steps[activePage - 1]?.label}
                    </h3>
                    {!procedeToMakePage ? (
                      vehicleData24?.length === 0 ||
                      vehicleData18.length === 0 ? (
                        <h4 className="identify-vehicle-sub-label">
                          {t("sell:inentify-vehicle")}
                        </h4>
                      ) : null
                    ) : null}
                  </>
                )}
              </div>
              {activePage > 2 && activePage < 7 && (
                <Button
                  color="white"
                  className="preview-ad-btn"
                  label={t("sell:preview-ad")}
                  click={previewAdHandler}
                />
              )}
              {/* { activePage === 7 && <Button color='transparent' label='Sell another car' className='create-another' click={ sellAnotherCar } /> } */}
            </div>
            <main>{steps[activePage - 1]?.component}</main>
            {procedeToMakePage ? (
              <div
                className={
                  (vehicleData24.length > 0 || vehicleData18.length > 0) &&
                  activePage === 1
                    ? "withTarga"
                    : "withregcard"
                }
              >
                <div
                  className={`controls ${
                    activePage === 7 ? "controls-hidden" : ""
                  }`}
                >
                  <Button
                    color="red"
                    label={t("common:previous")}
                    className="control-btns"
                    click={handlePrevious}
                    disabled={
                      activePage === 1 &&
                      procedeToMakePage &&
                      vehicleData18.length === 0 &&
                      vehicleData24.length === 0
                    }
                  />
                  <Button
                    color="red"
                    label={
                      activePage === 6
                        ? t("common:save-&-go-next")
                        : activePage === 1
                        ? t("common:next")
                        : t("common:save&go")
                    }
                    className="control-btns"
                    click={handleNext}
                    disabled={nextButton}
                  />
                </div>
                {activePage === 1 &&
                vehicleData24?.length === 0 &&
                vehicleData18.length === 0 ? (
                  <span>
                    <p>{t("sell:you-cant-define-month-and-date")}</p>
                    <p>{t("sell:enter-data-yourself")}</p>
                  </span>
                ) : (
                  <span>
                    <p>{t("sell:did-you-find-vehicle-in-selection")}</p>
                    <p>{t("sell:collect-data-yourself")}</p>
                  </span>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const {
    adCreated,
    loading,
    power,
    ad_data,
    general_data,
    vehicle_data,
    engine_environment,
    vehicle_location,
    fuel_consumption,
    ad_description,
    condition_maintenance,
    optional_equipmentids,
    ad,
    vehicleData18,
    vehicleData24,
  } = state.car;
  const { ad_images, uploadFinished, adImagesToDelete } = state.common;
  const { profileData } = state.profile;
  const commonLoading = state.common.loading;
  const profileLoading = state.profile.loading;

  return {
    adCreated,
    loading,
    ad_images,
    commonLoading,
    profileLoading,
    uploadFinished,
    profileData,
    power,
    ad_data,
    general_data,
    vehicle_data,
    engine_environment,
    vehicle_location,
    fuel_consumption,
    ad_description,
    condition_maintenance,
    optional_equipmentids,
    ad,
    adImagesToDelete,
    vehicleData18,
    vehicleData24,
  };
};

export default connect(stateToProps)(Sell);
