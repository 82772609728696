import React from "react";

export const Logo = () => {
  return (
    <svg
      id="CARINDEX_LOGO_on_light"
      data-name="CARINDEX LOGO on light"
      className="carindex-logo"
      xmlns="http://www.w3.org/2000/svg"
      width="199.999"
      height="41.718"
      viewBox="0 0 199.999 41.718"
    >
      <defs>
        {/* <style>
      .cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: #f61d2a;
      }

      .cls-3 {
        fill: var(--logo-dark-fill);
      }

      .cls-4 {
        fill: url(#linear-gradient);
      }
    </style> */}

        <linearGradient
          id="linear-gradient"
          x1="0.5"
          y1="0.992"
          x2="0.5"
          y2="-0.007"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#ff2429" />
          <stop offset="0.992" stopColor="#d80532" />
        </linearGradient>
      </defs>
      <g
        id="Group_3189"
        data-name="Group 3189"
        transform="translate(4.931 11.019)"
      >
        <g id="Group_3188" data-name="Group 3188">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 0 MEMBER 0 FILL"
          >
            <path
              id="Path_465"
              data-name="Path 465"
              className="cls-1"
              d="M270.571,227.678q-.109-.174-.2-.349a21.8,21.8,0,0,0-1.786-2.635,3.931,3.931,0,0,0-2.254-1.132.79.79,0,0,1-.087-.022q-.588-.065-1.35-.076h-8.362q.414,4.987,5.88,6.794-7.252.61-9.353,4.192a3.938,3.938,0,0,0,.392,4.7,3.577,3.577,0,0,0,1.568.991,3.331,3.331,0,0,0,.969.142,3.558,3.558,0,0,0,1.013-.142,3.416,3.416,0,0,0,1.154-.566,4.18,4.18,0,0,0,1.535-3.07,6.229,6.229,0,0,1,.991-3.027q1.034-1.23,3.713-1.285h6.174q2.983.316,3.452,4.312a4.071,4.071,0,0,0,1.579,3.07,3.6,3.6,0,0,0,1.209.566,3.825,3.825,0,0,0,1.045.142,3.558,3.558,0,0,0,1.013-.142,3.619,3.619,0,0,0,1.633-.991,3.726,3.726,0,0,0,1.045-2.722,3.881,3.881,0,0,0-.479-1.731,4.2,4.2,0,0,0-3.4-2.014l-.152-.022A10.154,10.154,0,0,1,270.571,227.678Z"
              transform="translate(-252.463 -223.464)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3191"
        data-name="Group 3191"
        transform="translate(181.171 10.008)"
      >
        <g id="Group_3190" data-name="Group 3190">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_0_MEMBER_0_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 0 MEMBER 0 FILL"
          >
            <path
              id="Path_466"
              data-name="Path 466"
              className="cls-2"
              d="M1376.471,217.116h-2.064l-6.518,8.438-6.51-8.438h-2.135l7.537,9.657-8.366,10.7h2.056l7.283-9.418,7.306,9.418h2.183l-8.406-10.7Z"
              transform="translate(-1358.416 -217.116)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3193"
        data-name="Group 3193"
        transform="translate(164.056 10.008)"
      >
        <g id="Group_3192" data-name="Group 3192">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_0_MEMBER_1_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 0 MEMBER 1 FILL"
          >
            <path
              id="Path_467"
              data-name="Path 467"
              className="cls-2"
              d="M1264.7,218.662l.367-1.546h-14.055v20.358H1264.7l.367-1.546h-12.239v-8.143h11.107l.366-1.554h-11.474v-7.569Z"
              transform="translate(-1251.016 -217.116)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3195"
        data-name="Group 3195"
        transform="translate(142.83 10.008)"
      >
        <g id="Group_3194" data-name="Group 3194">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_0_MEMBER_2_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 0 MEMBER 2 FILL"
          >
            <path
              id="Path_468"
              data-name="Path 468"
              className="cls-2"
              d="M1130.373,218.064a13.587,13.587,0,0,0-5.562-.948h-7v20.358h7a13.59,13.59,0,0,0,5.562-.948,5.9,5.9,0,0,0,3-2.972,12.393,12.393,0,0,0,.877-5.139v-2.263a12.336,12.336,0,0,0-.877-5.131,5.911,5.911,0,0,0-3-2.956m-1.036,1.315a4.433,4.433,0,0,1,2.366,2.4,11.668,11.668,0,0,1,.669,4.43v2.143a11.829,11.829,0,0,1-.669,4.478,4.335,4.335,0,0,1-2.366,2.39,11.405,11.405,0,0,1-4.526.709h-5.171V218.662h5.171A11.48,11.48,0,0,1,1129.337,219.379Z"
              transform="translate(-1117.816 -217.116)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3197"
        data-name="Group 3197"
        transform="translate(120.751 10.008)"
      >
        <g id="Group_3196" data-name="Group 3196">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_0_MEMBER_3_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 0 MEMBER 3 FILL"
          >
            <path
              id="Path_469"
              data-name="Path 469"
              className="cls-2"
              d="M980.931,217.116h-1.665v20.358h1.817V219.945l13.011,17.529h1.7V217.116h-1.817V234.7Z"
              transform="translate(-979.266 -217.116)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3199"
        data-name="Group 3199"
        transform="translate(112.823 10.008)"
      >
        <g id="Group_3198" data-name="Group 3198">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_0_MEMBER_4_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 0 MEMBER 4 FILL"
          >
            <path
              id="Path_470"
              data-name="Path 470"
              className="cls-2"
              d="M931.333,217.116h-1.817v20.358h1.817Z"
              transform="translate(-929.516 -217.116)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3201"
        data-name="Group 3201"
        transform="translate(90.346 10.007)"
      >
        <g id="Group_3200" data-name="Group 3200">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_1_MEMBER_0_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 1 MEMBER 0 FILL"
          >
            <path
              id="Path_471"
              data-name="Path 471"
              className="cls-3"
              d="M803.661,218.547q-1.984-1.466-6.358-1.434h-8.836V237.47h4.956v-7.147H796.9l4.191,7.147h5.577l-4.956-7.84a5.554,5.554,0,0,0,2.908-1.944,5.861,5.861,0,0,0,.988-3.562v-.781q.04-3.346-1.944-4.8m-10.239,2.39h3.737a6.97,6.97,0,0,1,2.04.215,1.707,1.707,0,0,1,1.1.8,3.287,3.287,0,0,1,.327,1.673v.223a2.528,2.528,0,0,1-.765,2.207,4.51,4.51,0,0,1-2.7.55h-3.737Z"
              transform="translate(-788.466 -217.112)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3203"
        data-name="Group 3203"
        transform="translate(65.24 10.008)"
      >
        <g id="Group_3202" data-name="Group 3202">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_1_MEMBER_1_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 1 MEMBER 1 FILL"
          >
            <path
              id="Path_472"
              data-name="Path 472"
              className="cls-3"
              d="M644.788,217.116h-5.976l-7.9,20.358h5.107l1.721-4.916h7.872l1.8,4.916h5.315l-7.944-20.358m-.4,11.76h-5.4l2.669-7.593Z"
              transform="translate(-630.916 -217.116)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3205"
        data-name="Group 3205"
        transform="translate(46.937 9.951)"
      >
        <g id="Group_3204" data-name="Group 3204">
          <g
            id="Layer0_0_MEMBER_0_MEMBER_1_MEMBER_1_MEMBER_2_FILL"
            data-name="Layer0 0 MEMBER 0 MEMBER 1 MEMBER 1 MEMBER 2 FILL"
          >
            <path
              id="Path_473"
              data-name="Path 473"
              className="cls-3"
              d="M520.192,217.615a5.822,5.822,0,0,0-3.163,2.86,12.028,12.028,0,0,0-.964,5.322v2.374a12.539,12.539,0,0,0,.924,5.346,5.753,5.753,0,0,0,3.131,2.868,15.931,15.931,0,0,0,5.809.845h3.02q.924-.008,2.191-.056l.837-3.984q-1.53.048-2.558.064-1.052.016-1.8,0h-1.4a8.711,8.711,0,0,1-3.028-.374,2.465,2.465,0,0,1-1.482-1.5,9.3,9.3,0,0,1-.383-3.131v-2.518a9.366,9.366,0,0,1,.359-3.147,2.293,2.293,0,0,1,1.426-1.482,8.789,8.789,0,0,1,3.028-.367q1.211,0,2.438.024,1.235.008,2.374.032l.829-3.976q-2.223-.056-3.578-.056h-2.271A15.332,15.332,0,0,0,520.192,217.615Z"
              transform="translate(-516.064 -216.762)"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_3206"
        data-name="Group 3206"
        transform="translate(112.784 34.407)"
      >
        <path
          id="Path_474"
          data-name="Path 474"
          className="cls-3"
          d="M929.272,371.057h.663v2.081h.016a1.356,1.356,0,0,1,1.286-.639,1.888,1.888,0,0,1,1.855,2.12,1.861,1.861,0,0,1-1.84,2.1,1.543,1.543,0,0,1-1.3-.631h-.016v.538h-.663Zm1.871,2.027c-.889,0-1.232.764-1.232,1.52,0,.8.359,1.528,1.271,1.528s1.208-.772,1.208-1.559C932.39,373.816,932,373.084,931.143,373.084Z"
          transform="translate(-929.272 -370.925)"
        />
        <path
          id="Path_475"
          data-name="Path 475"
          className="cls-3"
          d="M957.409,385.262c-.3.787-.577,1.076-1.169,1.076a1.319,1.319,0,0,1-.46-.07v-.608a1.354,1.354,0,0,0,.39.094.572.572,0,0,0,.546-.366l.273-.686-1.6-4.007h.748l1.177,3.3h.016l1.13-3.3h.7Z"
          transform="translate(-951.228 -379.026)"
        />
        <path
          id="Path_476"
          data-name="Path 476"
          className="cls-3"
          d="M994.885,371.057h.818l2.175,5.566h-.819l-.608-1.676H994.1l-.624,1.676h-.764Zm-.554,3.266h1.886l-.927-2.6h-.016Z"
          transform="translate(-982.601 -370.925)"
        />
        <path
          id="Path_477"
          data-name="Path 477"
          className="cls-3"
          d="M1032.108,374.612a1.957,1.957,0,0,1-2.191,2.144c-1.434,0-2.284-.663-2.284-2.144v-3.555h.74v3.555a1.386,1.386,0,0,0,1.543,1.52,1.339,1.339,0,0,0,1.45-1.52v-3.555h.741Z"
          transform="translate(-1011.959 -370.925)"
        />
        <path
          id="Path_478"
          data-name="Path 478"
          className="cls-3"
          d="M1060.5,371.681h-1.855v-.624h4.451v.624h-1.855v4.942h-.74Z"
          transform="translate(-1038.025 -370.925)"
        />
        <path
          id="Path_479"
          data-name="Path 479"
          className="cls-3"
          d="M1089.823,370.226a2.927,2.927,0,1,1-2.666,2.915A2.672,2.672,0,0,1,1089.823,370.226Zm0,5.207c1.356,0,1.925-1.169,1.925-2.292s-.569-2.292-1.925-2.292-1.925,1.169-1.925,2.292S1088.467,375.433,1089.823,375.433Z"
          transform="translate(-1061.997 -370.226)"
        />
        <path
          id="Path_480"
          data-name="Path 480"
          className="cls-3"
          d="M1126.677,371.057h1.013l1.754,4.677,1.762-4.677h1.014v5.566h-.7v-4.63h-.016l-1.738,4.63h-.631l-1.738-4.63h-.016v4.63h-.7Z"
          transform="translate(-1095.219 -370.925)"
        />
        <path
          id="Path_481"
          data-name="Path 481"
          className="cls-3"
          d="M1170.526,370.226a2.927,2.927,0,1,1-2.666,2.915A2.672,2.672,0,0,1,1170.526,370.226Zm0,5.207c1.356,0,1.925-1.169,1.925-2.292s-.569-2.292-1.925-2.292-1.925,1.169-1.925,2.292S1169.17,375.433,1170.526,375.433Z"
          transform="translate(-1129.839 -370.226)"
        />
        <path
          id="Path_482"
          data-name="Path 482"
          className="cls-3"
          d="M1207.38,371.057h2.019a3.347,3.347,0,0,1,1.473.2,1.321,1.321,0,0,1,.655,1.162,1.286,1.286,0,0,1-.826,1.247v.016a1.276,1.276,0,0,1,1.06,1.341,1.58,1.58,0,0,1-1.692,1.6h-2.689Zm.741,2.393h1.481c.842,0,1.185-.3,1.185-.889,0-.772-.546-.881-1.185-.881h-1.481Zm0,2.549h1.855a.954.954,0,0,0,1.045-1c0-.725-.585-.928-1.208-.928h-1.692Z"
          transform="translate(-1163.062 -370.925)"
        />
        <path
          id="Path_483"
          data-name="Path 483"
          className="cls-3"
          d="M1241.08,371.057h.74v5.566h-.74Z"
          transform="translate(-1191.391 -370.925)"
        />
        <path
          id="Path_484"
          data-name="Path 484"
          className="cls-3"
          d="M1253.552,371.057h.74V376h2.947v.624h-3.687Z"
          transform="translate(-1201.876 -370.925)"
        />
        <path
          id="Path_485"
          data-name="Path 485"
          className="cls-3"
          d="M1293.318,371.057h3.266a1.624,1.624,0,0,1,1.816,1.59,1.429,1.429,0,0,1-.834,1.4,1.517,1.517,0,0,1,.818,1.388,3.673,3.673,0,0,0,.187,1.193h-1.715a4.018,4.018,0,0,1-.14-.857c-.047-.53-.094-1.084-.772-1.084h-.912v1.941h-1.715Zm1.715,2.432h.9c.32,0,.756-.055.756-.553,0-.351-.195-.553-.85-.553h-.8Z"
          transform="translate(-1235.305 -370.925)"
        />
        <path
          id="Path_486"
          data-name="Path 486"
          className="cls-3"
          d="M1332.055,371.057h4.607v1.427h-2.892v.694h2.627V374.5h-2.627v.694h2.978v1.426h-4.693Z"
          transform="translate(-1267.869 -370.925)"
        />
        <path
          id="Path_487"
          data-name="Path 487"
          className="cls-3"
          d="M1371.386,376.623h-1.917l-1.661-5.566h1.73l.912,3.375h.016l.9-3.375h1.761Z"
          transform="translate(-1297.925 -370.925)"
        />
        <path
          id="Path_488"
          data-name="Path 488"
          className="cls-3"
          d="M1411.719,374.471c0,1.543-.834,2.284-2.533,2.284s-2.541-.741-2.541-2.284v-3.414h1.715v3.032c0,.569,0,1.278.834,1.278.811,0,.811-.709.811-1.278v-3.032h1.715Z"
          transform="translate(-1330.572 -370.925)"
        />
        <path
          id="Path_489"
          data-name="Path 489"
          className="cls-3"
          d="M1446.751,371.057h4.607v1.427h-2.892v.694h2.627V374.5h-2.627v.694h2.978v1.426h-4.693Z"
          transform="translate(-1364.288 -370.925)"
        />
      </g>
      <g
        id="Group_3207"
        data-name="Group 3207"
        transform="translate(-100.001 -46)"
      >
        <g
          id="Group_3134"
          data-name="Group 3134"
          transform="translate(100.001 46)"
        >
          <g id="Group_3133" data-name="Group 3133" transform="translate(0 0)">
            <g
              id="Layer0_0_MEMBER_0_MEMBER_0_FILL"
              data-name="Layer0 0 MEMBER 0 MEMBER 0 FILL"
            >
              <path
                id="Path_449"
                data-name="Path 449"
                className="cls-4"
                d="M422.825,899.609a8.271,8.271,0,0,0-1.178-.955,6.9,6.9,0,0,0-3.924-1.154h-24.53a6.953,6.953,0,0,0-3.94,1.154,9.333,9.333,0,0,0-1.186.955,7.042,7.042,0,0,0-2.117,5.134v24.514a7.228,7.228,0,0,0,7.243,7.243h24.53a7.269,7.269,0,0,0,7.243-7.243V904.743A7,7,0,0,0,422.825,899.609Z"
                transform="translate(-385.95 -897.5)"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_3136"
          data-name="Group 3136"
          transform="translate(104.927 57.007)"
        >
          <g id="Group_3135" data-name="Group 3135" transform="translate(0)">
            <g
              id="Layer0_0_MEMBER_0_MEMBER_0_MEMBER_0_FILL-2"
              data-name="Layer0 0 MEMBER 0 MEMBER 0 MEMBER 0 FILL"
            >
              <path
                id="Path_450"
                data-name="Path 450"
                className="cls-1"
                d="M434.985,970.857q-.109-.174-.2-.348a21.737,21.737,0,0,0-1.784-2.632,3.926,3.926,0,0,0-2.251-1.131.767.767,0,0,1-.087-.022q-.587-.065-1.349-.076h-8.353q.413,4.981,5.874,6.787-7.244.609-9.343,4.187a3.934,3.934,0,0,0,.391,4.7,3.575,3.575,0,0,0,1.566.99,3.325,3.325,0,0,0,.968.141,3.55,3.55,0,0,0,1.011-.141,3.416,3.416,0,0,0,1.153-.566,4.175,4.175,0,0,0,1.534-3.067,6.226,6.226,0,0,1,.99-3.024q1.033-1.229,3.709-1.283h6.167q2.98.315,3.448,4.307a4.067,4.067,0,0,0,1.577,3.067,3.6,3.6,0,0,0,1.207.566,3.82,3.82,0,0,0,1.044.141,3.55,3.55,0,0,0,1.011-.141,3.614,3.614,0,0,0,1.632-.99,3.722,3.722,0,0,0,1.044-2.719,3.876,3.876,0,0,0-.478-1.729,4.2,4.2,0,0,0-3.394-2.012l-.152-.022A10.143,10.143,0,0,1,434.985,970.857Z"
                transform="translate(-416.897 -966.648)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
