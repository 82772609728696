import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  withStyles,
  Checkbox,
} from "@material-ui/core";

const CustomDialog = withStyles({
  root: {
    "& .MuiPaper-rounded": {
      borderRadius: 0,
    },
  },
})(Dialog);

const CustomDialogTitle = withStyles({
  root: {
    position: "relative",
    padding: "16px",
    fontSize: 18,
    "& .MuiTypography-root": {
      lineHeight: 1,
    },
  },
})(DialogTitle);

const CustomDialogContent = withStyles((theme) => ({
  root: {
    padding: "24px 24px",
    [theme.breakpoints.up("md")]: {
      width: "700px",
      minHeight: "405px",
    },
  },
}))(DialogContent);

const CustomDialogActions = withStyles({
  root: {
    padding: 0,
  },
})(DialogActions);

const CustomCheckbox = withStyles({
  root: {
    padding: "2px 9px",
  },
  checked: {},
})(Checkbox);

export {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogActions,
  CustomCheckbox,
};
