import React, { useEffect, useState } from "react";
import history from "../../lib/history";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  findAds4,
  resetCarModel,
  resetFindProps,
  getCarModel,
  setFindProps,
  customSetFindProps,
} from "../../store/actions";
import {
  setSearchProps,
  //  numberWithCommas
} from "../../lib/utils";
import { getYearsWithAll } from "../../data";
import TextField from "../../components/text-field/index";
import Select from "../../components/select/index";
import Button from "../../components/button/index";
import FormControl from "../../components/form-control";
import "./index.css";
import { ArrowRight } from "../../components/svg-icons";
import CarsOfDay from "./cars-of-day";
import vw from "../../assets/images/brands/vw.png";
import audi from "../../assets/images/brands/audi.png";
import bmw from "../../assets/images/brands/bmw.png";
import fiat from "../../assets/images/brands/fiat.png";
import ford from "../../assets/images/brands/ford.png";
import mercedes from "../../assets/images/brands/mercedes.png";
import opel from "../../assets/images/brands/opel.png";
import renault from "../../assets/images/brands/renault.png";
import NewsTestReports from "../home/news-testreports/index";
import CarouselHome from "./carousel-home/index";
import { useCarsOfTheDay } from "../../hooks";

const Home = (props) => {
  const { t, i18n } = useTranslation(["home", "common"]);
  const {
    dispatch,
    makeWithAll,
    carModelWithAll,
    find_data,
    // ads_total,
    connectFinished,
  } = props;
  useCarsOfTheDay();
  const yearsWithAll = getYearsWithAll();

  const [selectedMake, setSelectedMake] = useState();
  const [selectedModel, setSelectedModel] = useState();
  const [selectedYearFrom, setSelectedYearFrom] = useState();

  const handleMakeChange = (newValue) => {
    let value;
    if (newValue?.idmake === -1) {
      value = [null];
    } else {
      value = newValue ? [newValue.idmake] : null;
    }
    dispatch(setFindProps("idModel", [[null]]));
    dispatch(setFindProps("idMake", value));
    if (value) {
      dispatch(getCarModel(value[0], 0));
    }
    setSelectedMake(newValue || null);
  };

  const handleModelChange = (newValue) => {
    const value = newValue?.idmodel === -1 ? null : newValue?.idmodel;

    dispatch(setFindProps("idModel", value ? [[value]] : [[null]]));
    setSelectedModel(newValue);
  };

  const handleYearFromChange = (newValue) => {
    setSearchProps(dispatch, "yearFrom", newValue ? newValue.year : null);
    setSelectedYearFrom(newValue);
  };

  const popBrands = [
    { label: "BMW", src: bmw, idmake: 10 },
    { label: "Volkswagen", src: vw, idmake: 92 },
    { label: "Audi", src: audi, idmake: 7 },
    { label: "Mercedes-Benz", src: mercedes, idmake: 58 },
    { label: "Ford", src: ford, idmake: 33 },
    { label: "Opel", src: opel, idmake: 66 },
    { label: "Fiat", src: fiat, idmake: 31 },
    { label: "Renault", src: renault, idmake: 72 },
  ];

  const goToResultsPage = () => {
    history.push(`/${i18n.language}/results/`);
  };

  const goToAdvancedSearch = () => {
    history.push(`/${i18n.language}/advanced-search/`);
  };

  const mostSearchedBrandsHandler = (idmake) => {
    dispatch(setFindProps("idMake", [idmake]));
    dispatch(findAds4());
    history.push(`/${i18n.language}/results/`);
  };

  useEffect(() => {
    dispatch(findAds4());
  }, [connectFinished]);

  useEffect(() => {
    (async () => {
      await dispatch(resetFindProps());
      await dispatch(resetCarModel());
      // await dispatch(findAds4());
      await dispatch(getCarModel(0, 0));
      await setSelectedMake(null);
      await setSelectedModel(null);
      await dispatch(customSetFindProps("equipmentLine", [null]));
    })();
  }, []);

  useEffect(() => {
    const presetMake = makeWithAll.find(
      (item) => item.idmake === (find_data.idMake ? find_data.idMake[0] : null)
    );
    setSelectedMake(presetMake);
    if (presetMake) {
      dispatch(getCarModel(presetMake.idmake, 0));
    }
  }, [makeWithAll]);

  useEffect(() => {
    const presetModel = (
      Array.isArray(carModelWithAll[0]) ? carModelWithAll[0] : []
    ).find(
      (item) =>
        item.idmodel ===
        (Array.isArray(find_data.idModel) && Array.isArray(find_data.idModel[0])
          ? find_data.idModel[0][0]
          : null)
    );
    setSelectedModel(presetModel);
  }, [carModelWithAll]);

  useEffect(() => {
    const presetYear = yearsWithAll.find(
      (item) => item.year === find_data.yearFrom
    );
    setSelectedYearFrom(presetYear);
  }, [yearsWithAll]);

  return (
    <div className="flex-row-wrapper home-page">
      <div className="full-width relative home-image-container">
        <CarouselHome />
      </div>
      <div className="flex-row-wrapper home-heading">
        <h3 className="secondary-font-color home-title no-margin font-weight-normal">
          {t("look-among")}
          {/* <span className="primary-color">
            {" "}
            {ads_total ? numberWithCommas(ads_total) : "0"}{" "}
          </span> */}
          {/* {t("vehicles")} */}
        </h3>
        <h3 className="secondary-font-color home-subtitle no-margin font-weight-normal">
          {t("home-subheading")}
        </h3>
      </div>
      <div className="home-input-container">
        <div className="home-input-grid">
          <FormControl
            inputType="select"
            placeholder={t("home:car-brand")}
            handleChange={(_, newValue) => {
              handleMakeChange(newValue);
            }}
            data={makeWithAll}
            value={selectedMake}
          />
          <FormControl
            inputType="select"
            placeholder={t("home:model")}
            handleChange={(_, newValue) => {
              handleModelChange(newValue);
            }}
            data={Array.isArray(carModelWithAll[0]) ? carModelWithAll[0] : []}
            value={selectedModel}
            disabled={!selectedMake}
          />
          <Select
            placeholder={t("home:year-from")}
            handleChange={(_, newValue) => {
              handleYearFromChange(newValue);
            }}
            label="yearFrom"
            data={yearsWithAll}
            defaultValue={selectedYearFrom}
          />
          <TextField
            id="price"
            type="number"
            placeholder={t("home:price-until")}
            value={find_data.priceTo ? find_data.priceTo : ""}
            changeHandler={(event) => {
              setSearchProps(dispatch, "priceTo", parseInt(event.target.value));
            }}
          />
          <Button
            click={goToResultsPage}
            className="search-button"
            label={
              t("common:search")
              // +
              // " " +
              // `(${ads_total ? numberWithCommas(ads_total) : "0"})`
            }
            color="red"
          />
        </div>
        <Button
          color="transparent"
          className="goToAdvancedSearchBtn"
          click={goToAdvancedSearch}
        >
          <span>{t("common:advanced-search")}</span>
          <ArrowRight />
        </Button>
      </div>
      <div className="cars-of-the-day">
        <p>{t("home:cars-of-the-day")}</p>
        <CarsOfDay />
      </div>
      <div className="most-searched-brands">
        <p>{t("home:most-searched-brands")}</p>
        <div className="brands-container">
          {popBrands.map((item, index) => {
            return (
              <div
                className="brand-wrapper"
                key={index}
                onClick={() => mostSearchedBrandsHandler(item?.idmake)}
              >
                <span className="brand-logo">
                  <img src={item.src} />
                </span>
                <p>{item.label}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="news-testreports-revue">
        <p>{t("home:news-testreports-revue")}</p>
        <NewsTestReports />
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const { makeWithAll, carModelWithAll, find_data, ads_total } = state.car;
  const { connectFinished } = state.profile;
  return {
    makeWithAll,
    carModelWithAll,
    find_data,
    ads_total,
    connectFinished,
  };
};

export default connect(stateToProps)(Home);
