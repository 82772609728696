import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDownRed } from "../svg-icons/index";
import "./index.css";

const Accordion_ = (props) => {
  const { children, title, className } = props;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={ArrowDownRed}
        aria-controls="engine"
        id="engine"
      >
        <h2 className="accordian-title">{title}</h2>
      </AccordionSummary>
      <AccordionDetails className={className}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default Accordion_;
