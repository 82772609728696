import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = ({ title, description, image, url }) => {
  const isBrowser = typeof window !== "undefined";
  const currentUrl = url || (isBrowser ? window.location.href : "");
  const isDomain =
    isBrowser &&
    (window.location.hostname === "carindex.ch" ||
      window.location.hostname === "www.carindex.ch");

  if (!title && !description && !image) return null;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {currentUrl && <meta property="og:url" content={currentUrl} />}
      <meta property="og:type" content="website" />
      {/* Twitter */}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {/* Matomo container script only on production */}
      {isDomain && (
        <script>
          {`
            var _mtm = window._mtm = window._mtm || [];
            _mtm.push({ 'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start' });
            (function () {
              var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
              g.async = true; g.src = 'https://cdn.matomo.cloud/carindex.matomo.cloud/container_y4KIfBpa.js';
              s.parentNode.insertBefore(g, s);
            })();
          `}
        </script>
      )}
    </Helmet>
  );
};

export default SEO;
