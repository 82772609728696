import {
  REQUEST_ERROR,
  CLEAR_ERROR,
  LOADING,
  ERROR,
  SET_COMMON_PROP,
  SHOW_TOAST,
  RESET_TOAST,
  SET_COMMON_MODAL,
  CHANGE_CONNECTIONS_STATUS,
  REMOVE_TOAST,
  SET_CATEGORY,
  SET_SEARCH,
  ISHOMEROUTEACTIVE,
  BEGINUPLOAD,
  BEGINUPLOAD_RESULT,
  BEGINUPLOAD_ERROR,
  UPLOAD_RESULT,
  UPLOAD_ERROR,
  SETPHOTOSTOUPLOAD,
  REORDERPHOTOS,
  DETELEPHOTOFROMUPLOADARRAY,
  RESETADPHOTOS,
  RESET_UPLOAD_FINISHED,
  SETADIMAGES,
  DELETEPHOTOS,
  DELETEPHOTOS_ERROR,
  DELETEPHOTOS_RESULT,
  CLEAR_COMMON_DATA,
  SENDSUPPORTEMAIL,
  SENDSUPPORTEMAIL_RESULT,
  SENDSUPPORTEMAIL_ERROR,
  EMAILSELLER,
  EMAILSELLER_RESULT,
  EMAILSELLER_ERROR,
  RESET_EMAIL_STATE,
} from "./constants";
import { sendMessage } from "../comm/sender";
import {
  uploadPhoto,
  uploadPhotoRequirements,
  changeUploadStatus,
} from "../../lib/utils";
import { UPLOAD_TYPES, ImageUploadStatus } from "../../types/index";

let initialState = {
  loading: false,
  category: null,
  search: null,
  initLoading: true, //this is only used for WEB application (replacement for SplashScreen)
  toast: [],
  currentlySending: 0,
  appVersion: "",
  social: {},
  isFeedbackModalOpen: false,
  terms: null,
  faq: null,
  privacyPolicy: null,
  allergensInfo: [],
  deviceFcmToken: null,
  isConnectedToNetwork: true,
  isValidationModalOpen: false,
  isChooseMenuModalOpen: false,
  isHomeRouteActive: true,
  myLocation: {
    latitude: null,
    longitude: null,
  },
  error: {
    message: "",
    errors: {},
    errorStatusCode: null,
  },
  coverToUpload: null,
  avatarToUpload: null,
  ad_image_index: 0,
  imageToUpload: null,
  ad_images: [],
  avatar: null,
  created_ad_id: null,
  uploadType: null,
  uploadFinished: false,
  adImagesToDelete: [],
  deletedAdImages: false,
  isSendingEmail: false,
  emailSuccess: false,
  emailError: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ERROR:
      return { ...state, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: initialState.errors };
    case LOADING:
      return { ...state, loading: action.payload };
    case ERROR:
      return { ...state, error: action.error };
    case SHOW_TOAST: {
      const toastWithSameMessage = state.toast.find(
        (t) => t.message === action.message
      );
      // prevent inserting duplicated toasts
      if (toastWithSameMessage) {
        return state;
      } else {
        const toast = {
          message: action.message || "",
          toastType: action.toastType || "warning",
        };
        return {
          ...state,
          toast: [...state.toast, toast, { message: "", toastType: "" }],
        };
      }
    }
    case RESET_TOAST: {
      return { ...state, toast: [] };
    }
    case REMOVE_TOAST: {
      // remove first item from toast array
      const arr = state.toast.slice(1);
      return { ...state, toast: arr };
    }
    case SET_COMMON_MODAL:
      return { ...state, [action.modal]: action.value };
    case CHANGE_CONNECTIONS_STATUS:
      return { ...state, isConnectedToNetwork: action.status };
    case SET_COMMON_PROP:
      return { ...state, [action.key]: action.value };
    case SET_CATEGORY:
      return { ...state, category: action.value };
    case SET_SEARCH:
      return { ...state, search: action.value === "" ? null : action.value };
    case ISHOMEROUTEACTIVE:
      return {
        ...state,
        isHomeRouteActive: action.pathname === "/home" ? true : false,
      };
    case RESETADPHOTOS: {
      return { ...state, ad_images: [] };
    }
    case SETPHOTOSTOUPLOAD: {
      const { photo, croppedImageIndex } = action;
      const photosToUpload = [...state.ad_images];

      if (
        croppedImageIndex !== null &&
        croppedImageIndex < photosToUpload.length
      ) {
        photosToUpload.splice(croppedImageIndex + 1, 0, {
          src: photo,
          id: photosToUpload.length + 1,
          status: ImageUploadStatus.NOT_PROCESSED,
        });
      } else {
        photosToUpload.push({
          src: photo,
          id: photosToUpload.length + 1,
          status: ImageUploadStatus.NOT_PROCESSED,
        });
      }
      return {
        ...state,
        ad_images: photosToUpload,
      };
    }
    case REORDERPHOTOS: {
      return { ...state, ad_images: action.photos };
    }
    case DETELEPHOTOFROMUPLOADARRAY: {
      const updatedPhotos = state.ad_images.filter(
        (item, index) => index !== action.index
      );
      return {
        ...state,
        ad_images: updatedPhotos,
        adImagesToDelete: [...state.adImagesToDelete, action.id + 1],
      };
    }
    case BEGINUPLOAD: {
      const { uploadType, photo, idad } = action;
      switch (uploadType) {
        case UPLOAD_TYPES.MERCHANT_LOGO: {
          const { photoToUpload, fileSize, atobImage } =
            uploadPhotoRequirements(photo);
          sendMessage("beginUpload", [4, fileSize, 1]);
          return {
            ...state,
            avatarToUpload: atobImage,
            loading: true,
            avatar: photoToUpload,
            uploadType: uploadType,
          };
        }

        case UPLOAD_TYPES.USER_AVATAR: {
          const { photoToUpload, fileSize, atobImage } =
            uploadPhotoRequirements(photo);
          sendMessage("beginUpload", [1, fileSize]);
          return {
            ...state,
            avatarToUpload: atobImage,
            loading: true,
            avatar: photoToUpload,
            uploadType: uploadType,
          };
        }

        case UPLOAD_TYPES.MERCHANT_COVER: {
          const { photoToUpload, fileSize, atobImage } =
            uploadPhotoRequirements(photo);
          sendMessage("beginUpload", [6, fileSize, 2]);
          return {
            ...state,
            coverToUpload: atobImage,
            loading: true,
            uploadType: uploadType,
          };
        }

        case UPLOAD_TYPES.AD_IMAGE: {
          const { ad_images, ad_image_index } = state;
          if (ad_images.length === 0) return { ...state, loading: false };

          const photo = ad_images[ad_image_index]?.src;
          const { fileSize, atobImage } = uploadPhotoRequirements(photo);
          sendMessage("beginUpload", [
            14,
            fileSize,
            ad_images[ad_image_index]?.id,
            idad,
          ]);

          return {
            ...state,
            imageToUpload: atobImage,
            loading: true,
            created_ad_id: idad,
            uploadType: uploadType,
            ad_images: changeUploadStatus(
              ad_images,
              ImageUploadStatus.STARTED,
              ad_image_index
            ),
          };
        }
      }
      break;
    }
    case BEGINUPLOAD_RESULT: {
      const uploadType = action.params.request.params[0];
      switch (uploadType) {
        case UPLOAD_TYPES.MERCHANT_LOGO: {
          uploadPhoto(state.avatarToUpload);
          return { ...state };
        }
        case UPLOAD_TYPES.USER_AVATAR: {
          uploadPhoto(state.avatarToUpload);
          return { ...state };
        }
        case UPLOAD_TYPES.MERCHANT_COVER: {
          uploadPhoto(state.coverToUpload);
          return { ...state };
        }
        case UPLOAD_TYPES.AD_IMAGE: {
          const { imageToUpload, ad_image_index, ad_images } = state;

          uploadPhoto(imageToUpload);
          return {
            ...state,
            ad_image_index: ad_image_index + 1,
            ad_images: changeUploadStatus(
              ad_images,
              ImageUploadStatus.DONE,
              ad_image_index
            ),
          };
        }
      }
      break;
    }
    case BEGINUPLOAD_ERROR: {
      const { ad_images, ad_image_index } = state;
      return {
        ...state,
        ad_images: changeUploadStatus(
          ad_images,
          ImageUploadStatus.ERROR,
          ad_image_index
        ),
      };
    }
    case UPLOAD_RESULT: {
      if (state.uploadType === UPLOAD_TYPES.AD_IMAGE) {
        return {
          ...state,
          ad_image_index: 0,
          uploadType: null,
          loading: false,
          uploadFinished: true,
        };
      } else {
        return { ...state, uploadType: null, loading: false };
      }
    }
    case UPLOAD_ERROR: {
      return { ...state, loading: false };
    }
    case RESET_UPLOAD_FINISHED: {
      return { ...state, uploadFinished: false };
    }
    case SETADIMAGES: {
      return { ...state, ad_images: action.images };
    }
    case DELETEPHOTOS: {
      if (action.adId) {
        sendMessage("deletePhotos", [
          UPLOAD_TYPES.AD_IMAGE,
          action.adId,
          action.images,
        ]);
      }

      return {
        ...state,
        adImagesToDelete: [],
      };
    }
    case DELETEPHOTOS_ERROR: {
      return { ...state };
    }
    case DELETEPHOTOS_RESULT: {
      return {
        ...state,
        deletedAdImages: true,
      };
    }
    case CLEAR_COMMON_DATA: {
      action.params.forEach((value) => {
        delete state[value];
      });
      return { ...state };
    }
    case SENDSUPPORTEMAIL: {
      sendMessage("sendSupportEmail", [action.params]);
      return {
        ...state,
        isSendingEmail: true,
        emailSuccess: false,
        emailError: null,
      };
    }
    case SENDSUPPORTEMAIL_RESULT:
      return {
        ...state,
        emailSuccess: true,
      };
    case SENDSUPPORTEMAIL_ERROR:
      return {
        ...state,
        emailError: action.payload,
      };

    case EMAILSELLER: {
      sendMessage("emailSeller", action.params);
      return {
        ...state,
        emailSuccess: false,
        emailError: null,
      };
    }

    case EMAILSELLER_RESULT:
      return {
        ...state,
        emailSuccess: true,
      };
    case EMAILSELLER_ERROR:
      return {
        ...state,
        emailError: action.params.params[1],
      };

    case RESET_EMAIL_STATE:
      return {
        ...state,
        isSendingEmail: false,
        emailSuccess: false,
        emailError: null,
      };

    default:
      return state;
  }
}

export default reducer;
