import React from "react";
import { Box, Typography } from "@mui/material";
import { ArrowDownRed } from "../svg-icons";
import { useTranslation } from "react-i18next";
import "./index.css";

const Sort = (props) => {
  const { handler, label } = props;
  const { t } = useTranslation(["results, common"]);
  return (
    <Box className="sort">
      <Typography variant="caption" className="secondary-font-color">
        {t("common:sort-by")}:
      </Typography>
      <Typography variant="caption" onClick={handler}>
        {t(label)}
        {ArrowDownRed}
      </Typography>
    </Box>
  );
};

export default Sort;
