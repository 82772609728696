import React from "react";
import TextField from "@mui/material/TextField";
// import InputAdornment from "@mui/material/InputAdornment";
import { withStyles } from "@mui/styles";
import "./index.css";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  },
})(TextField);

const Textfield = (props) => {
  const {
    id,
    type,
    value,
    changeHandler,
    clickHandler,
    readOnly,
    placeholder,
    disabled,
    maxRows,
    multiline,
    defaultValue,
    name,
    InputProps,
  } = props;
  return (
    <div className="input-text-field">
      <CssTextField
        id={id}
        type={type}
        name={name}
        variant="outlined"
        placeholder={placeholder ? placeholder : null}
        value={value}
        defaultValue={defaultValue && defaultValue}
        disabled={disabled}
        onChange={(e) => changeHandler(e)}
        onClick={clickHandler}
        multiline={multiline}
        maxRows={maxRows}
        InputProps={{
          readOnly: readOnly,
          inputProps: {
            min: 0,
          },
          ...InputProps,
        }}
      />
    </div>
  );
};

export default Textfield;
