import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, Redirect, useLocation } from "react-router";
import { connect, Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./components/error-boundary";
import { getDefaultRoute } from "./lib/utils";
import { store } from ".";
import navConfig from "./.nav-config";
import NotFound from "./screens/not-found";
import ProtectedRoute from "./components/protected-route";
import Layout from "./components/layout/index";
import ScrollToTop from "./lib/scroll-to-top";
import ClearState from "./screens/clear-state";
import { useHistory } from "react-router-dom";
import SEO from "./components/seo/index";

const App = (props) => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const { category } = props;
  const mapRoutes = (routes, extraProps = {}) => {
    return routes
      .filter((route) => !!route.path && !!route.component)
      .map((item) => {
        const { path } = item;
        const ActualRoute = item.protected ? ProtectedRoute : Route;
        const isChangePasswordRoute = path.includes("/change-password");

        return (
          <ActualRoute
            exact={item.exact}
            path={isChangePasswordRoute ? path : `/${i18n.language}${path}`}
            key={"nav-key-" + path}
            route={item}
            // component={ item.component }
            render={(props) => {
              return item.render ? (
                item.render({ ...props, ...extraProps, route: item })
              ) : (
                <item.component {...props} {...extraProps} route={item} />
              );
            }}
          />
        );
      });
  };

  const supportedLanguages = ["it", "de", "fr", "en"];

  useEffect(() => {
    const { pathname } = window.location;
    //const currentLangPrefix = `/${i18n.language}/`;

    // Check if the current path contains any language prefix from the supported languages
    const pathSegments = pathname.split("/").filter((segment) => segment);
    const potentialLang = pathSegments[0];
    // Skip adding a language prefix for the 'change-password' route
    if (pathname.includes("/change-password")) return;
    if (supportedLanguages.includes(potentialLang)) {
      // Remove the language prefix if it's there
      pathSegments.shift();
    }

    const newPathname = `/${i18n.language}/${pathSegments.join("/")}`;

    if (newPathname !== pathname) {
      history.replace(newPathname);
    }
  }, [i18n.language, history]);

  const defaultRoute = getDefaultRoute();
  const routeComponents = mapRoutes(navConfig.routes, { category });
  const authRouteComponents = mapRoutes(navConfig.authRoutes, { category });
  const additionalRouteComponents = mapRoutes(navConfig.additionalRoutes, {
    category,
  });
  const footerRouteComponents = mapRoutes(navConfig.footerRoutes, { category });
  const notInMenuRouteComponents = mapRoutes(navConfig.notInMenuRoutes, {
    category,
  });
  const { pathname } = useLocation();

  return (
    <Provider store={store}>
      <ErrorBoundary>
        <SEO
          title="CARINDEX by AUTOMOBIL REVUE - Autos kaufen und verkaufen in der Schweiz"
          description="CARINDEX by AUTOMOBIL REVUE - Autos kaufen und verkaufen in der Schweiz"
          image="https://carindex.ch/2bbe81052c59fa374a9cebdc4147b9d6.png"
          url={window.location.href}
        />
        <ScrollToTop dependency={[pathname]} />
        <ClearState pathname={pathname} />
        <Layout className="mdc-drawer-app-content">
          <Switch>
            {routeComponents}
            {authRouteComponents}
            {additionalRouteComponents}
            {footerRouteComponents}
            {notInMenuRouteComponents}
            {defaultRoute ? (
              <Route
                exact
                path="/"
                render={() => {
                  const params = new URLSearchParams(window.location.search);
                  // if "share" query param exists, the url is from firebase and should redirect user to the detail page
                  if (params.has("share")) {
                    const idAd = params.get("share");
                    return <Redirect to={`/${i18n.language}/ad/${idAd}`} />;
                  }
                  return (
                    <Redirect to={`/${i18n.language}${defaultRoute.path}`} />
                  );
                }}
              />
            ) : null}
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Layout>
        <ToastContainer />
      </ErrorBoundary>
    </Provider>
  );
};

const stateToProps = (state) => {
  const { category } = state.common;
  return {
    category,
  };
};

export default connect(stateToProps)(App);
