import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { formatAddress, scroll } from "../../../../lib/utils";
import Api from "../../../../lib/api";
import { addMerchtCard } from "../../../../store/actions";
import { TYPES_OF_MERCHANT } from "../../../../types";
import { defaultLocation } from "../../../../data";
import Avatar from "../../../../components/avatar";
import Button from "../../../../components/button";
import MerchantForm from "../../../../components/merchant-form";
import ShownContactInfo from "../../../../components/user-info";
import "./index.css";
import { useGoogleMap } from "../../../../hooks";

const MerchantDetails = (props) => {
  const { dispatch, profileData } = props;
  const { t } = useTranslation(["common", "profile", "sell"]);
  const [localProfileData, setLocalProfileData] = useState(
    JSON.parse(JSON.stringify(profileData))
  );
  const [updatedProfileData, setUpdatedProfileData] = useState(null);
  const { getCoordinatesForAddress } = useGoogleMap();
  const [errorMessages, setErrorMessages] = useState({});
  const [addressData, setAddressData] = useState({});
  const [changeMap, setChangeMap] = useState(1);
  const [canSyncMap, setCanSyncMap] = useState(true);
  const phoneTypes = [
    { name: `${t("common:business")}`, id: 1 },
    { name: `${t("common:private")}`, id: 2 },
  ];

  const hasAddressChanged = (value) => {
    switch (value) {
      case "streetandnumber":
      case "city":
      case "country":
        return true;
      default:
        return false;
    }
  };

  const isCompany = (merchantType) =>
    TYPES_OF_MERCHANT.COMPANY === merchantType;

  /**
   *-----------------------------------------------
   * UPDATE PROFILE DATA
   *-----------------------------------------------
   */
  const inputChangeHandler = (argument, value, change, pIndex = null) => {
    let data = JSON.parse(JSON.stringify(localProfileData));
    if (change === "address") {
      data = {
        ...data,
        address_data: { ...data.address_data, [argument]: value },
      };
    }
    if (change === "type") {
      data = { ...data, type: value };
      if (!isCompany(value)) {
        data = { ...data, company: "" };
      }
    }
    if (change === "phone") {
      data.phones[pIndex] = { ...data.phones[pIndex], [argument]: value };
    } else {
      data[argument] = value;
      if (argument === "country" && value !== "Switzerland") {
        delete data.address_data.region;
      }
    }
    if (hasAddressChanged(argument)) {
      setCanSyncMap(true);
    }
    setLocalProfileData(data);
  };

  /**
   *-----------------------------------------------
   * ADD ANOTHER SET OF PHONE INPUTS
   *-----------------------------------------------
   */
  const addPhoneNumber = () => {
    let data = {
      ...localProfileData,
      phones: [
        ...localProfileData.phones,
        { type: "", number: "", id: Date.now() },
      ],
    };
    if (localProfileData.phones === null) {
      data = {
        ...localProfileData,
        phones: [{ type: "", number: "", id: Date.now() }],
      };
    }
    setLocalProfileData(data);
  };

  /**
   *-----------------------------------------------
   * REMOVE PHONE BY PHONE INDEX
   *-----------------------------------------------
   */
  const removePhoneNumber = (id) => {
    const data = { ...localProfileData };
    data.phones = data.phones.filter((item) => item.id !== id);
    setLocalProfileData(data);
  };

  /**
   *-----------------------------------------------
   * UPDATE PROFILE HANDLER
   *-----------------------------------------------
   */
  const updateProfileHandler = async () => {
    const errors = [];
    if (!localProfileData?.country || localProfileData?.country === "") {
      errors.push({
        name: "country",
        message: "common:required-error-message",
        target: "#country-scroll-target",
      });
    }
    if (!localProfileData?.city || localProfileData?.city === "") {
      errors.push({
        name: "city",
        message: "common:required-error-message",
        target: "#city-scroll-target",
      });
    }
    if (
      localProfileData?.phones[0]?.type === "" ||
      localProfileData?.phones[0]?.phoness === ""
    ) {
      errors.push({
        name: "number",
        message: "common:required-error-message",
        target: "#number-scroll-target",
      });
    }
    if (
      (!localProfileData?.company || localProfileData?.company === "") &&
      localProfileData?.type === 2
    ) {
      errors.push({
        name: "company",
        message: "common:required-error-message",
        target: "#company-scroll-target",
      });
    }
    if (errors.length > 0) {
      const errorData = {};
      errors.forEach((error, index) => {
        if (index === 0) {
          scroll(error.target);
        }
        errorData[error.name] = error.message;
      });
      setErrorMessages(errorData);
    } else {
      if (localProfileData?.country !== "Switzerland") {
        delete localProfileData?.address_data?.region;
      }
      if (!localProfileData?.location) {
        const addressData = await getCoordinatesForAddress(
          localProfileData?.address_data?.streetandnumber,
          localProfileData?.city,
          localProfileData?.country
        );
        // console.log("ADDRESSSSSSSSSS DATAAAAAAA ", addressData);
        localProfileData.location = {
          latitude: addressData.location.latitude ?? defaultLocation.lat,
          longitude: addressData.location.longitude ?? defaultLocation.lng,
        };
      }
      setUpdatedProfileData(Date.now());
      setLocalProfileData(localProfileData);
      dispatch(addMerchtCard(localProfileData));
      // console.log("AFTER CALL", localProfileData);
    }
  };

  const findOnMap = async () => {
    try {
      if (!canSyncMap) return null;
      const formattedAddress = formatAddress(
        localProfileData?.address_data?.streetandnumber,
        localProfileData?.city,
        localProfileData?.country
      );
      if (!formattedAddress) return null;
      const result = await Api.geocoding(formattedAddress);
      const lat = result?.results[0]?.geometry.location.lat;
      const lng = result?.results[0]?.geometry.location.lng;
      const data = { ...localProfileData, location: localProfileData.location };
      data.location = {
        latitude: lat,
        longitude: lng,
      };
      setLocalProfileData(data);
      setChangeMap((prevState) => prevState + 1);
      setCanSyncMap(false);
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    const _profileData = profileData;
    if (!isCompany(_profileData.type)) {
      _profileData.company = "";
    }
    setLocalProfileData(_profileData);
  }, [profileData]);

  useEffect(() => {
    const data = { ...localProfileData };
    if (Object.keys(addressData).length !== 0) {
      data.city = addressData.city;
      data.country = addressData.country;
      data.address_data = {
        ...data.address_data,
        streetandnumber: addressData.address_data.streetandnumber,
        zipcode: addressData.address_data.zipcode,
      };
      data.location = {
        latitude: addressData.location.latitude,
        longitude: addressData.location.longitude,
      };
      setLocalProfileData(data);
    }
  }, [addressData]);

  return (
    <div className="user-contact">
      <div className="usercontact-main">
        <div>
          <div className="user-contact-header">
            <h5>{t("sell:contact-details")}</h5>
            <p>{t("profile:contact-details-instruction")}</p>
          </div>
          <div className="userprofile-form">
            <MerchantForm
              inputChangeHandler={inputChangeHandler}
              profileData={localProfileData}
              phoneTypes={phoneTypes}
              addPhoneNumber={addPhoneNumber}
              removePhoneNumber={removePhoneNumber}
              errorMessages={errorMessages}
              setAddressData={setAddressData}
              findOnMap={findOnMap}
              changeMap={changeMap}
              updatedProfileData={updatedProfileData}
            />
          </div>
        </div>
        <div>
          <ShownContactInfo>
            <Avatar customClass="small-avatar" circularSize={10} />
          </ShownContactInfo>
        </div>
      </div>
      <div className="user-contact-bottom">
        <div>
          <Button
            color="red"
            className="user-contact-button"
            label={t("common:save-all-changes")}
            click={updateProfileHandler}
          />
        </div>
        <div></div>
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const { profileData } = state.profile;
  return {
    profileData,
  };
};

export default connect(stateToProps)(MerchantDetails);
