import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box, TextField } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import GoogleMapReact from "google-map-react";
import { useTranslation } from "react-i18next";
import {
  checkboxChange,
  resetCheckbox,
  setRootAdData,
  setGeneralData,
  setEngineEnviroment,
  getDriveType,
} from "../../../store/actions";
import { numericMonths as months, years } from "../../../data";
import { removePropsFromObject } from "../../../lib/utils";
import Select from "../../../components/select/index";
import IdentifyVehicle from "../00-identify-vehicle/index";
import CheckboxField from "../../../components/checkbox-field";
import FormControl from "../../../components/form-control";
import Radio from "../../../components/radio";
import NumberFormatCustom from "../../../components/numberFormatCustom/index";
import { useVehicleData, useProfileDataLocation } from "../../../hooks";
import { CoordinateType } from "../../../hooks/useProfileDataLocation";
import "./index.css";

const AddDataType = {
  ROOT_DATA: "rootData",
  POWER_DATA: "powerData",
  ENGINE_DATA: "engineData",
};

const Make = (props) => {
  const {
    dispatch,
    make,
    car_model,
    body_type,
    fuel_type,
    procedeToMakePage,
    continueWithoutVehicleRegistration,
    setNextButton,
    ad_data,
    vehicle_location,
    general_data,
    engine_environment,
    transmission,
    drive_type,
    setIsOnTargaMobileView,
  } = props;

  const { t } = useTranslation(["common", "sell", "home"]);
  const languageSmallLetter = localStorage.getItem("i18nextLng");
  const language = languageSmallLetter.toUpperCase();
  const transformed_transmission = transmission.filter((item) => item.name);
  useVehicleData();
  const { loadMap, getCoordinates } = useProfileDataLocation();

  /**
   * OVO TREBA MODIFIKOVATI
   * @param {Array} arrName
   * @param {Any} value
   * @param {String} key
   */
  const checkboxChangeHandler = (arrName, value, key) => {
    if (arrName === "fuel_type" || arrName === "body_type") {
      const updatedGeneralData = removePropsFromObject(general_data, [
        "equipmentline",
      ]);
      dispatch(setGeneralData(updatedGeneralData));
    }
    dispatch(checkboxChange(arrName, value, key));
    setAdDataHandler(key, value);
  };

  /**
   *-----------------------------------------------
   * SET BASIC AD PROPERTIES
   *-----------------------------------------------
   *
   * @param {String} argument
   * @param {String || Number} value
   */
  const setAdDataHandler = (
    argument,
    value,
    destinationsObject = AddDataType.ROOT_DATA
  ) => {
    if (value === "" || isNaN(value)) {
      let updateAdData = ad_data;
      let updateGeneralData = general_data;
      let updateEngineData = engine_environment;
      switch (argument) {
        case "idbodytype": {
          updateAdData = removePropsFromObject(ad_data, [
            "idbodytype",
            "idmake",
            "idmodel",
            "idfueltype",
            "mileage",
            "price",
          ]);

          dispatch(resetCheckbox("fuel_type"));
          break;
        }
        case "month": {
          updateAdData = removePropsFromObject(ad_data, [
            "month",
            "idmake",
            "idmodel",
            "idfueltype",
            "mileage",
            "price",
          ]);

          dispatch(resetCheckbox("fuel_type"));
          break;
        }
        case "year": {
          updateAdData = removePropsFromObject(ad_data, [
            "year",
            "idmake",
            "idmodel",
            "idfueltype",
            "mileage",
            "price",
          ]);
          dispatch(resetCheckbox("fuel_type"));
          break;
        }
        case "idmake": {
          updateAdData = removePropsFromObject(ad_data, [
            "idmake",
            "idmodel",
            "idfueltype",
            "mileage",
            "price",
          ]);
          dispatch(resetCheckbox("fuel_type"));
          break;
        }
        case "idmodel": {
          updateAdData = removePropsFromObject(ad_data, [
            "idmodel",
            "idfueltype",
            "mileage",
            "price",
          ]);
          dispatch(resetCheckbox("fuel_type"));
          break;
        }
        case "idfueltype": {
          updateAdData = removePropsFromObject(ad_data, [
            "idfueltype",
            "mileage",
            "price",
          ]);
          break;
        }
        case "power": {
          updateAdData = removePropsFromObject(ad_data, ["mileage", "price"]);
          updateEngineData = removePropsFromObject(engine_environment, [
            "idtransmission",
            "iddrivetype",
          ]);
          updateGeneralData = removePropsFromObject(general_data, ["power"]);
          break;
        }
        case "mileage": {
          updateAdData = removePropsFromObject(ad_data, ["mileage", "price"]);
          updateEngineData = removePropsFromObject(engine_environment, [
            "idtransmission",
            "iddrivetype",
          ]);
          break;
        }
        case "price": {
          updateAdData = removePropsFromObject(ad_data, ["price"]);
          updateEngineData = removePropsFromObject(engine_environment, [
            "idtransmission",
            "iddrivetype",
          ]);
          break;
        }
        case "transmission": {
          updateEngineData = removePropsFromObject(engine_environment, [
            "iddrivetype",
          ]);
          break;
        }
      }
      dispatch(setRootAdData(updateAdData));
      dispatch(setGeneralData(updateGeneralData));
      dispatch(setEngineEnviroment(updateEngineData));
    } else {
      if (argument === "idmake") {
        let updateAdData = removePropsFromObject(ad_data, [
          "idmodel",
          "idfueltype",
          "mileage",
          "price",
          "idtransmission",
          "iddrivetype",
        ]);
        updateAdData = { ...updateAdData, [argument]: value };
        dispatch(setRootAdData(updateAdData));
      } else {
        switch (destinationsObject) {
          case AddDataType.ROOT_DATA:
            dispatch(setRootAdData({ ...ad_data, [argument]: value }));
            break;
          case AddDataType.POWER_DATA:
            dispatch(setGeneralData({ ...general_data, [argument]: value }));
            break;
          case AddDataType.ENGINE_DATA:
            dispatch(
              setEngineEnviroment({ ...engine_environment, [argument]: value })
            );
            break;

          default:
            break;
        }
      }
    }
    if (argument === "idmake" || argument === "idmodel") {
      const updatedGeneralData = removePropsFromObject(general_data, [
        "equipmentline",
      ]);
      dispatch(setGeneralData(updatedGeneralData));
    }
  };

  const hasBasicInfoFilled = () => {
    if (
      ad_data?.idbodytype &&
      ad_data?.idfueltype &&
      ad_data?.idmake &&
      ad_data?.idmodel &&
      ad_data?.mileage &&
      ad_data?.month &&
      ad_data?.price &&
      ad_data?.year &&
      general_data?.power &&
      engine_environment?.idtransmission &&
      engine_environment?.iddrivetype
    ) {
      return true;
    }
    return false;
  };
  /**
   *-----------------------------------------------
   * DISABLE OR ENABLE NEXT BUTTON
   *-----------------------------------------------
   *
   */
  useEffect(() => {
    if (hasBasicInfoFilled()) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
  }, [ad_data, general_data, engine_environment]);

  /**
   *-----------------------------------------------
   * BODY TYPE PICKER LABEL COMPONENT
   *-----------------------------------------------
   *
   */
  const Bodytypelabel = (props) => {
    const { imageSrc, name } = props;
    let label = "Other";
    if (name[language ? language.toUpperCase() : "EN"]) {
      label = name[language ? language.toUpperCase() : "EN"];
    }
    return (
      <div className="bodytype-label">
        <img src={imageSrc} alt="" />
        <p>{label}</p>
      </div>
    );
  };

  /**
   *-----------------------------------------------
   * FETCH NECESSARRY DATA ON PAGE INIT
   *-----------------------------------------------
   */
  useEffect(() => {
    dispatch(getDriveType());
  }, [general_data]);

  //set to false on mount bc we need header layout to be visible here
  //(check targaOnMobileVIew and sell if you need to investigate this)
  useEffect(() => {
    setIsOnTargaMobileView(false);
  }, []);

  console.log("language", language);

  return (
    <>
      {procedeToMakePage ? (
        <div className="sell-make">
          <FormControl label={t("sell:select-the-type")} className="bodytype">
            {body_type.map((item, index) => {
              if (!!item.src) {
                return (
                  <RadioGroup
                    key={index}
                    aria-label="bodytype"
                    name="bodytype"
                    onChange={() =>
                      checkboxChangeHandler(
                        "body_type",
                        item.idbodytype,
                        "idbodytype"
                      )
                    }
                  >
                    <Radio
                      name={item.name}
                      value={
                        item.name[language ? language.toUpperCase() : "EN"]
                      }
                      checked={item.idbodytype === ad_data.idbodytype}
                      id={item.idbodytype}
                      label={
                        <Bodytypelabel imageSrc={item.src} name={item.name} />
                      }
                    />
                  </RadioGroup>
                );
              }
            })}
          </FormControl>
          <div className="withoutregistration">
            <h4>{t("sell:without-registration-card")}</h4>
            <h5>{t("sell:without-the-information")}</h5>
          </div>
          <FormControl
            label={t("sell:month-and-year-of-constr")}
            className="registration"
          >
            <Select
              label="month"
              data={months}
              handleChange={(event, newValue) =>
                setAdDataHandler("month", newValue ? newValue.month : "")
              }
              defaultValue={months.find(
                (item) => item.month === ad_data?.month
              )}
            />
            <Select
              label="year"
              data={years}
              handleChange={(event, newValue) =>
                setAdDataHandler("year", newValue ? newValue.year : "")
              }
              defaultValue={years.find((item) => item.year === ad_data?.year)}
            />
          </FormControl>
          {((ad_data.idbodytype && ad_data.month && ad_data.year) ||
            ad_data.idmake) && (
            <FormControl
              inputType="select"
              label={t("common:make")}
              data={make}
              value={make.find((item) => item.idmake === ad_data?.idmake)}
              handleChange={(event, newValue) =>
                setAdDataHandler("idmake", newValue ? newValue.idmake : "")
              }
            />
          )}
          {(ad_data.idmake || ad_data.idmodel) && (
            <FormControl
              inputType="select"
              label={t("home:model")}
              data={car_model}
              value={car_model.find(
                (item) => item.idmodel === ad_data?.idmodel
              )}
              handleChange={(event, newValue) =>
                setAdDataHandler("idmodel", newValue ? newValue.idmodel : "")
              }
            />
          )}
          {ad_data.idmodel && (
            <FormControl label={t("common:fuel")} className="fueltype">
              {fuel_type.map((item, index) => {
                return (
                  !!item.name[language] && (
                    <CheckboxField
                      label={
                        item.name[language ? language.toUpperCase() : "EN"]
                      }
                      checked={item.idfueltype === ad_data.idfueltype}
                      key={index}
                      className={"checkbox"}
                      changeHandler={() =>
                        checkboxChangeHandler(
                          "fuel_type",
                          item.idfueltype,
                          "idfueltype"
                        )
                      }
                    />
                  )
                );
              })}
            </FormControl>
          )}
          {ad_data.idfueltype && (
            <FormControl className="input-with-abbreviation">
              <span className="label">{t("common:power")}</span>
              <Box className="content">
                <TextField
                  name="power"
                  placeholder={t("common:enter")}
                  value={general_data.power}
                  variant="outlined"
                  onChange={(event) =>
                    setAdDataHandler(
                      "power",
                      parseInt(event.target.value),
                      AddDataType.POWER_DATA
                    )
                  }
                  InputProps={{
                    endAdornment: <span className="abbreviation">PS</span>,
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Box>
            </FormControl>
          )}
          {general_data?.power && (
            <FormControl className="input-with-abbreviation">
              <span className="label">{t("common:mileage")}</span>
              <Box className="content">
                <TextField
                  placeholder={t("common:type")}
                  value={ad_data?.mileage}
                  variant="outlined"
                  onChange={(event) =>
                    setAdDataHandler("mileage", parseInt(event.target.value))
                  }
                  InputProps={{
                    endAdornment: <span className="abbreviation">km</span>,
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Box>
            </FormControl>
          )}
          {ad_data?.mileage && (
            <FormControl className="input-with-abbreviation">
              <span className="label">{t("common:price")}</span>
              <Box className="content">
                <TextField
                  name="price"
                  placeholder={t("common:type")}
                  value={ad_data.price}
                  variant="outlined"
                  onChange={(event) =>
                    setAdDataHandler("price", parseInt(event.target.value))
                  }
                  InputProps={{
                    endAdornment: <span className="abbreviation">CHF</span>,
                    inputComponent: NumberFormatCustom,
                  }}
                />
              </Box>
            </FormControl>
          )}
          {ad_data?.price && (
            <FormControl
              inputType="select"
              label={t("common:transmission")}
              data={transformed_transmission}
              value={transformed_transmission.find(
                (item) =>
                  item.idtransmission === engine_environment?.idtransmission
              )}
              handleChange={(event, newValue) =>
                setAdDataHandler(
                  "idtransmission",
                  newValue ? newValue.idtransmission : "",
                  AddDataType.ENGINE_DATA
                )
              }
            />
          )}
          {engine_environment?.idtransmission && (
            <FormControl
              inputType="select"
              label={t("common:drivetrain")}
              data={drive_type}
              value={drive_type.find(
                (item) => item.iddrivetype === engine_environment?.iddrivetype
              )}
              handleChange={(event, newValue) =>
                setAdDataHandler(
                  "iddrivetype",
                  newValue ? newValue.iddrivetype : "",
                  AddDataType.ENGINE_DATA
                )
              }
            />
          )}
          {Object.keys(ad_data).length >= 8 &&
            Object.keys(general_data).length >= 1 &&
            Object.keys(engine_environment).length >= 2 && (
              <FormControl
                label={t("sell:location-of-the-vehicle")}
                className="vehicle-location"
              >
                <p>{t("profile:drag-pin")}</p>
                <div className="map-content">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.GOOGLE_API_KEY,
                      libraries: ["marker"],
                    }}
                    defaultCenter={{
                      lat: getCoordinates(CoordinateType.LATITUDE),
                      lng: getCoordinates(CoordinateType.LONGITUDE),
                    }}
                    defaultZoom={10}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
                    options={{
                      mapId: process.env.GOOGLE_MAP_ID,
                    }}
                  />
                  <FormControl
                    inputType="textfield"
                    value={
                      vehicle_location?.place ? vehicle_location?.place : ""
                    }
                    disabled={true}
                  />
                </div>
              </FormControl>
            )}
        </div>
      ) : (
        <IdentifyVehicle redirect={continueWithoutVehicleRegistration} />
      )}
    </>
  );
};

const stateToProps = (state) => {
  const {
    make,
    body_type,
    fuel_type,
    car_model,
    power,
    ad_data,
    vehicle_location,
    general_data,
    engine_environment,
    transmission,
    drive_type,
  } = state.car;

  return {
    make,
    body_type,
    fuel_type,
    car_model,
    power,
    ad_data,
    vehicle_location,
    general_data,
    engine_environment,
    transmission,
    drive_type,
  };
};

export default connect(stateToProps)(Make);
