import React from "react";
import { ArrowRight, ArrowDown } from "../svg-icons";
// import { ArrowDown } from "../icons/ArrowDown";
import "./index.css";

export const NavDropDown = (props) => {
  const { title, subtitle, titleClass, subtitleClass, rootClass, click } =
    props;
  return (
    <div className={`nav-dropdown ${rootClass}`}>
      {subtitle && <p className={subtitleClass}>{subtitle}</p>}
      <span className={`title ${titleClass}`} onClick={click}>
        <p>{title}</p>
        {subtitle ? <ArrowDown /> : <ArrowRight />}
      </span>
    </div>
  );
};
