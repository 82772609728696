import React from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Textfield from "../../components/text-field";
import { useTranslation } from "react-i18next";

import "./index.css";

const datePicker = (props) => {
  const { value, changeHandler } = props;
  const { t, i18n } = useTranslation(["common"]);

  const currentLang = i18n.language.split("-")[0]; //"de-CH";
  moment.locale(currentLang);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={currentLang}>
      <DatePicker
        onChange={changeHandler}
        value={value}
        inputVariant="outlined"
        disableToolbar={true}
        cancelLabel={t("common:cancel")}
        okLabel={t("common:ok")}
        TextFieldComponent={(inputProps) => {
          return (
            <Textfield
              clickHandler={inputProps.onClick}
              value={inputProps.value}
              placeholder={t("common:select")}
            />
          );
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default datePicker;
