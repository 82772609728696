import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setPhotosToUpload,
  deletePhoto,
  reorderPhotos,
  setAdImages,
} from "../../../store/actions";
import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { DialogModal } from "../../../components/dialog";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
import CloseIcon from "../../../assets/shapes/close.svg";
import Checked from "../../../assets/shapes/checked.svg";
import Button from "../../../components/button/index";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "react-image-crop/dist/ReactCrop.css";
import "./index.css";
const MAX_NUMBER_OF_PHOTOS = 25;

const imageUpload = (props) => {
  const {
    dispatch,
    ad_images,
    activeError,
    setActiveError,
    photos,
    isCreateAdPage,
  } = props;
  const acceptedFileTypes =
    "image/x-png, image/png, image/jpg, image/jpeg, image/gif";
  const [openModal, setOpenModal] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [cropImageIndex, setCropImageIndex] = useState(null);
  const [cropMode, setCropMode] = useState(false);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 3 / 2 });
  const [imageRef, setImageRef] = useState(null);
  const canvas = document.createElement("canvas");
  const [imageIndexToDelete, setImageIndexToDelete] = useState(null);
  const { t } = useTranslation("sell");
  const grid = 8;

  useEffect(() => {
    if (!isCreateAdPage) {
      if (ad_images.length === 0) {
        //Ensure existing images are not overridden
        dispatch(setAdImages(photos));
      }
    }
  }, []);

  const handleOnDrop = async (files) => {
    const validFiles = files.filter((file) =>
      acceptedFileTypes.includes(file.type)
    );
    const remainingSpace = MAX_NUMBER_OF_PHOTOS - ad_images.length;
    const filesToUpload = validFiles.slice(0, remainingSpace);
    for (const file of filesToUpload) {
      await readFileAsDataURL(file);
    }
  };

  const readFileAsDataURL = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        dispatch(setPhotosToUpload(reader.result, null));
        resolve();
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const onImageLoaded = (image) => {
    setImageRef(image);
    setCrop({
      unit: "%",
      width: 50,
      height: 50,
      x: 0,
      y: 25,
      aspect: 3 / 2,
    });
    return false;
  };

  const onCropComplete = async (pixelCrop, percentCrop) => {
    image64toCanvasRef(
      canvas,
      ad_images[cropImageIndex]?.src,
      pixelCrop,
      imageRef
    );
  };
  const submitCroppedImage = () => {
    const fileExtension = extractImageFileExtensionFromBase64(
      ad_images[cropImageIndex]?.src
    );
    const imageData64 = canvas.toDataURL("image/png", 1); //cropped images stored
    const fileName = "previewFile." + fileExtension;
    const myNewCroppedFile = base64StringtoFile(imageData64, fileName); //upload image
    // downloadBase64File(imageData64, fileName); //download image

    dispatch(setPhotosToUpload(imageData64, cropImageIndex));
    setOpenModal(false);
    setCropMode(false);
  };

  const deleteImageHandler = () => {
    dispatch(deletePhoto(imageIndexToDelete[0], imageIndexToDelete[1]));
    setIsDialogVisible(false);
  };

  const closeCropModal = () => {
    setOpenModal(false);
    setCropMode(false);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    const tmp = result[startIndex].id;
    result[startIndex].id = result[endIndex].id;
    result[endIndex].id = tmp;

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#eae5e5" : "white",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    padding: grid,
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      ad_images,
      result.source.index,
      result.destination.index
    );
    dispatch(reorderPhotos(items));
  };

  const hideDialog = () => {
    setIsDialogVisible(false);
  };
  const hideError = () => {
    setActiveError(false);
  };

  return (
    <div>
      <div className="upload-instructions">
        <div>
          <p>{t("sell:upload-image-instruction1")}</p>
          <p>{t("sell:upload-image-instruction1.1")}</p>
        </div>
        <div>
          <p>{t("sell:upload-image-instruction2")}</p>
          <p>{t("sell:upload-image-instruction2.1")}</p>
        </div>
        <div>
          <p>{t("sell:upload-image-instruction3")}</p>
        </div>
        <div>
          <p>{t("sell:tip")}</p>
          <span>
            <p>{t("sell:upload-image-instruction4")}</p>
            <p>{t("sell:upload-image-instruction4.1")}</p>
          </span>
        </div>
      </div>
      <div className="photos-container">
        <h4 className="secondary-font-color font-weight-normal">
          {t("sell:choose-and-upload")}
        </h4>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => {
              return (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {ad_images &&
                    ad_images.map((item, index) => {
                      if (!item?.src) return null;
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={
                            item.src + item.id ? item.id.toString() : "0"
                          }
                          index={index}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                className="dropped-image-card"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {/* <div> */}
                                <div className="flex-col-wrapper large-gap">
                                  <img src={item.src} alt="" />
                                  <div className="flex-row-wrapper flex-justify-between">
                                    <h5 className="upload-photo-name">
                                      {index === 0
                                        ? t("sell:main-photo")
                                        : `${t("common:photo")} ${index + 1}`}
                                    </h5>
                                    <span>
                                      <Button
                                        label={t("common:preview")}
                                        className="image-control-button"
                                        color="transparent"
                                        click={() => {
                                          setOpenModal(true);
                                          setCropImageIndex(index);
                                        }}
                                      />
                                      <Button
                                        label={t("common:delete")}
                                        className="image-control-button"
                                        color="transparent"
                                        click={() => {
                                          setIsDialogVisible(true);
                                          setImageIndexToDelete([
                                            index,
                                            item.id,
                                          ]);
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="upload-success">
                                  <span className="flex-item-align-centered primary-font-color">
                                    {t("sell:successfully-added")}
                                  </span>
                                  <span className="photo-checked">
                                    {" "}
                                    <img src={Checked} alt="" />
                                  </span>
                                </div>
                                {/* </div> */}
                              </div>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
      <div className="dropzone">
        <Dropzone
          accept={acceptedFileTypes}
          multiple={true}
          onDrop={async (files, rejectedFiles) =>
            await handleOnDrop(files, rejectedFiles)
          }
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                {ad_images.length === MAX_NUMBER_OF_PHOTOS ? null : (
                  <input {...getInputProps()} />
                )}
                <Button
                  label={t("sell:add-photo")}
                  className="add-photo-btn"
                  color="white"
                  disabled={ad_images.length === MAX_NUMBER_OF_PHOTOS}
                />
              </div>
            </section>
          )}
        </Dropzone>
        <p>
          {t("sell:you-can-add")}{" "}
          <span className="primary-color">
            {MAX_NUMBER_OF_PHOTOS - ad_images.length}
          </span>{" "}
          {t("sell:more-photos")}
        </p>
      </div>
      <Modal
        className="image-preview-modal"
        open={openModal}
        onClose={closeCropModal}
      >
        <div className="preview-image">
          {cropMode ? (
            <div className="react-crop">
              <Button
                className="close-photo text-very-big primary-color"
                label={t("common:save")}
                disabled={crop.unit === "%"}
                click={submitCroppedImage}
                color="transparent"
              />
              <h3 className="white-color">
                {cropImageIndex === 0
                  ? t("sell:main-photo")
                  : `${t("common:photo")} ${cropImageIndex + 1}`}
              </h3>
              <p className="white-color crop-text">
                {t("sell:crop-instruction")}
              </p>
              <ReactCrop
                qualityArgument={1}
                zoom={1}
                onImageLoaded={onImageLoaded}
                onComplete={onCropComplete}
                onChange={(newCrop) => setCrop(newCrop)}
                src={ad_images[cropImageIndex]?.src}
                crop={crop}
              />
            </div>
          ) : (
            <div className="react-crop">
              <Button
                className="close-photo text-very-big primary-color"
                color="transparent"
                click={() => {
                  setOpenModal(false);
                  setCropMode(false);
                  setCropImageIndex(null);
                }}
              >
                <span>{t("common:close")}</span> <img src={CloseIcon} alt="" />
              </Button>
              <h3 className="white-color">
                {cropImageIndex === 0
                  ? t("sell:main-photo")
                  : `${t("common:photo")} ${cropImageIndex + 1}`}
              </h3>
              <div className="righted">
                <Button
                  color="transparent"
                  label={t("sell:resize-reframe")}
                  className="primary-color text-very-big rezise-button"
                  click={() => setCropMode(true)}
                />
              </div>
              <img
                src={ad_images[cropImageIndex]?.src}
                alt=""
                className="flex-item-align-centered"
              />
            </div>
          )}
        </div>
      </Modal>
      <DialogModal
        data={{
          title: "common:delete",
          message: "common:delete-confirmation",
          buttons: [
            {
              label: "common:cancel",
              color: "white",
              className: "cancel",
              action: hideDialog,
            },
            {
              label: "common:delete",
              color: "red",
              className: "confirm",
              action: deleteImageHandler,
            },
          ],
        }}
        hideDialog={hideDialog}
        openModal={isDialogVisible}
      />
      <DialogModal
        data={{
          title: "sell:info",
          message: "sell:minimum-images",
          buttons: [
            {
              label: "Ok",
              color: "red",
              className: "cancel",
              action: hideError,
            },
          ],
        }}
        hideDialog={hideError}
        openModal={activeError}
      />
    </div>
  );
};

const stateToProps = (state) => {
  const { ad_images } = state.common;
  const { photos } = state.car;
  return {
    ad_images,
    photos,
  };
};

export default connect(stateToProps)(imageUpload);
