import { useSelector } from "react-redux";

const useSelectedValue = () => {
  const findData = useSelector((state) => state.car.find_data);

  /**
   * Find out what is selected element for the select component
   *
   * @param {[{}]} array - array of all elements
   * @param {String} propertyName - property name which can be found in one of element of array
   * @param {String} findDataPropertyName - property name in redux store car -> findData
   * @param {?Number} index - this should be number. It's indicates position of search if we are searching by multiple things
   * @param {?Number} urlValue - optional URL parameter value to use as fallback
   * @return {Number} - Returns selected element. This is entire object from 'array'
   */
  const getSelectedValue = (
    array,
    propertyName,
    findDataPropertyName,
    index,
    urlValue = null
  ) => {
    // Guard against null/undefined array
    if (!array || !Array.isArray(array)) {
      return null;
    }

    const id = [null, undefined].includes(index)
      ? findData[findDataPropertyName]
      : findData[findDataPropertyName]?.[index];

    if (findDataPropertyName === "idModel") {
      const selectedModel = Array.isArray(id) ? id.flat()[0] : id;
      return (
        array.find((item) => item && item[propertyName] === selectedModel) ||
        null
      );
    }

    // For other cases, try findData value first, then fallback to URL value
    const valueToUse = id ?? urlValue ?? -1;
    return (
      array.find((item) => item && item[propertyName] === valueToUse) || null
    );
  };

  return { getSelectedValue };
};

export default useSelectedValue;
