import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/button";
import { Close } from "../../../../../components/svg-icons";
import sec18 from "../../../../../assets/images/help-info-documents/sec18.png";
import sec24 from "../../../../../assets/images/help-info-documents/sec24.png";
import "./index.css";

const HelpInfo = (props) => {
  const { content, onClose } = props;
  const { t } = useTranslation(["common, sell"]);

  let headerText, imageSrc;

  switch (content) {
    case "sec18":
      headerText = "sell:section-18";
      imageSrc = sec18;
      break;
    case "sec24":
      headerText = "sell:section-24";
      imageSrc = sec24;
      break;
    default:
      headerText = "";
      imageSrc = "";
  }

  return (
    <div className="help-modal">
      <div className="header">
        <p>{`${t("sell:helpful-information")} ${t(headerText)}`}</p>
        <div onClick={onClose}>
          <Button
            className="close-button"
            color="white"
            label={t("common:close")}
          />
          <Close color="var(--mdc-theme-border-color)" />
        </div>
      </div>
      <main>
        <img src={imageSrc} alt="Document example" />
      </main>
    </div>
  );
};

export default HelpInfo;
