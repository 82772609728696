import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import {
  setFindProps,
  checkboxChange,
  getCarModel,
  resetCarModel,
  getMotorsForMakeModelFuelBody,
  getBodyColorForLocale,
  getInteriorColorForLocale,
  customSetFindProps,
  removeCarModel,
  getUpholsteryForLocale,
  getConditionForLocale,
  getDriveType,
  setOptionalEquipmentIds,
  setOptionalEuroNorm,
  getEuroNorm,
  getTrailerHitch,
  getCylinders,
  getEquipment,
  findAds4,
  getEngineType,
} from "../../store/actions";
import { emissionClassLessThan, getPerimeterOptions } from "./data";
import { useTranslation } from "react-i18next";
import {
  thousandSeparatorWithPeriod,
  sortByNumeric,
  acceptOnlyNumbers,
  doNotShowLetterE,
  // numberWithCommas,
} from "../../lib/utils";
import _Slider from "../../components/slider/index";
import { TextField } from "@mui/material";
import history from "../../lib/history";
import CheckboxField from "../../components/checkbox-field/index";
import Accordion from "../../components/accordion/index";
import Button from "../../components/button/index";
import FormControl from "../../components/form-control/index";
import FilterSection from "../../components/filter-section";
import PopularBrands from "../../components/popular-brands";
import ColorPicker from "../../components/color-picker";
import Radio from "../../components/radio";
import Marker from "../../components/marker";
import Delete from "../../assets/shapes/delete.svg";
import Edit from "../../assets/shapes/edit.svg";
import "./index.css";
import { getFormattedZipCodePlacesLocationsSwiss } from "../../data/zip-cities-swiss";
import { useSelectedValue, useCustomTranslation } from "../../hooks";

const defaultProps = {
  center: {
    lat: 10.99835602,
    lng: 77.01502627,
  },
  zoom: 11,
};
let circle;

const AdvancedSearch = (props) => {
  const {
    dispatch,
    makeWithAll,
    carModelWithAll,
    find_data,
    // ads_total,
    fuel_type,
    body_type,
    interiorcolor,
    condition,
    // motors,
    upholstery,
    // doors,
    // seats,
    euronorm,
    trailerhitchWithAll,
    transmission,
    bodyTypeCount,
    bodyColors,
    idlocale,
    drive_type,
    optional_equipmentids,
    optional_euro_normids,
    energyEfficienceData,
    cylindersWithAll,
    equipment,
    enginetype,
  } = props;
  const { t, i18n } = useTranslation([
    "common, advancedSearch",
    "adDetails",
    "sell",
    "home",
    "dealerSearch",
  ]);
  const [metallicColor, setMetallicColor] = useState(
    find_data?.metallicColor ?? false
  );
  const [alloyWheels, setAlloyWheels] = useState(
    find_data?.alloyWheels ?? false
  );
  const [nonSmokingVehicle, setNonSmokingVehicle] = useState(
    find_data?.nonSmokingVehicle ?? false
  );
  const [handicappedAccessible, setHandicappedAccessible] = useState(
    find_data?.handicappedAccessible ?? false
  );
  const [damagedVehicle, setDamagedVehicle] = useState(
    find_data?.damagedVehicle ?? false
  );
  const [guarantee, setGuarantee] = useState(find_data?.guarantee ?? false);
  const [particulateFilter, setParticulateFilter] = useState(
    find_data?.particulateFilter ?? false
  );
  const [catalyticConverter, setCatalyticConverter] = useState(
    find_data?.catalyticConverter ?? false
  );
  const [fromMfk, setFromMfk] = useState(find_data?.fromMfk ?? false);
  const [directImport, setDirectImport] = useState(
    find_data?.directImport ?? false
  );
  const language = localStorage.getItem("i18nextLng");
  const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 });
  const [useCurrentPosition, setUseCurrentPosition] = useState(false);
  const [viewMakeModelAndType, setViewMakeModelAndType] = useState([
    { make: "", model: "", variant: "", view: true },
  ]);
  const { getSelectedValue } = useSelectedValue();
  const zipCodePlacesLocationsSwiss = getFormattedZipCodePlacesLocationsSwiss();
  const { translateArray } = useCustomTranslation();
  const perimetarOptions = getPerimeterOptions();

  const apiIsLoaded = (map, maps) => {
    circle = new maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.3,
      map,
      center: currentPosition,
      radius: perimetarOptions.find((item) => item.radius === find_data.radius)
        ?.value,
    });
  };

  const handleSearch = () => {
    history.push(`/${i18n.language}/results/`);
  };

  const handleSliderData = (label, value) => {
    const minMaxValues = {
      price: { min: 0, max: 60000 },
      year: { min: 1940, max: new Date().getFullYear() },
      mileage: { min: 0, max: 200000 },
      numberOfGears: { min: 0, max: 10 },
      power: { min: 0, max: 2000 },
      engineSize: { min: 0, max: 8000 },
      kerbWeight: { min: 0, max: 3500 },
      totalWeight: { min: 0, max: 6000 },
      payload: { min: 0, max: 2000 },
      trailerLoad: { min: 0, max: 3500 },
      doorCount: { min: 0, max: 5 },
      numberOfSeats: { min: 0, max: 10 },
    };
    const min = minMaxValues[label].min;
    const max = minMaxValues[label].max;
    const labels = [label + "From", label + "To"];
    value.forEach((item, index) => {
      const finalValue =
        (index === 0 && item === min) || (index === 1 && item === max)
          ? null
          : item;
      dispatch(setFindProps(labels[index], finalValue));
    });
  };

  const checkboxChangeHandler = (arrayName, id, propName, key, type) => {
    dispatch(checkboxChange(arrayName, id, propName));
    let updatedValue = id;
    if (type === "fuelType") {
      updatedValue = find_data?.idFuelType === id ? null : id;
    } else if (type === "transmission") {
      updatedValue = find_data?.idtransmission === id ? null : id;
    } else if (type === "bodyType") {
      updatedValue = find_data?.idBodyType === id ? null : id;
    }
    dispatch(setFindProps(key, updatedValue));
  };

  // get motors && get seats
  useEffect(() => {
    if (
      find_data.idMake &&
      find_data.idModel &&
      find_data.idFuelType &&
      find_data.idBodyType
    ) {
      dispatch(
        getMotorsForMakeModelFuelBody([
          find_data.idMake[0],
          find_data.idModel[0],
          find_data.idFuelType,
          find_data.idBodyType,
        ])
      );
    }
  }, [
    find_data.idMake,
    find_data.idModel,
    find_data.idFuelType,
    find_data.idBodyType,
  ]);

  useEffect(() => {
    dispatch(getBodyColorForLocale());
    dispatch(getInteriorColorForLocale());
    dispatch(getUpholsteryForLocale());
    dispatch(getConditionForLocale());
    dispatch(getDriveType());
    dispatch(getTrailerHitch());
    dispatch(getEquipment());
    dispatch(findAds4());
    // dispatch(getEngineType()); only EN available
  }, [idlocale]);

  useEffect(() => {
    if (!(find_data?.idMake?.length > 0 && find_data.idMake[0] !== null)) {
      dispatch(getCarModel(0, 0));
      dispatch(customSetFindProps("idMake", [null]));
      dispatch(customSetFindProps("idModel", [null]));
      dispatch(customSetFindProps("equipmentLine", []));
    } else {
      const newState = [];
      find_data.idMake.forEach((_, index) => {
        let make = null;
        let model = null;
        let variants = [];

        if (
          Array.isArray(find_data.idMake) &&
          acceptOnlyNumbers(find_data.idMake[index])
        ) {
          const item = makeWithAll.find(
            (item) => item.idmake === find_data.idMake[index]
          );
          make = item ? item.name : null;
        }
        if (
          Array.isArray(find_data.idModel) &&
          acceptOnlyNumbers(find_data.idModel[index])
        ) {
          const item = (carModelWithAll[index] ?? []).find(
            (item) => item.idmodel === find_data.idModel[index]
          );
          model = item?.name || null;
        } else if (!Array.isArray(find_data.idModel)) {
          dispatch(customSetFindProps("idModel", [null]));
        }
        if (
          Array.isArray(find_data.equipmentLine) &&
          find_data.equipmentLine[index] &&
          Array.isArray(find_data.equipmentLine[index])
        ) {
          variants = find_data.equipmentLine[index];
        }
        newState[index] = { make, model, variants, view: true };
      });
      setViewMakeModelAndType(() => newState);
    }
    dispatch(getInteriorColorForLocale());
    dispatch(getBodyColorForLocale());
    dispatch(getUpholsteryForLocale());
    dispatch(getConditionForLocale());
    dispatch(getDriveType());
    dispatch(getEuroNorm());
    dispatch(getTrailerHitch());
    dispatch(getCylinders());
    dispatch(getEquipment());
    dispatch(getEngineType());
    if (find_data?.latitude && find_data?.longitude) {
      setUseCurrentPosition(true);
      setCurrentPosition({
        lat: find_data.latitude,
        lng: find_data.longitude,
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setOptionalEquipmentIds([]));
      dispatch(setOptionalEuroNorm([]));
      dispatch(setFindProps());
    };
  }, []);

  // Initialize optional_equipmentids with find_data.equipment values
  useEffect(() => {
    if (find_data.equipment && find_data.equipment.length > 0) {
      dispatch(setOptionalEquipmentIds(find_data.equipment));
    }
  }, [find_data.equipment, dispatch]);

  // Initialize optional_euro_normids with find_data.idEuroNorm values
  useEffect(() => {
    if (find_data.idEuroNorm && find_data.idEuroNorm.length > 0) {
      dispatch(setOptionalEuroNorm(find_data.idEuroNorm));
    }
  }, [find_data.idEuroNorm, dispatch]);

  const setFindDataHandler = (key, value, type) => {
    dispatch(setFindProps(key, value === -1 ? null : value, type));
  };

  const handleCityChange = (newValue) => {
    dispatch(setFindProps("latitude", newValue.latitude));
    dispatch(setFindProps("longitude", newValue.longitude));
    dispatch(setFindProps("zipcode", newValue.zipCode));
  };

  const handleRadiusChange = (event, value) => {
    dispatch(setFindProps("radius", value.radius ?? null));
  };

  const damagedVehicleOptions = [
    { name: t("advancedSearch:do-not-show"), value: false },
    { name: t("advancedSearch:show"), value: true },
  ];

  const damagedVehicleCheckboxHandler = (value) => {
    const newValue = value === true;
    setDamagedVehicle(newValue);
    dispatch(setFindProps("damagedVehicle", newValue));
  };

  const checkboxHandler = (key, value) => {
    dispatch(setFindProps(key, value));
    switch (key) {
      case "metallicColor":
        setMetallicColor(value);
        break;
      case "alloyWheels":
        setAlloyWheels(value);
        break;
      case "nonSmokingVehicle":
        setNonSmokingVehicle(value);
        break;
      case "guarantee":
        setGuarantee(value);
        break;
      case "particulateFilter":
        setParticulateFilter(value);
        break;
      case "catalyticConverter":
        setCatalyticConverter(value);
        break;
      case "fromMfk":
        setFromMfk(value);
        break;
      case "directImport":
        setDirectImport(value);
        break;
      case "handicappedAccessible":
        setHandicappedAccessible(value);
        break;
      case "vehiclelocation":
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              setCurrentPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
              setFindDataHandler("place", "");
              setFindDataHandler("zipcode", "");
              setFindDataHandler("latitude", position.coords.latitude);
              setFindDataHandler("longitude", position.coords.longitude);
              setFindDataHandler("radius", perimetarOptions[1].radius);
              setUseCurrentPosition((prevState) => !prevState);
            },
            function (error) {
              console.log(error);
            }
          );
        }
        break;
      default:
        break;
    }
  };

  /**
   *-----------------------------------------------
   * SET EQUIPMENT LIST
   *-----------------------------------------------
   * @param {String} argument
   */
  const handleEquipmentIdSearch = (equipmentId) => {
    const equipmentIdStr = equipmentId.toString();
    const isEquipmentSelected = optional_equipmentids.includes(equipmentIdStr);
    let newOptionalEquipmentList = [...optional_equipmentids];
    if (isEquipmentSelected) {
      newOptionalEquipmentList = newOptionalEquipmentList.filter(
        (id) => id !== equipmentIdStr
      );
    } else {
      newOptionalEquipmentList.push(equipmentIdStr);
    }
    dispatch(setOptionalEquipmentIds(newOptionalEquipmentList));
    dispatch(setFindProps("equipment", newOptionalEquipmentList));
  };

  /**
   *-----------------------------------------------
   * SET EURO NORM LIST
   *-----------------------------------------------
   * @param {String} argument
   */
  const handleEuroNormIdSearch = (ideuronorm) => {
    const isEuroNormSelected = optional_euro_normids.includes(ideuronorm);
    let newOptionalEuroNormList = [...optional_euro_normids];
    if (isEuroNormSelected) {
      newOptionalEuroNormList = newOptionalEuroNormList.filter(
        (id) => id !== ideuronorm
      );
    } else {
      newOptionalEuroNormList.push(ideuronorm);
    }
    dispatch(setOptionalEuroNorm(newOptionalEuroNormList));
    dispatch(setFindProps("idEuroNorm", newOptionalEuroNormList));
  };

  const handleDateChange = (date) => {
    const transformedDate = convertUTCDateToLocalDate(date).toISOString();
    setFindDataHandler("adOnlineSince", transformedDate.split("T")[0]);
  };

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60 * 1000
    );
    return newDate;
  }

  //ReSort transmission data by ID (reason: on lang change checkboxes swap positions)

  const sortedTransmissionByIds = sortByNumeric(
    transmission,
    "asc",
    "idtransmission"
  );

  const addMakeOrModel = (key, value, index, name) => {
    value = value === -1 ? null : value;
    if (key === "idMake") {
      if (value !== null) {
        dispatch(getCarModel(value, index));
      }
      dispatch(resetCarModel(index));
      const newMake = [...find_data.idMake];
      newMake[index] = value;
      const newModel = [...find_data.idModel];
      newModel[index] = null;
      const newVariants = [...find_data.equipmentLine];
      newVariants[index] = null;
      dispatch(customSetFindProps("idMake", newMake));
      dispatch(customSetFindProps("idModel", newModel));
      dispatch(customSetFindProps("equipmentLine", newVariants));
      setViewMakeModelAndType((prevState) => {
        const newState = [...prevState];
        newState[index].make = name;
        newState[index].model = "";
        return newState;
      });
    } else if (key === "idModel") {
      const newModel = [...(find_data.idModel || [])];
      //Ensure it's a 2d array
      newModel[index] = Array.isArray(newModel[index])
        ? [...newModel[index], value]
        : [value];
      dispatch(customSetFindProps("idModel", newModel));
      setViewMakeModelAndType((prevState) => {
        const newState = [...prevState];
        newState[index].model = name;
        return newState;
      });
    } else if (key === "equipmentLine") {
      const newVariants = [...find_data.equipmentLine];
      newVariants[index] = value;
      dispatch(customSetFindProps("equipmentLine", newVariants));
      setViewMakeModelAndType((prevState) => {
        const newState = [...prevState];
        newState[index].equipmentLine = name;
        return newState;
      });
    }
  };

  const addAnotherVehicle = () => {
    dispatch(customSetFindProps("idMake", [...find_data.idMake, null]));
    // Append `[null]` instead of `[]` to maintain correct structure
    const newModelArray = [...find_data.idModel, [null]];
    dispatch(customSetFindProps("idModel", newModelArray));
    dispatch(
      customSetFindProps("equipmentLine", [...find_data.equipmentLine, null])
    );
    setViewMakeModelAndType((prevState) => [
      ...prevState,
      { make: "", model: "", view: true },
    ]);
  };

  const deleteVehicle = (indexPosition) => {
    dispatch(removeCarModel(indexPosition));
    const newMake = find_data.idMake.filter(
      (_, index) => index !== indexPosition
    );
    const newModel = find_data.idModel.filter(
      (_, index) => index !== indexPosition
    );
    const newVariants = find_data.variant
      ? find_data.variant.filter((_, index) => index !== indexPosition)
      : [];
    dispatch(customSetFindProps("idMake", newMake));
    dispatch(customSetFindProps("idModel", newModel));
    dispatch(customSetFindProps("equipmentLine", newVariants));
    setViewMakeModelAndType((prevState) => {
      const newState = [...prevState];
      delete newState[indexPosition];
      return newState.filter((item) => item?.view !== null);
    });
  };

  return (
    <div className="advanced-search">
      <header>
        <h3>{t("common:advanced-search")}</h3>
        <p>{t("advancedSearch:description-for-search")}</p>
      </header>
      <main>
        {find_data?.idMake?.length > 0 &&
          find_data?.idMake.map((_, index) => (
            <section key={index} className="filter-section">
              <div className="filter-section-title d-flex flex-justify-between">
                <h5 className="d-flex flex-align-center">
                  {/* <span className="make-model-circle">{index + 1}</span> */}
                  {(find_data?.idMake && find_data.idMake[index]) ||
                  (find_data?.idModel && find_data.idModel[index])
                    ? `${
                        find_data?.idMake && find_data.idMake[index]
                          ? viewMakeModelAndType[index]?.make
                          : ""
                      } ${
                        Array.isArray(find_data?.idModel) &&
                        find_data?.idModel[index]
                          ? viewMakeModelAndType[index]?.model
                          : ""
                      } ${
                        Array.isArray(find_data?.equipmentLine) &&
                        find_data.equipmentLine[index]
                          ? viewMakeModelAndType[index]?.equipmentLine
                          : ""
                      }`
                    : t("advancedSearch:select-make-model-type")}
                </h5>
                <div className="actions">
                  <span>
                    <img
                      onClick={() =>
                        setViewMakeModelAndType((prevState) => {
                          const newState = [...prevState];
                          newState[index].view = !newState[index]?.view;
                          return [...newState];
                        })
                      }
                      src={Edit}
                      alt="edit"
                    />
                  </span>
                  {find_data?.idMake && find_data.idMake.length !== 1 && (
                    <span>
                      <img
                        onClick={() => deleteVehicle(index)}
                        src={Delete}
                        alt="delete"
                      />
                    </span>
                  )}
                </div>
              </div>
              <div className="make-model-variant-controls">
                {viewMakeModelAndType[index]?.view && (
                  <>
                    <div className="make-model-popular-brands-container">
                      <div className="make-model-inputs">
                        <FormControl
                          inputType="select"
                          handleChange={(_, newValue) => {
                            addMakeOrModel(
                              "idMake",
                              newValue ? newValue.idmake : null,
                              index,
                              newValue ? newValue.name : null
                            );
                          }}
                          data={makeWithAll}
                          value={getSelectedValue(
                            makeWithAll,
                            "idmake",
                            "idMake",
                            index
                          )}
                          placeholder={t("advancedSearch:car-brand-adv-s")}
                        />
                        <FormControl
                          inputType="select"
                          handleChange={(_, newValue) =>
                            addMakeOrModel(
                              "idModel",
                              newValue ? newValue.idmodel : null,
                              index,
                              newValue ? newValue.name : null
                            )
                          }
                          data={
                            Array.isArray(carModelWithAll[index])
                              ? carModelWithAll[index]
                              : []
                          }
                          value={getSelectedValue(
                            Array.isArray(carModelWithAll[index])
                              ? carModelWithAll[index]
                              : [],
                            "idmodel",
                            "idModel",
                            index
                          )}
                          placeholder={t("home:model")}
                        />
                      </div>
                      <PopularBrands
                        modelIndex={index}
                        message={t("advancedSearch:or-click")}
                        handler={addMakeOrModel}
                      />
                    </div>
                    <FormControl
                      name="variant"
                      type="text"
                      placeholder={t("sell:variant-placeholder")}
                      inputType="textfield"
                      label={t("common:variant")}
                      value={find_data?.equipmentLine[index] || ""}
                      handleChange={(event) =>
                        addMakeOrModel(
                          "equipmentLine",
                          event.target.value,
                          index,
                          event.target.value
                        )
                      }
                    />
                  </>
                )}
              </div>
              {index === find_data.idMake.length - 1 && (
                <Button
                  color="transparent"
                  label={`+ ${t("advancedSearch:add-another-vehicle")}`}
                  className="addanothervehicle"
                  click={addAnotherVehicle}
                />
              )}
            </section>
          ))}
        <FilterSection title={t("advancedSearch:select-type-of-the-vehicle")}>
          <div className="vehicle-types">
            {body_type.length > 0 &&
              body_type.map((item, index) => {
                if (item.Component) {
                  return (
                    <div
                      key={index}
                      onClick={() =>
                        checkboxChangeHandler(
                          "body_type",
                          item.idbodytype,
                          "idbodytype",
                          "idBodyType",
                          "bodyType"
                        )
                      }
                      className={`vehicle-type ${
                        item.checked ? "vehicle-type-checked" : ""
                      }`}
                    >
                      <item.Component />
                      <span>
                        <p>
                          {item.name[language ? language.toUpperCase() : "EN"]}
                        </p>
                        <p>
                          (
                          {thousandSeparatorWithPeriod(
                            bodyTypeCount.find(
                              (subItem) =>
                                subItem.idbodytype === item.idbodytype
                            )?.vehiclescount ?? 0
                          )}
                          )
                        </p>
                      </span>
                    </div>
                  );
                }
              })}
          </div>
        </FilterSection>
        <FilterSection
          title={null}
          className="filter-flex-wrap sliders"
          id="root-sliders-section"
        >
          <_Slider
            value1={find_data.yearFrom}
            value2={find_data.yearTo}
            min={1940}
            max={new Date().getFullYear()}
            handleChange={handleSliderData}
            label="year"
            type={2}
            title={t("common:first-registration")}
            placeholder1={t("common:any")}
            placeholder2={t("common:any")}
          />
          <_Slider
            value1={find_data.mileageFrom}
            value2={find_data.mileageTo}
            min={0}
            max={200000}
            handleChange={handleSliderData}
            label="mileage"
            type={2}
            title={t("common:mileage")}
            placeholder1={t("common:any")}
            placeholder2={t("common:any")}
          />
          <_Slider
            value1={find_data.priceFrom}
            value2={find_data.priceTo}
            min={0}
            max={60000}
            handleChange={handleSliderData}
            label="price"
            type={2}
            title={t("common:price")}
            placeholder1={t("common:any")}
            placeholder2={t("common:any")}
          />
        </FilterSection>
        <FilterSection
          title={t("advancedSearch:condition-warranty")}
          className="filter-flex-wrap"
        >
          <FormControl
            inputType="select"
            label={t("common:condition")}
            data={condition}
            value={condition.find(
              (item) => item.idcondition === find_data.idcondition
            )}
            handleChange={(event, newValue) =>
              setFindDataHandler("idcondition", newValue?.idcondition ?? "")
            }
          />
          <FormControl
            inputType="checkbox"
            label={`${t("common:from")} MFK`}
            handleChange={() => checkboxHandler("fromMfk", !fromMfk)}
            value={fromMfk}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:direct-parallel-import")}
            handleChange={() => checkboxHandler("directImport", !directImport)}
            value={directImport}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:guarantee")}
            handleChange={() => checkboxHandler("guarantee", !guarantee)}
            value={guarantee}
          />
          <FormControl
            inputType="select"
            label={t("common:damaged-vehicle")}
            data={damagedVehicleOptions}
            value={damagedVehicleOptions.find(
              (item) => item.value === damagedVehicle
            )}
            handleChange={(event, newValue) =>
              damagedVehicleCheckboxHandler(newValue.value)
            }
          />
          <FormControl
            inputType="checkbox"
            label={t("common:non-smoking-vehicle")}
            handleChange={() =>
              checkboxHandler("nonSmokingVehicle", !nonSmokingVehicle)
            }
            value={nonSmokingVehicle}
          />
          <FormControl
            inputType="checkbox"
            label={t("common:handicapped-accessible")}
            handleChange={() =>
              checkboxHandler("handicappedAccessible", !handicappedAccessible)
            }
            value={handicappedAccessible}
          />
        </FilterSection>
        <Accordion
          title={t("common:vehicle-details")}
          className="filter-flex-wrap mainvehicledata"
        >
          <FilterSection title={t("common:fuel")} className="fueltype-filter">
            {fuel_type.map((item, index) => {
              if (item.name.EN) {
                return (
                  <CheckboxField
                    className={"fuel-check"}
                    checked={find_data?.idFuelType === item.idfueltype}
                    changeHandler={() =>
                      checkboxChangeHandler(
                        "fuel_type",
                        item.idfueltype,
                        "idfueltype",
                        "idFuelType",
                        "fuelType"
                      )
                    }
                    label={item.name[language ? language.toUpperCase() : "EN"]}
                    key={index}
                  />
                );
              } else {
                return null;
              }
            })}
          </FilterSection>
          <FilterSection
            title={t("common:transmission")}
            className="fueltype-filter"
          >
            {sortedTransmissionByIds.map((item, index) => {
              if (item.name) {
                return (
                  <CheckboxField
                    className={"fuel-check"}
                    checked={
                      find_data?.idTransmission === item.idtransmission
                        ? true
                        : false
                    }
                    changeHandler={() =>
                      checkboxChangeHandler(
                        "transmission",
                        item.idtransmission,
                        "idtransmission",
                        "idTransmission",
                        "transmission"
                      )
                    }
                    label={item.name}
                    key={index}
                  />
                );
              } else {
                return null;
              }
            })}
          </FilterSection>
          <FilterSection title={null} className="filter-flex-wrap sliders">
            <_Slider
              value1={find_data.numberOfGearsFrom}
              value2={find_data.numberOfGearsTo}
              min={0}
              max={10}
              handleChange={handleSliderData}
              label="numberOfGears"
              type={2}
              title={t("common:number-of-gears")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
            <FormControl
              inputType="select"
              label={t("common:drivetrain")}
              data={drive_type}
              value={drive_type.find(
                (item) => item.iddrivetype === find_data.idDriveType
              )}
              handleChange={(event, newValue) =>
                setFindDataHandler("idDriveType", newValue?.iddrivetype ?? "")
              }
            />
            <_Slider
              value1={find_data.powerFrom}
              value2={find_data.powerTo}
              min={0}
              max={2000}
              handleChange={handleSliderData}
              label="power"
              type={2}
              title={t("common:power")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            >
              {/* <label className="switch-power">
                <input type="checkbox" />
                <span className="switch-power-slider"></span>
              </label> */}
            </_Slider>
          </FilterSection>
          <FilterSection
            title={t("advancedSearch:engine")}
            className="filter-flex-wrap sliders"
          >
            <_Slider
              value1={find_data.engineSizeFrom}
              value2={find_data.engineSizeTo}
              min={0}
              max={8000}
              handleChange={handleSliderData}
              label="engineSize"
              type={2}
              title={t("common:engine-size")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
            <FormControl
              inputType="select"
              label={t("common:cylinder")}
              data={cylindersWithAll}
              value={getSelectedValue(
                cylindersWithAll,
                "idcylinder",
                "cylinder"
              )}
              handleChange={(event, newValue) =>
                setFindDataHandler("cylinder", newValue?.idcylinder ?? "")
              }
            />
            <FormControl
              inputType="select"
              label={t("common:engine-type")}
              data={enginetype}
              value={enginetype.find(
                (item) => item.idenginetype === find_data.engineType
              )}
              handleChange={(event, newValue) =>
                setFindDataHandler("engineType", newValue?.idenginetype ?? "")
              }
              disabled={true}
            />
            <_Slider
              value1={find_data.kerbWeightFrom}
              value2={find_data.kerbWeightTo}
              min={0}
              max={3500}
              handleChange={handleSliderData}
              label="kerbWeight"
              type={2}
              title={t("common:kerb-weight")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
            <_Slider
              value1={find_data.totalWeightFrom}
              value2={find_data.totalWeightTo}
              min={0}
              max={6000}
              handleChange={handleSliderData}
              label="totalWeight"
              type={2}
              title={t("common:total-weight")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
            <_Slider
              value1={find_data.payloadFrom}
              value2={find_data.payloadTo}
              min={0}
              max={2000}
              handleChange={handleSliderData}
              label="payload"
              type={2}
              title={t("common:payload")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
            <_Slider
              value1={find_data.trailerLoadFrom}
              value2={find_data.trailerLoadTo}
              min={0}
              max={3500}
              handleChange={handleSliderData}
              label="trailerLoad"
              type={2}
              title={t("common:trailer-load")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
          </FilterSection>
          <FilterSection className="filter-flex-wrap">
            <FormControl label={t("common:body-color")}>
              <ColorPicker
                id={"idbodycolor"}
                colors={bodyColors}
                label="bodyColor"
                value={find_data.idbodycolor ?? ""}
                changeHandler={(event, newValue) =>
                  setFindDataHandler(
                    "idbodycolor",
                    newValue?.idbodycolor ? newValue.idbodycolor : null,
                    "array"
                  )
                }
              />
            </FormControl>
            <FormControl label={t("common:interior-color")}>
              <ColorPicker
                id={"idinteriorcolor"}
                label="interiorColor"
                colors={interiorcolor}
                value={find_data.idinteriorcolor ?? ""}
                changeHandler={(event, newValue) =>
                  setFindDataHandler(
                    "idinteriorcolor",
                    newValue?.idinteriorcolor ? newValue.idinteriorcolor : null,
                    "array"
                  )
                }
              />
            </FormControl>
            <FormControl
              inputType="select"
              label={t("common:upholstery")}
              data={upholstery}
              value={upholstery.find(
                (item) => item.idupholstery === find_data.idupholstery
              )}
              handleChange={(event, newValue) =>
                setFindDataHandler("idupholstery", newValue?.idupholstery ?? "")
              }
            />
            <FormControl
              inputType="select"
              label={t("advancedSearch:trailer-hitch")}
              data={trailerhitchWithAll}
              value={getSelectedValue(
                trailerhitchWithAll,
                "idtrailerhitch",
                "idtrailerhitch"
              )}
              handleChange={(event, newValue) =>
                setFindDataHandler(
                  "idtrailerhitch",
                  newValue?.idtrailerhitch ?? ""
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("advancedSearch:metallic")}
              handleChange={() =>
                checkboxHandler("metallicColor", !metallicColor)
              }
              value={metallicColor}
            />
            <FormControl
              inputType="checkbox"
              label={t("advancedSearch:alloy-wheels")}
              handleChange={() => checkboxHandler("alloyWheels", !alloyWheels)}
              value={alloyWheels}
            />
          </FilterSection>
          <FilterSection title={null} className="sliders">
            <_Slider
              value1={find_data.doorCountFrom}
              value2={find_data.doorCountTo}
              min={0}
              max={5}
              handleChange={handleSliderData}
              label="doorCount"
              type={2}
              title={t("common:door-count")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
            <_Slider
              value1={find_data.numberOfSeatsFrom}
              value2={find_data.numberOfSeatsTo}
              min={0}
              max={10}
              handleChange={handleSliderData}
              label="numberOfSeats"
              type={2}
              title={t("common:number-of-seats")}
              placeholder1={t("common:any")}
              placeholder2={t("common:any")}
            />
          </FilterSection>
        </Accordion>
        <Accordion title={t("sell:equipment")} className="filter-flex-wrap">
          <div className="equipment-list">
            {translateArray(equipment)?.map((item) => (
              <FormControl
                inputType="checkbox"
                key={item.id}
                label={item.name}
                value={optional_equipmentids.includes(item.id.toString())}
                handleChange={() => {
                  handleEquipmentIdSearch(item.id);
                }}
              />
            ))}
          </div>
        </Accordion>
        <Accordion
          title={t("advancedSearch:ecological-criteria")}
          className="filter-flex-wrap ecological-criteria"
        >
          <FilterSection
            title={t("adDetails:fuel-consumption")}
            className="filter-flex-wrap fuel-consumption"
          >
            <FormControl
              label={t("sell:fuel-consumption-comb")}
              className="input-with-abbreviation"
            >
              <TextField
                type="number"
                variant="outlined"
                placeholder={t("advancedSearch:up-to")}
                // onKeyDown={doNotShowLetterE}
                value={find_data?.fuelConsumptionCombinedTo ?? ""}
                onChange={(event) =>
                  setFindDataHandler(
                    "fuelConsumptionCombinedTo",
                    parseFloat(event.target.value)
                  )
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">l/100km</span>,
                }}
              />
            </FormControl>
            <FormControl
              label={t("sell:fuel-consumption-urban")}
              className="input-with-abbreviation"
            >
              <TextField
                type="number"
                variant="outlined"
                placeholder={t("advancedSearch:up-to")}
                value={find_data?.fuelConsumptionUrbanTo ?? ""}
                onKeyDown={doNotShowLetterE}
                onChange={(event) =>
                  setFindDataHandler(
                    "fuelConsumptionUrbanTo",
                    parseFloat(event.target.value)
                  )
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">l/100km</span>,
                }}
              />
            </FormControl>
            <FormControl
              label={t("sell:fuel-cons-extra-urban")}
              className="input-with-abbreviation"
            >
              <TextField
                placeholder={t("advancedSearch:up-to")}
                type="number"
                variant="outlined"
                onKeyDown={doNotShowLetterE}
                value={find_data?.fuelConsumptionExtraUrbanTo ?? ""}
                onChange={(event) =>
                  setFindDataHandler(
                    "fuelConsumptionExtraUrbanTo",
                    parseFloat(event.target.value)
                  )
                }
                InputProps={{
                  endAdornment: <span className="abbreviation">l/100km</span>,
                }}
              />
            </FormControl>
          </FilterSection>
          <FilterSection className="emission">
            <FormControl
              inputType="select"
              label={t("sell:co2-emission")}
              placeholder={t("advancedSearch:less-then")}
              data={emissionClassLessThan}
              value={emissionClassLessThan.find(
                (item) => item.value === find_data?.co2emissionLessThan
              )}
              handleChange={(event, newValue) =>
                setFindDataHandler(
                  "co2emissionLessThan",
                  newValue ? newValue.value : null
                )
              }
            />
            <FormControl
              inputType="select"
              label={t("adDetails:energy-efficiency-class")}
              data={energyEfficienceData}
              value={energyEfficienceData.find(
                (item) => item.value === find_data?.energyEfficiencyClass
              )}
              handleChange={(event, newValue) =>
                setFindDataHandler(
                  "energyEfficiencyClass",
                  newValue ? newValue.value : null
                )
              }
            />
            <FormControl
              inputType="checkbox"
              label={t("advancedSearch:particulate-filter")}
              handleChange={() =>
                checkboxHandler("particulateFilter", !particulateFilter)
              }
              value={particulateFilter}
            />
            <FormControl
              inputType="checkbox"
              label={t("advancedSearch:catalytic-converter")}
              handleChange={() =>
                checkboxHandler("catalyticConverter", !catalyticConverter)
              }
              value={catalyticConverter}
            />
          </FilterSection>
          <FilterSection
            title={t("adDetails:euro-norm")}
            className="euronorm-section"
          >
            {euronorm.map((item) => (
              <FormControl
                inputType="checkbox"
                key={item.ideuronorm}
                label={item.name}
                value={optional_euro_normids.includes(item.ideuronorm)}
                handleChange={() => {
                  handleEuroNormIdSearch(item.ideuronorm);
                }}
              />
            ))}
          </FilterSection>
        </Accordion>
        <Accordion title={t("common:other-details")} className="other-details">
          <FilterSection
            title={t("advancedSearch:vehicle-location-radius")}
            className="filter-location-section"
          >
            <Radio
              id="location-distance"
              className="radio-button-location-distance"
              name="location"
              label={t("advancedSearch:current-location-distance")}
              labelClass="filter-location-label"
              changeHandler={() => checkboxHandler("vehiclelocation")}
              checked={useCurrentPosition}
            />
            {useCurrentPosition && (
              <>
                <div className="google-map">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: process.env.GOOGLE_API_KEY }}
                    defaultCenter={currentPosition}
                    defaultZoom={defaultProps.zoom}
                    zoomControl={false}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) =>
                      apiIsLoaded(map, maps)
                    }
                  >
                    <Marker
                      lat={currentPosition.lat}
                      lng={currentPosition.lng}
                    />
                  </GoogleMapReact>
                </div>
                <FormControl
                  label={t("advancedSearch:perimetar")}
                  inputType="select"
                  placeholder={t("common:all")}
                  data={perimetarOptions}
                  className={"mb-1"}
                  value={perimetarOptions.find(
                    (item) => item.radius === find_data.radius
                  )}
                  handleChange={(_, newValue) => {
                    setFindDataHandler("radius", newValue.radius);
                    circle.setRadius(Number(newValue.value));
                  }}
                ></FormControl>
              </>
            )}
            {!useCurrentPosition && (
              <div className="manual-location contact-form">
                <FormControl
                  label={t("dealerSearch:zip-place")}
                  inputType="select"
                  placeholder=""
                  data={zipCodePlacesLocationsSwiss}
                  value={getSelectedValue(
                    zipCodePlacesLocationsSwiss,
                    "zipCode",
                    "zipcode"
                  )}
                  handleChange={(_, newValue) => handleCityChange(newValue)}
                />

                <FormControl
                  label={t("advancedSearch:perimetar")}
                  inputType="select"
                  placeholder={t("common:all")}
                  data={perimetarOptions}
                  value={perimetarOptions.find(
                    (item) => item.radius === find_data.radius
                  )}
                  handleChange={handleRadiusChange}
                />

                {/* <FormControl
                value={find_data?.place ? find_data.place : ""}
                inputType="textfield"
                label={t("profile:place")}
                type="text"
                handleChange={(event) =>
                  setFindDataHandler("place", event.target.value)
                }
                disabled={useCurrentPosition}
              /> */}
              </div>
            )}
          </FilterSection>
          <FilterSection className="ad-filter-section">
            <FormControl
              inputType="datepicker"
              label={t("common:ad-online-since")}
              value={find_data?.adOnlineSince ? find_data.adOnlineSince : null}
              handleChange={(date) => handleDateChange(date._d)}
            />
            {/* <FormControl
              label="Ad-ID/Nr."
              placeholder={t("common:type")}
              type="textfield"
              handleChange={(event) =>
                setFindDataHandler("adIdNumber", parseFloat(event.target.value))
              }
            /> */}
          </FilterSection>
        </Accordion>
        <Button
          label={
            t("common:search")
            // +
            // " " +
            // `(${ads_total ? numberWithCommas(ads_total) : "0"})`
          }
          className="button"
          click={handleSearch}
          color="red"
        />
      </main>
    </div>
  );
};

const stateToProps = (state) => {
  const {
    makeWithAll,
    carModelWithAll,
    find_data,
    ads_total,
    fuel_type,
    body_type,
    interiorcolor,
    condition,
    motors,
    upholstery,
    doors,
    seats,
    euronorm,
    trailerhitchWithAll,
    transmission,
    bodyTypeCount,
    bodyColors,
    idlocale,
    drive_type,
    optional_equipmentids,
    optional_euro_normids,
    energyEfficience,
    cylindersWithAll,
    equipment,
    enginetype,
  } = state.car;

  const energyEfficienceData = energyEfficience.map((item) => ({
    name: item.name,
    value: item.name,
  }));

  return {
    makeWithAll,
    carModelWithAll,
    find_data,
    ads_total,
    fuel_type,
    body_type,
    interiorcolor,
    condition,
    motors,
    upholstery,
    doors,
    seats,
    euronorm,
    trailerhitchWithAll,
    transmission,
    bodyTypeCount,
    bodyColors,
    idlocale,
    drive_type,
    optional_equipmentids,
    optional_euro_normids,
    energyEfficienceData,
    cylindersWithAll,
    equipment,
    enginetype,
  };
};

export default connect(stateToProps)(AdvancedSearch);
