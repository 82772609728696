import axios from "axios";
import { toast } from "react-toastify";

export const handleShareDeepLinking = async (idAd, t) => {
  if (!idAd) {
    console.error("Invalid Ad ID", idAd);
    toast.error(t("common:something-went-wrong"));
    return;
  }

  const endpoint = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.FIREBASE_API_KEY}`;
  const domainUriPrefix = "https://carindex.page.link";
  const link = `https://www.carindex.ch/?share=${idAd}`;
  const packageName = "com.carindex.user";

  try {
    const response = await axios.post(endpoint, {
      dynamicLinkInfo: {
        domainUriPrefix,
        link,
        androidInfo: {
          androidPackageName: packageName,
        },
        iosInfo: {
          iosBundleId: packageName,
        },
      },
    });
    navigator.clipboard.writeText(response?.data?.shortLink ?? "");
    toast.success(t("common:successfully-copied"));
  } catch (error) {
    console.error(error);
    toast.error(t("common:something-went-wrong"));
  }
};
