import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import Image from "../../../components/image";
import { PhotoPlaceholder } from "../../../components/svg-icons/index.js";
import Button from "../../../components/button";
import { numberWithCommas, getMainImage } from "../../../lib/utils.js";
import "./index.css";

const CarsOfDay = () => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const cars = useSelector((state) => {
    return state.car.carsOfTheDay;
  });

  const previewAdHandler = (idad) => {
    history.push({
      pathname: `/${i18n.language}/ad/${idad}`,
    });
  };

  if (!cars || cars.every((car) => car === null)) {
    return (
      <div className="no-cars">
        <h2>No cars of the day available</h2>
      </div>
    );
  }

  return (
    <div className="cars-of-day">
      {cars?.map((item, index) => {
        const { idad, price, photos, logo } = item || {};
        const mainImage = getMainImage(photos);
        const seller = logo[index] ?? null;
        return (
          <div className="ad-thumb" key={index}>
            <span className="seller-logo">{seller && <img src={logo} />}</span>
            <a
              href={`/${i18n.language}/ad/${idad}`}
              onClick={(e) => {
                e.preventDefault();
                previewAdHandler(idad);
              }}
            >
              <span className="ad-img">
                <section>
                  <div>
                    {photos ? (
                      <Image src={mainImage} className="cover" />
                    ) : (
                      <PhotoPlaceholder />
                    )}
                  </div>
                  <Button
                    label={`CHF ${numberWithCommas(price)}.-`}
                    color="red"
                    click={() => previewAdHandler(idad)}
                  />
                </section>
              </span>
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default CarsOfDay;
