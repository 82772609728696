import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDown } from "../../../components/svg-icons/index";

import "./index.css";
import { getInsertionRulesaccordionData } from "../data/data";

const InsertionRulesaccordionData = getInsertionRulesaccordionData();

const InsertionRules = () => {
  const { t } = useTranslation(["footer"]);

  return (
    <div className="insertion-rules">
      <header>
        <h3>{t("footer:insertion-rules-header")}</h3>
      </header>
      <main>
        <p>{t("footer:insertion-rules-header-p")}</p>
        {InsertionRulesaccordionData.map((section) => (
          <Accordion key={section.id}>
            <AccordionSummary
              expandIcon={<ArrowDown />}
              aria-controls={`section${section.id}`}
              id={`section${section.id}`}
            >
              <h4 className="subtitle">{t(`footer:${section.subtitle}`)}</h4>
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <label>
                  {Array.isArray(section.label) ? (
                    <div>
                      {section.label.map((item, index) => (
                        <div key={index}>
                          {item.bulleted ? (
                            <p style={{ paddingLeft: "20px" }}>
                              {"\u2022 "}
                              {t(`footer:${item.text}`)}
                            </p>
                          ) : item.emptyRow ? (
                            <div>
                              <br />
                            </div>
                          ) : (
                            <p>{t(`footer:${item.text}`)}</p>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>{t(`footer:${section.label}`)}</p>
                  )}
                </label>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </main>
    </div>
  );
};

export default InsertionRules;
