import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCarsOfTheDay } from "../store/actions";

const useCarsOfTheDay = () => {
  const dispatch = useDispatch();
  const carsOfTheDay = useSelector((state) => {
    return state.car.carsOfTheDay ?? [];
  });

  useEffect(() => {
    dispatch(getCarsOfTheDay());
  }, []);
};

export default useCarsOfTheDay;
