import { Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import Sort from "../sort";
import "./index.css";

const Header = (props) => {
  const {
    title,
    titleClass,
    sortLabel,
    openSort,
    anchorEl,
    handleSort,
    dropdownData,
    children,
  } = props;
  const { t } = useTranslation(["common"]);
  const menuItems = dropdownData?.map((item, index) => (
    <MenuItem key={index} onClick={() => handleSort(index)}>
      {t(item)}
    </MenuItem>
  ));
  return (
    <div className="results-header">
      {title ? (
        <p className={`${titleClass ? titleClass : ""}`}>{title}</p>
      ) : (
        children
      )}
      <Sort label={t(sortLabel)} handler={openSort} />
      <Menu
        className="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleSort(null)}
      >
        {menuItems}
      </Menu>
    </div>
  );
};

export default Header;
