import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./index.css";
import Loader from "../loader/index";

export default ({
  loading,
  children,
  transparent,
  loadingMessage,
  className,
}) => {
  return (
    <>
      {loading ? (
        <div
          className={
            "simple-loader" +
            (transparent ? " transparent-loader" : "") +
            (className ? " " + className : "")
          }
        >
          <div className="flex-row-wrapper flex-justify-center flex-align-center gap">
            <CircularProgress size={50} thickness={3} color="secondary" />
            {loadingMessage ? (
              <div className="simple-loader-message primary-font-color">
                {loadingMessage}
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};
