import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import history from "../../lib/history";
import { Tooltip } from "react-tooltip";
import GoogleMapReact from "google-map-react";
import { acceptOnlyNumbers } from "../../lib/utils";
import Api from "../../lib/api";
import { defaultLocation, getRegionsSwiss } from "../../data";
import { TYPES_OF_MERCHANT } from "../../types";
import { Close } from "../svg-icons";
import Button from "../button";
import FormControl from "../form-control";
import Radio from "../radio";
import FindOnMap from "../../assets/shapes/find-on-map-svgrepo-com.svg";
// import { useProfileDataLocation } from "../../hooks";
import "./index.css";

const regionSwiss = getRegionsSwiss();

const merchantForm = (props) => {
  const {
    profileData,
    phoneTypes,
    inputChangeHandler,
    addPhoneNumber,
    removePhoneNumber,
    errorMessages,
    setAddressData,
    findOnMap,
    changeMap,
    updatedProfileData,
  } = props;
  const { t, i18n } = useTranslation(["common", "profile", "footer"]);
  // const { loadMap, getCoordinates, addMarker } = useProfileDataLocation();

  useEffect(() => {
    // console.log("CHANGE", updatedProfileData);
    if (!updatedProfileData) {
      return;
    }
    // console.log("ADD MARKER");
    addMarker(
      updatedProfileData?.location?.latitude,
      updatedProfileData?.location?.longitude
    );
  }, [profileData]);
  const loadMap = async (map, maps) => {
    const p = await window.google.maps.importLibrary("marker");

    const loadAdvancedMarkerElementLibrary = async () => {
      await maps.importLibrary("marker");

      const markerOptions = {
        position: {
          lat: profileData?.location?.latitude
            ? profileData?.location?.latitude
            : defaultLocation.lat,
          lng: profileData?.location?.longitude
            ? profileData?.location?.longitude
            : defaultLocation.lng,
        },
        map,
        gmpDraggable: true,
        zIndex: 10000,
      };
      const marker = new maps.marker.AdvancedMarkerElement(markerOptions);

      marker.addListener("onClick", () => {
        console.log("CLICK");
      });

      marker.addListener("dragend", async () => {
        const data = {
          address_data: {},
        };
        const lat = marker.position.lat;
        const lng = marker.position.lng;
        data.location = {
          latitude: lat,
          longitude: lng,
        };
        try {
          const geocodeResponse = await Api.reverseGeocoding(lat, lng);
          geocodeResponse.results[0].address_components.forEach((item) => {
            if (item.types.includes("street_number")) {
              data.address_data.streetandnumber = item.long_name;
            }
            if (item.types.includes("route")) {
              data.address_data.streetandnumber =
                `${
                  data?.address_data?.streetandnumber
                    ? data.address_data.streetandnumber + " "
                    : ""
                }` + item.long_name;
            }
            if (item.types.includes("locality")) {
              data.city = item.long_name;
            }
            if (item.types.includes("country")) {
              data.country = item.long_name;
            }
            if (item.types.includes("postal_code")) {
              data.address_data.zipcode = item.long_name;
            }
          });
          setAddressData(data);
        } catch (err) {
          return null;
        }
      });
    };

    loadAdvancedMarkerElementLibrary();
  };

  const goToContactUsPage = () => {
    history.push(`/${i18n.language}/contact-us/`);
  };

  const handleRadioButton = (e) => {
    const value = parseInt(e.target.value);
    const name = e.target.name;
    inputChangeHandler(name, value, "type");
  };

  return (
    <div className="contact-form">
      <FormControl className={"d-flex"}>
        <Radio
          name={"type"}
          value={TYPES_OF_MERCHANT.MR}
          checked={profileData.type === TYPES_OF_MERCHANT.MR}
          className={"radio-button-target"}
          label={t("profile:mr")}
          changeHandler={handleRadioButton}
        />
        <Radio
          name={"type"}
          value={TYPES_OF_MERCHANT.MS}
          className={"radio-button-target"}
          label={t("profile:ms")}
          checked={profileData.type === TYPES_OF_MERCHANT.MS}
          changeHandler={handleRadioButton}
        />
        <Radio
          name={"type"}
          value={TYPES_OF_MERCHANT.COMPANY}
          className={"radio-button-target"}
          label={t("footer:company")}
          checked={profileData.type === TYPES_OF_MERCHANT.COMPANY}
          changeHandler={handleRadioButton}
        />
      </FormControl>
      <FormControl
        inputType="textfield"
        name="first_name"
        label={t("profile:first-name")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData?.first_name}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="last_name"
        label={t("profile:last-name")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData?.last_name}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <FormControl
        inputType="textfield"
        name="company"
        id="company-scroll-target"
        errorMessage={t(
          `${errorMessages?.company ? errorMessages?.company : ""}`
        )}
        errorMessageClassName={"color-red"}
        label={t("profile:company-aplicable")}
        type="text"
        placeholder={t("common:type")}
        value={profileData?.company}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
        disabled={
          profileData.type === TYPES_OF_MERCHANT.MR ||
          profileData.type === TYPES_OF_MERCHANT.MS
        }
      />
      <FormControl
        inputType="textfield"
        name="additional"
        label={t("profile:additional")}
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData?.additional}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <div className="map-wrapper">
        <p style={{ marginBottom: "15px" }}>{t("profile:drag-pin")}</p>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.GOOGLE_API_KEY,
            libraries: ["marker"],
          }}
          defaultCenter={{
            lat: profileData?.location?.latitude
              ? profileData?.location?.latitude
              : defaultLocation.lat,
            lng: profileData?.location?.longitude
              ? profileData?.location?.longitude
              : defaultLocation.lng,
          }}
          defaultZoom={10}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => loadMap(map, maps)}
          key={changeMap}
          options={{
            mapId: process.env.GOOGLE_MAP_ID,
          }}
        />
      </div>
      <div className="find-on-map-wrapper">
        <FormControl
          inputType="textfield"
          label={t("profile:streetandnumber")}
          name="streetandnumber"
          type="text"
          placeholder={t("common:type")}
          value={
            profileData?.address_data?.streetandnumber
              ? profileData.address_data.streetandnumber
              : ""
          }
          handleChange={(e) =>
            inputChangeHandler(e.target.name, e.target.value, "address")
          }
        />
        <img
          className="find-on-map"
          alt="find on map"
          src={FindOnMap}
          onClick={findOnMap}
        />
      </div>
      <div className="joined-inputs-1-2">
        <FormControl
          inputType="textfield"
          label={t("profile:zip-code")}
          name="zipcode"
          type="text"
          placeholder={t("common:type")}
          value={
            profileData?.address_data?.zipcode
              ? profileData.address_data.zipcode
              : ""
          }
          handleChange={(e) => {
            if (acceptOnlyNumbers(e.target.value)) {
              inputChangeHandler(e.target.name, e.target.value, "address");
            }
          }}
        />
        <FormControl
          inputType="textfield"
          label={t("profile:place")}
          name="city"
          type="text"
          id="city-scroll-target"
          errorMessage={t(`${errorMessages?.city ? errorMessages?.city : ""}`)}
          errorMessageClassName={"color-red"}
          placeholder={t("common:type")}
          value={profileData?.city ? profileData.city : ""}
          handleChange={(e) =>
            inputChangeHandler(e.target.name, e.target.value)
          }
        />
      </div>
      <div className="joined-inputs-1-2">
        <FormControl
          inputType="select"
          label={t("profile:region")}
          name="region"
          placeholder={t("common:type")}
          data={regionSwiss}
          value={
            profileData.country === "Switzerland"
              ? regionSwiss.find(
                  (region) => profileData.address_data.region === region.name
                )
              : ""
          }
          disabled={profileData.country === "Switzerland" ? false : true}
          handleChange={(e, newValue) =>
            inputChangeHandler(
              "region",
              newValue ? newValue.name : "",
              "address"
            )
          }
        />
        <FormControl
          inputType="textfield"
          label={t("profile:country")}
          name="country"
          type="text"
          id="country-scroll-target"
          errorMessage={t(
            `${errorMessages?.country ? errorMessages?.country : ""}`
          )}
          errorMessageClassName={"color-red"}
          placeholder={t("common:type")}
          value={profileData?.country ? profileData.country : ""}
          handleChange={(e) =>
            inputChangeHandler(e.target.name, e.target.value)
          }
        />
      </div>
      <>
        <p>{t("profile:phone-num-example")}</p>
        <div className="joined-inputs">
          <FormControl
            inputType="select"
            name="type"
            data={phoneTypes}
            value={phoneTypes.find(
              (type) =>
                type.name ===
                (!!profileData.phones ? profileData.phones[0].type : null)
            )}
            handleChange={(event, newValue) =>
              inputChangeHandler(
                "type",
                newValue ? newValue.name : null,
                "phone",
                0
              )
            }
          />
          <FormControl
            inputType="textfield"
            type="text"
            name="number"
            placeholder={t("common:type")}
            value={profileData.phones ? profileData.phones[0].number : ""}
            handleChange={(e) => {
              if (acceptOnlyNumbers(e.target.value)) {
                inputChangeHandler(e.target.name, e.target.value, "phone", 0);
              }
            }}
            id="phones-scroll-target"
            errorMessage={t(
              `${errorMessages?.number ? errorMessages?.number : ""}`
            )}
            errorMessageClassName={"color-red"}
          />
        </div>
        {!!profileData.phones &&
          profileData.phones.map((item, index) => {
            if (index !== 0) {
              return (
                <div className="joined-inputs" key={item.id}>
                  <FormControl
                    inputType="select"
                    name="type"
                    data={phoneTypes}
                    value={phoneTypes.find(
                      (type) =>
                        type.name ===
                        (profileData.phones[index]
                          ? profileData.phones[index].type
                          : null)
                    )}
                    handleChange={(event, newValue) =>
                      inputChangeHandler(
                        "type",
                        newValue ? newValue.name : null,
                        "phone",
                        index
                      )
                    }
                  />
                  <FormControl
                    inputType="textfield"
                    name="number"
                    type="text"
                    placeholder={t("common:type")}
                    value={item.number}
                    handleChange={(e) => {
                      const inputValue = e.target.value;
                      if (acceptOnlyNumbers(inputValue)) {
                        inputChangeHandler(
                          e.target.name,
                          inputValue,
                          "phone",
                          index
                        );
                      }
                    }}
                  />
                  {index > 0 && (
                    <Button
                      color="transparent"
                      className="close"
                      click={() => removePhoneNumber(item.id)}
                    >
                      <Close />
                    </Button>
                  )}
                </div>
              );
            }
          })}
      </>
      <Button
        color="transparent"
        className="add_another_phone"
        label={t("profile:add-another-phone")}
        click={addPhoneNumber}
        disable={!profileData.phones || profileData?.phones?.length === 1}
      />
      <FormControl
        inputType="textfield"
        label={t("profile:web-page")}
        name="website"
        type="text"
        placeholder={t("common:type")}
        defaultValue={profileData.website}
        handleChange={(e) => inputChangeHandler(e.target.name, e.target.value)}
      />
      <p className="email-address">
        {t("profile:email-address")}:{" "}
        <span> {profileData?.email && profileData?.email} </span>
      </p>
      <Tooltip anchorSelect=".find-on-map" place="top">
        {t("profile:synchronize-map-with-address")}
      </Tooltip>
      <Button
        color="white"
        className="contact-btn"
        label={t("profile:contact-us-for-support")}
        click={goToContactUsPage}
      />
    </div>
  );
};

export default merchantForm;
