import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { defaultLanguage } from "../../../i18n";
import { format, isValid } from "date-fns";
import * as allLocales from "date-fns/locale";
import { fetchEPaper, fetchRevueArticles } from "../../../store/actions";
import { PhotoPlaceholder } from "../../../components/svg-icons";

import "./index.css";

const NewsTestReports = (params) => {
  const { dispatch, ePaperData, articlesData } = params;
  const { t, i18n } = useTranslation(["home", "common"]);
  const lang = (i18n?.language ?? defaultLanguage).toLowerCase();

  useEffect(() => {
    dispatch(fetchEPaper());
    dispatch(fetchRevueArticles(lang));
  }, []);

  useEffect(() => {
    dispatch(fetchRevueArticles(lang));
  }, [lang]);

  const renderRevueArticles = () => {
    if (articlesData && articlesData.length > 0) {
      return articlesData
        .filter((article) => article.thumbnail !== "")
        .map((article, index) => {
          const date =
            article.date && isValid(new Date(article.date))
              ? format(new Date(article.date), "dd.MM.yyyy", {
                  locale: allLocales[lang],
                })
              : null;
          return (
            <div className="news-cards" key={index}>
              <a href={article?.url} target="_blank" rel="noreferrer">
                <div>
                  <img src={article?.thumbnail} />
                </div>
              </a>
              <h5>{article?.title}</h5>
              <span className="revue-section">
                <p>{article?.section}</p>
              </span>
              <div className="news-text">
                <p>{article?.lead}</p>
                <p>{date}</p>
              </div>
            </div>
          );
        });
    }
    return null;
  };

  const formattedDate =
    ePaperData?.date && isValid(new Date(ePaperData.date))
      ? format(new Date(ePaperData.date), "dd.MM.yyyy", {
          locale: allLocales[lang],
        })
      : null;

  const renderEpaperInfo = () => {
    const hasEpaperDataFetched =
      ePaperData && Object.keys(ePaperData).length > 0;
    return (
      <div className="revue-image">
        <span>
          <div>
            {hasEpaperDataFetched ? (
              <img src={ePaperData.image} alt={ePaperData.title} />
            ) : (
              <PhotoPlaceholder />
            )}
            {hasEpaperDataFetched && formattedDate ? (
              <p>{formattedDate}</p>
            ) : (
              <p>{ePaperData.title}</p>
            )}
          </div>
        </span>
      </div>
    );
  };
  return (
    <div className="news-revue-container">
      <div className="news-container-left">{renderRevueArticles()}</div>
      <div className="revue-container-right">
        <a
          href={ePaperData?.url || "https://automobilrevue.ch/e-paper"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderEpaperInfo()}
        </a>
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const { ePaperData, articlesData } = state.car;
  return {
    ePaperData,
    articlesData,
  };
};

export default connect(stateToProps)(NewsTestReports);
