import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import Button from "../../components/button";
import FormControl from "../../components/form-control";
import { getPerimeterOptions } from "../advanced-search/data";
import { setDealerFindProps } from "../../store/actions";

import "./index.css";

const cities = [
  "Zurich",
  "Geneva",
  "Basel",
  "Bern",
  "Lausanne",
  "Winterthur",
  "St. Gallen",
  "Lugano",
  "Lucerne",
  "Biel/Bienne",
  "Thun",
  "La Chaux de Fonds",
  "Rapperswil SG",
  "Schaffhausen",
  "Friborg",
  "Chur",
  "Sitten",
  "Grenchen",
];

const DealerSearch = (props) => {
  const { dispatch, dealer_find_data } = props;
  const { t } = useTranslation([
    "common",
    "advancedSearch",
    "dealerSearch",
    "profile",
    "navbar",
  ]);
  const history = useHistory();
  const perimetarOptions = getPerimeterOptions();

  const handleDealerSearch = () => {
    dispatch(setDealerFindProps("location", dealer_find_data.location));
    history.push("dealer-results");
  };

  const handleCityClick = (city, e) => {
    if (e.button === 0) {
      dispatch(setDealerFindProps("location", city));
      history.push("dealer-results");
    } else if (e.button === 1 || e.button === 2) {
      window.open(`/dealer-results/${city}`, "_blank");
    }
  };

  const handleRadiusChange = (event, value) => {
    dispatch(setDealerFindProps("radius", value.radius ?? null));
  };

  const cityColumns = [[], [], []];
  cities.forEach((city, index) => {
    cityColumns[index % 3].push(city);
  });

  return (
    <div className="dealer-search">
      <div className="form-search-container">
        <header>
          <h3>{t("navbar:dealer-search")}</h3>
        </header>
        <main>
          <FormControl
            inputType="textfield"
            label={t("dealerSearch:dealer")}
            type="text"
            placeholder={t("profile:name")}
            value={dealer_find_data?.dealerName ?? ""}
            handleChange={(event) =>
              dispatch(setDealerFindProps("dealerName", event.target.value))
            }
          />
          <FormControl
            inputType="textfield"
            label={t("dealerSearch:zip-place")}
            type="text"
            placeholder=""
            value={dealer_find_data?.location ?? ""}
            handleChange={(event) =>
              dispatch(setDealerFindProps("location", event.target.value))
            }
          />
          <FormControl
            label={t("advancedSearch:perimetar")}
            inputType="select"
            placeholder={t("common:all")}
            data={perimetarOptions}
            value={perimetarOptions.find(
              (item) => item.radius === dealer_find_data.radius
            )}
            handleChange={handleRadiusChange}
          />
          <Button
            label={t("common:search")}
            className="button"
            click={() => handleDealerSearch()}
            color="red"
          />
        </main>
      </div>
      <div className="dealers-in-swiss">
        <header>
          <h3>{t("dealerSearch:dealers-by-city")}</h3>
        </header>
        <div className="city-columns">
          {cityColumns.map((column, columnIndex) => (
            <ul key={columnIndex}>
              {column.map((city) => (
                <li key={city}>
                  <a
                    href={`/dealer-results/${city}`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleCityClick(city, e);
                    }}
                  >
                    {city}
                  </a>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { dealer_find_data } = state.car;
  return {
    dealer_find_data,
  };
};

export default connect(mapStateToProps)(DealerSearch);
