import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Typography, TextField } from "@mui/material";
import { registerUserRequest, clearData } from "../../../store/actions";
import { emailRegex } from "../../../lib/utils";
import { ShowPassword } from "../../../components/svg-icons";
import SimpleLoader from "../../../components/simple-loader";
import FormControl from "../../../components/form-control/index";
import Button from "../../../components/button/index";
import ThanksPage from "../../../components/thanks-page";
import ChooseUser from "../choose-user";
import "./index.css";

const SignUp = (props) => {
  const {
    dispatch,
    loading,
    isAuthorized,
    registrationSuccess,
    registrationFail,
  } = props;
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [data, setData] = useState({ email: "", password1: "", password2: "" });
  const [validationMessages, setValidationMessages] = useState({});
  const [choosenType, setChoosenType] = useState(null);
  const [isOnRegister, setIsOnRegister] = useState(true);
  const { t } = useTranslation(["common", "auth"]);

  useEffect(() => {
    return () => {
      dispatch(clearData(["registrationSuccess", "registrationFail"]));
    };
  }, []);

  useEffect(() => {
    if (registrationSuccess) {
      setData({ email: "", password1: "", password2: "" });
    }
  }, [registrationSuccess]);

  const registerHandler = (event) => {
    event.preventDefault();
    let errorFlag = false;
    for (const key in data) {
      const value = data[key].trim();
      if (key === "email") {
        if (!emailRegex.test(value)) {
          errorFlag = true;
          setValidationMessages((prevState) => {
            return {
              ...prevState,
              emailError: "auth:validation-email-message",
            };
          });
        } else {
          setValidationMessages((prevState) => {
            delete prevState.emailError;
            return { ...prevState };
          });
        }
      }
      if (key.includes("password")) {
        if (value.length < 6 || value.length > 30) {
          errorFlag = true;
          setValidationMessages((prevState) => {
            return {
              ...prevState,
              passwordError: "auth:validation-password-message",
            };
          });
        } else {
          setValidationMessages((prevState) => {
            delete prevState.passwordError;
            return { ...prevState };
          });
        }
        if (key === "password1" && value !== data.password2.trim()) {
          errorFlag = true;
          setValidationMessages((prevState) => {
            return {
              ...prevState,
              samePasswordError: "auth:validation-same-password-message",
            };
          });
        } else if (key === "password2" && value !== data.password1.trim()) {
          errorFlag = true;
          setValidationMessages((prevState) => {
            return {
              ...prevState,
              samePasswordError: "auth:validation-same-password-message",
            };
          });
        } else {
          setValidationMessages((prevState) => {
            delete prevState.samePasswordError;
            return { ...prevState };
          });
        }
      }
    }
    if (
      errorFlag ||
      !data?.email.trim() ||
      !data?.password1.trim() ||
      !data?.password2.trim()
    )
      return;
    dispatch(
      registerUserRequest(data.email.trim(), data.password1.trim(), choosenType)
    );
  };

  const inputChangeHandler = (event) => {
    setData((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  if (choosenType === null) {
    return (
      <ChooseUser
        title={t("auth:do-you-want-to-register-as")}
        description={t("auth:choose-whether-you-want-to-register")}
        setChoosenType={setChoosenType}
        isOnRegister={isOnRegister}
      />
    );
  }
  return (
    <SimpleLoader loading={loading}>
      {isAuthorized ? null : (
        <div>
          {!registrationSuccess ? (
            <Box className="register">
              <Box className="header">
                <h5>{t("auth:register-with-carindex")}</h5>
                <p>{t("auth:register-instruction1-pt1")}</p>
                <p>{t("auth:register-instruction1-pt2")}</p>
              </Box>
              <form onSubmit={registerHandler} className="register-form">
                <Box className="flex-row-wrapper regformdiv1">
                  <FormControl className="register-label primary-font-color">
                    <span>{t("auth:your-email-address")}</span>
                    <TextField
                      value={data?.email ? data.email : ""}
                      id="email"
                      name="email"
                      variant="outlined"
                      type="text"
                      placeholder={t("common:type")}
                      onChange={(event) => inputChangeHandler(event)}
                    />
                  </FormControl>
                  {validationMessages?.emailError && (
                    <span className="errorMessage">
                      {t(validationMessages.emailError)}
                    </span>
                  )}
                </Box>
                <Box className="regformdiv2">
                  <FormControl className="register-label primary-font-color">
                    <span>{t("auth:enter-password")}</span>
                    <Box className="inputandicon">
                      <TextField
                        value={data?.password1 ? data.password1 : ""}
                        id="password1"
                        name="password1"
                        variant="outlined"
                        type={showPassword1 ? "text" : "password"}
                        placeholder={t("common:type")}
                        onChange={(event) => inputChangeHandler(event)}
                      />
                      <span
                        className="showPassword"
                        onClick={() => setShowPassword1((prev) => !prev)}
                      >
                        {ShowPassword}
                      </span>
                    </Box>
                  </FormControl>
                  <FormControl className="register-label primary-font-color">
                    <span>{t("auth:re-enter-password")}</span>
                    <Box className="inputandicon">
                      <TextField
                        value={data?.password2 ? data.password2 : ""}
                        id="password2"
                        name="password2"
                        variant="outlined"
                        type={showPassword2 ? "text" : "password"}
                        placeholder={t("common:type")}
                        onChange={(event) => inputChangeHandler(event)}
                      />
                      <span
                        className="showPassword2"
                        onClick={() => setShowPassword2((prev) => !prev)}
                      >
                        {ShowPassword}
                      </span>
                    </Box>
                  </FormControl>
                  <Button
                    type="submit"
                    color="red"
                    label={t("common:register")}
                    className="register-button"
                    click={registerHandler}
                  />
                </Box>
                {validationMessages?.passwordError && (
                  <span className="errorMessage">
                    {t(validationMessages.passwordError)}
                  </span>
                )}
                {validationMessages?.samePasswordError && (
                  <span className="errorMessage">
                    {t(validationMessages.samePasswordError)}
                  </span>
                )}
                {!validationMessages?.emailError &&
                  !validationMessages?.passwordError &&
                  !validationMessages?.samePasswordError &&
                  registrationFail && (
                    <span className="errorMessage">
                      {t("common:global-error")}
                    </span>
                  )}
                <Box className="regformdiv3">
                  <Button
                    color="gray"
                    label={t("common:return-to-picker")}
                    className="register-button"
                    click={() => setChoosenType(null)}
                  />
                </Box>
              </form>
              <Box className="footer">
                <Typography varinat="p">
                  {t("auth:register-instruction2")}
                </Typography>
              </Box>
            </Box>
          ) : (
            <div>
              {!validationMessages?.emailError &&
                !validationMessages?.passwordError &&
                !validationMessages?.samePasswordError &&
                registrationSuccess && (
                  <ThanksPage
                    texts={[
                      "auth:successful-registration1",
                      "auth:successful-registration2",
                    ]}
                  ></ThanksPage>
                )}
            </div>
          )}
        </div>
      )}
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { loading, isAuthorized, registrationSuccess, registrationFail } =
    state.profile;
  return {
    loading,
    isAuthorized,
    registrationSuccess,
    registrationFail,
  };
};

export default connect(stateToProps)(SignUp);
