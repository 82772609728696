import { FormControlLabel, Radio } from "@mui/material";
import React from "react";
import "./index.css";

const Index = (props) => {
  const {
    name,
    value,
    label,
    checked,
    id,
    className,
    changeHandler,
    disabled,
  } = props;

  return (
    <div className="radio-button">
      <FormControlLabel
        id={id}
        name={typeof name === "object" ? name["EN"] : name}
        value={value}
        control={
          <Radio
            checked={checked}
            size="small"
            onChange={changeHandler}
            sx={{
              color: "#c8c8c8",
              "&.Mui-checked": {
                color: "var(--okx-palette-primary)",
              },
            }}
          />
        }
        label={label}
        className={className + " radio-button-control"}
        disabled={disabled}
      />
    </div>
  );
};

export default Index;
