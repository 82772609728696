import React from "react";
import { withStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ArrowDown, ArrowDownDisabled } from "../svg-icons";
import "./index.css";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  },
})(TextField);

const Select = (props) => {
  const {
    label,
    placeholder,
    defaultValue,
    data,
    handleChange,
    disabled,
    customArrowColor,
  } = props;
  const { t } = useTranslation("common");
  const language = localStorage.getItem("i18nextLng");

  return (
    <div className="mui-select">
      <Autocomplete
        disabled={disabled}
        value={defaultValue ? defaultValue : null}
        id={label}
        options={data || []}
        // className={props.className}
        popupIcon={
          disabled ? (
            <ArrowDownDisabled style={{ fontSize: 18, marginTop: 2 }} />
          ) : (
            <ArrowDown
              style={{ fontSize: 18, marginTop: 2 }}
              color={customArrowColor || "#f61d2a"}
            />
          )
        }
        getOptionLabel={(option) => {
          if (!option) return "";
          const { name, short_name } = option;
          const fullName =
            typeof name === "object"
              ? name[language ? language.toUpperCase() : "EN"] ?? ""
              : name ?? "";

          let label = fullName;

          // Add null check for short_name
          if (short_name && typeof short_name === "string") {
            const trimmedShortName = short_name.trim();
            if (
              trimmedShortName.startsWith("{") &&
              trimmedShortName.endsWith("}")
            ) {
              try {
                const parsedShortName = JSON.parse(trimmedShortName);
                const fullShortName =
                  typeof parsedShortName === "object"
                    ? parsedShortName[
                        language ? language.toUpperCase() : "EN"
                      ] ?? ""
                    : parsedShortName;
                label += ` ${fullShortName}`;
              } catch (e) {
                console.warn("Failed to parse short_name:", e);
              }
            }
          }

          return label.trim();
        }}
        isOptionEqualToValue={(option, value) => option === value}
        onChange={handleChange}
        renderInput={(params) => (
          <CssTextField
            {...params}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            placeholder={placeholder ? placeholder : t("common:select")}
            InputProps={{
              ...params.InputProps,
              className: "custom-select-input",
            }}
          />
        )}
        noOptionsText={t("common:no-option")}
      />
    </div>
  );
};

export default Select;
