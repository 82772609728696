import React from "react";
import { PhotoPlaceholder } from "../svg-icons";
import Image from "../image/index";
import Button from "../button/index";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import useIntersectionObserver from "../../lib/use-intersection-observer";

import "./index.css";

const Carousel = (props) => {
  const { images, position, handleNext, handlePrevious, handleThumbClick } =
    props;
  const refe = React.useRef();
  const [isVisible, setIsVisible] = React.useState(false);

  const test = () => {
    setIsVisible(true);
  };

  useIntersectionObserver({
    target: refe,
    onIntersect: ([{ isIntersecting }], observerElement) => {
      if (isIntersecting) {
        // setIsVsiible(true);
        test();
        observerElement.unobserve(refe.current);
      }
    },
  });

  return (
    <div className="_carousel-container">
      <div className="_carousel">
        <Button
          click={handlePrevious}
          className="_carousel-button prev"
          color="transparent"
          disabled={!images || images?.length === 1}
        >
          <ArrowLeftIcon />
        </Button>
        <Button
          click={handleNext}
          className="_carousel-button next"
          color="transparent"
          disabled={!images || images?.length === 1}
        >
          <ArrowRightIcon />
        </Button>
        {!!images && !!images.length ? (
          images.map((item, index) => {
            return (
              <div className="_carousel__cell" key={index} ref={refe}>
                <img
                  src={item}
                  className="_carousel-main-photo"
                  alt=""
                  style={{ transform: `translateX(-${position}%)` }}
                />
              </div>
            );
          })
        ) : (
          <div className="_carousel__cell" ref={refe}>
            <PhotoPlaceholder />
          </div>
        )}
      </div>
      <div className="_thumbnails">
        <div className="_thumb" onClick={() => !!images && handleThumbClick(1)}>
          {images && images[0] ? (
            <Image src={images[0]} className={`_thumb-image`} />
          ) : (
            <PhotoPlaceholder />
          )}
        </div>
        <div
          className="_thumb"
          onClick={() => images && images[1] && handleThumbClick(2)}
        >
          {images && images[1] ? (
            <Image src={images[1]} className={`_thumb-image`} />
          ) : (
            <PhotoPlaceholder />
          )}
        </div>
        <div
          className="_thumb"
          // onClick={ () => (images && images[2]) && handleThumbClick(3) }
        >
          {images?.length > 3 ? <span className="layer"></span> : null}
          {images?.length > 3 ? (
            <span className="_more-photos">
              + {images.length - 3}{" "}
              {images.length - 3 === 1 ? "photo" : "photos"}
            </span>
          ) : null}
          {images && images[2] ? (
            <Image src={images[2]} className={`_thumb-image`} />
          ) : (
            <PhotoPlaceholder />
          )}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
