import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAdsForIds } from "../../store/actions";
import { ADS_PER_PAGE } from "../../data/index";
import {
  getFavoritesIds,
  getTotalPages,
  toggleFavorites,
} from "../../lib/utils";
import Ads from "../../components/ads";
import history from "../../lib/history";

import "./index.css";

const Favorites = (props) => {
  const { t, i18n } = useTranslation(["common, results"]);
  const { dispatch, favoriteAds } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortLabel, setSortLabel] = useState("common:low-to-high");
  const [paginationStep, setPaginationStep] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [adsToPreview, setAdsToPreview] = useState([]);
  const [favoriteIds, setFavoriteIds] = useState(null);
  const [favs, setFavs] = useState([]);
  const [readyToSort, setReadyToSort] = useState(false);

  const handleSort = (idSort) => {
    if (idSort === 0) {
      setSortLabel("common:low-to-high");
      const data = favs.sort((a, b) => a.price - b.price);
      setFavs(data);
    }
    if (idSort === 1) {
      setSortLabel("common:high-to-low");
      const data = favs.sort((a, b) => b.price - a.price);
      setFavs(data);
    }
    setReadyToSort(true);
    setAnchorEl(null);
  };
  /**
   *-----------------------------------------------
   * OPEN SORT DROPDOWN
   *-----------------------------------------------
   */
  const openSortMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *-----------------------------------------------
   * PREVIEW AD
   *-----------------------------------------------
   */
  const previewAdHandler = (idad) => {
    localStorage.setItem("previewAd", true);
    history.push({
      pathname: `/${i18n.language}/ad/${idad}`,
      state: { activePage: paginationStep, sortLabel: sortLabel },
    });
  };

  /**
   *-----------------------------------------------
   * PAGINATION LOGIC
   *-----------------------------------------------
   *
   * @param {Number} currentPage
   */
  const handlePagination = (currentPage) => {
    setPaginationStep(currentPage);
  };

  const narrowDownAds = (data, currentStep = null) => {
    const numberOfPages = getTotalPages(data);
    let paginationState = paginationStep;
    if (currentStep) {
      paginationState = currentStep;
    }
    const updatedData = data.filter(
      (item, index) =>
        index >= ADS_PER_PAGE * paginationState - ADS_PER_PAGE &&
        index <= ADS_PER_PAGE * paginationState - 1
    );
    setAdsToPreview(updatedData);
    setTotalPages(numberOfPages);
  };

  /**
   *-----------------------------------------------
   * PAGINATION LOGIC
   *-----------------------------------------------
   *
   * @param {Number} currentPage
   */
  const setFavoriteIdsHadnler = () => {
    if (favoriteIds === null) {
      const getFavoriteIds = getFavoritesIds();
      setFavoriteIds(getFavoriteIds);
    }
  };

  /**
   *-----------------------------------------------
   * TOGGLE FAVORITE ADS
   *-----------------------------------------------
   * @param { Number } idad
   */
  const toggleFavoritesHandler = (idad) => {
    toggleFavorites(idad);
    const favorites = getFavoritesIds();
    setFavoriteIds(favorites);
  };

  useEffect(() => {
    const previewAd = localStorage.getItem("previewAd");

    if (previewAd) {
      // user is coming from details page
      const activePage = parseInt(localStorage.getItem("activePage"), 10);
      const sLabel = localStorage.getItem("sortLabel");
      setFavoriteIdsHadnler();
      setPaginationStep(activePage);
      setSortLabel(sLabel);
    } else {
      if (favoriteIds === null) {
        setFavoriteIdsHadnler();
      } else {
        dispatch(getAdsForIds(favoriteIds));
      }
    }
    localStorage.removeItem("previewAd");
    localStorage.removeItem("activePage");
    localStorage.removeItem("sortLabel");
  }, [favoriteIds]);

  useEffect(() => {
    if (favoriteAds !== null) {
      setFavs(favoriteAds);
    }
  }, [favoriteAds]);

  useEffect(() => {
    narrowDownAds(favs, paginationStep);
  }, [favs]);

  useEffect(() => {
    if (readyToSort) {
      narrowDownAds(favs, 1);
      setPaginationStep(1);
      setReadyToSort(false);
    }
  }, [readyToSort]);

  useEffect(() => {
    if (favs !== null) {
      narrowDownAds(favs, paginationStep);
    }
  }, [paginationStep]);

  return (
    <div className="favourites">
      <div className="aside"></div>
      <main className="main">
        <Ads
          data={adsToPreview}
          activePage={paginationStep}
          totalPages={totalPages}
          paginationHandler={handlePagination}
          title={t("common:your-favourite-cars")}
          sortLabel={sortLabel}
          openSort={openSortMenu}
          anchorEl={anchorEl}
          handleSort={handleSort}
          dropdownData={["common:low-to-high", "common:high-to-low"]}
          previewAd={previewAdHandler}
          toggleFavorites={toggleFavoritesHandler}
          favoriteIds={favoriteIds}
          path="favourites"
        />
      </main>
    </div>
  );
};

const stateToProps = (state) => {
  const { favoriteAds } = state.car;
  return {
    favoriteAds,
  };
};

export default connect(stateToProps)(Favorites);
