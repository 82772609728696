import React from "react";
import Select from "../select/index";
import TextField from "../text-field/index";
import DatePicker from "../date-picker/index";
import "./index.css";
import { Checkbox } from "@material-ui/core";

const FormControl = (props) => {
  const {
    label,
    handleChange,
    data,
    value,
    defaultValue,
    type,
    placeholder,
    disabled,
    fullWidth,
    multiline,
    maxRows,
    children,
    className,
    name,
    inputType,
    id,
    errorMessage,
    errorMessageClassName,
    InputProps,
  } = props;

  let component = (
    <TextField
      type={type}
      placeholder={placeholder}
      value={value}
      changeHandler={handleChange}
      disabled={disabled}
      fullWidth={fullWidth}
      multiline={multiline}
      maxRows={maxRows}
      defaultValue={defaultValue}
      name={name}
      id={id}
      InputProps={InputProps}
    />
  );

  if (inputType === "select") {
    component = (
      <Select
        handleChange={handleChange}
        data={data}
        defaultValue={value}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        error={false}
      />
    );
  }

  if (inputType === "checkbox") {
    component = (
      <span className="wrapper checkbox-control" onClick={handleChange}>
        <Checkbox
          size="small"
          checked={value}
          onChange={handleChange}
          onClick={handleChange}
          disabled={disabled}
        />
      </span>
    );
  }

  if (inputType === "datepicker") {
    component = <DatePicker changeHandler={handleChange} value={value} />;
  }

  if (children) {
    component = <div>{children}</div>;
  }

  return (
    <div className={`formControl ${className ? className : ""}`}>
      <label>{label}</label>
      {component}
      {errorMessage && (
        <span className={errorMessageClassName ? errorMessageClassName : ""}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default FormControl;
