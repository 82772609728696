import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { withStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ArrowDown } from "../svg-icons";
import "./index.css";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#969696",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#969696",
      },
    },
  },
})(TextField);

const ColorPicker = ({ changeHandler, value, id, colors = [] }) => {
  const { t } = useTranslation(["common"]);

  const selectedValue =
    colors && id ? colors.find((item) => item[id] === Number(value)) : null;

  return (
    <Autocomplete
      value={selectedValue ?? null}
      options={colors}
      popupIcon={<ArrowDown style={{ fontSize: 18 }} />}
      className="mui-select color-picker-autocomplete"
      getOptionLabel={(option) => option?.name ?? ""}
      isOptionEqualToValue={(option, value) => option === value}
      onChange={changeHandler}
      renderInput={(params) => (
        <CssTextField
          {...params}
          variant="outlined"
          placeholder={t("common:select")}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} className="color-option">
          <div
            className="color-dot"
            style={{ backgroundColor: `#${option.rgb}` }}
          />
          <span style={{ color: "inherit" }}>{option.name}</span>
        </li>
      )}
      renderTags={(value) =>
        value ? (
          <div className="color-selected">
            <div
              className="color-dot"
              style={{ backgroundColor: `#${value.rgb}` }}
            />
            <span>{value.name}</span>
          </div>
        ) : null
      }
    />
  );
};

export default ColorPicker;
