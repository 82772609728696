import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { limitedArrayRender } from "../../../lib/utils";
import { brandsImagesArr } from "./data";
import "./index.css";

const brandsImages = brandsImagesArr;

const DealerCard = (props) => {
  const { name, uid, city, address_data, brands, company } = props.dealer;
  const history = useHistory();
  const { i18n } = useTranslation();

  const handleCardClick = (e) => {
    e.preventDefault();
    history.push(`/${i18n.language}/seller/${uid}`);
  };

  const renderBrandLogos = () => {
    const limitedBrands = limitedArrayRender(brands, 5);
    return limitedBrands?.map((brand) => {
      const brandImage = brandsImages.find((image) => image.label === brand);
      if (brandImage) {
        return (
          <div key={brandImage.label} className="make-logos">
            <img
              src={brandImage.src}
              alt={brandImage.label}
              className={brandImage.label}
            />
            <p>{brandImage.label}</p>
          </div>
        );
      }
      return null;
    });
  };

  return (
    <div className="dealer-card" onClick={handleCardClick}>
      <a
        href={`${i18n.language}/seller/${uid}`}
        onClick={(e) => e.preventDefault()}
        style={{ textDecoration: "none" }}
      >
        <div className="dealer-info">
          <h3>{company !== "" ? company : name}</h3>
          <p>{address_data?.streetandnumber}</p>
          <p>
            {address_data?.zipcode} {address_data?.city || city}
          </p>
        </div>
      </a>
      <div className="dealer-logos">{renderBrandLogos()}</div>
    </div>
  );
};

export default DealerCard;
