import React from "react";
import "./ThemeToggleButton.css";

const ThemeToggleButton = ({ toggleTheme, isDarkTheme }) => {
  return (
    <button
      onClick={toggleTheme}
      className={`theme-toggle-btn ${isDarkTheme ? "light" : "dark"}`}
      aria-label={isDarkTheme ? "Switch to light mode" : "Switch to dark mode"}
    >
      <svg className="theme-icon" viewBox="0 0 24 24">
        <g className="sun-wrapper">
          <circle className="sun" cx="12" cy="12" r="5" />
          <g className="sun-rays">
            {[0, 45, 90, 135, 180, 225, 270, 315].map((rotation) => (
              <rect
                key={rotation}
                className="ray"
                x="11"
                y="1"
                width="2"
                height="4"
                rx="1"
                transform={`rotate(${rotation} 12 12)`}
              />
            ))}
          </g>
        </g>
        <g className="moon-wrapper">
          <mask id="moon-mask">
            <rect x="0" y="0" width="24" height="24" fill="white" />
            <circle cx="18" cy="6" r="8" fill="black" />
          </mask>
          <circle
            className="moon"
            cx="12"
            cy="12"
            r="6"
            mask="url(#moon-mask)"
          />
        </g>
      </svg>
    </button>
  );
};

export default ThemeToggleButton;
