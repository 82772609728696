import { useTranslation } from "react-i18next";

export const emissionClassLessThan = [
  { name: "All", value: null },
  { name: "50", value: 50 },
  { name: "100", value: 100 },
  { name: "150", value: 150 },
  { name: "200", value: 200 },
  { name: "250", value: 250 },
  { name: "300", value: 300 },
];

export const getPerimeterOptions = () => {
  const { t } = useTranslation("common");
  return [
    { name: t("common:all"), value: null, radius: null },
    { name: "10 km", value: 10000, radius: 6.21 },
    { name: "20 km", value: 20000, radius: 12.43 },
    { name: "30 km", value: 30000, radius: 18.64 },
    { name: "40 km", value: 40000, radius: 24.85 },
    { name: "50 km", value: 50000, radius: 31.07 },
    { name: "75 km", value: 75000, radius: 46.6 },
    { name: "100 km", value: 100000, radius: 62.14 },
    { name: "200 km", value: 200000, radius: 124.27 },
    { name: "300 km", value: 300000, radius: 186.41 },
  ];
};
