import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ArrowDown } from "../../../../../components/svg-icons/index";

const CookiesSection = ({ section, t }) => {
  return (
    <div>
      <Accordion key={section.id}>
        <AccordionSummary
          expandIcon={<ArrowDown />}
          aria-controls={`section${section.id}`}
          id={`section${section.id}`}
        >
          <h4 className="subtitle">{t(`footer:${section.subtitle}`)}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <label>
              {Array.isArray(section.label) ? (
                <div>
                  {section.label.map((item, index) => (
                    <div key={index}>
                      {item.link ? (
                        <p>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.link}
                          </a>
                        </p>
                      ) : (
                        <p>{t(`footer:${item.text}`)}</p>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <p>{t(`footer:${section.label}`)}</p>
              )}
            </label>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CookiesSection;
