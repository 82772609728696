import {
  transformData,
  prepareDataForSocetRequest,
  getCurrentLocale,
  sortByAlphabet,
  sortByNumeric,
  acceptOnlyNumbers,
  mapConditionMaintenance,
} from "../../lib/utils";
import convertible from "../../assets/images/bodytype/convertible.svg";
import coupe from "../../assets/images/bodytype/coupe.svg";
import offroad from "../../assets/images/bodytype/suv.svg";
import station from "../../assets/images/bodytype/station.svg";
import sedan from "../../assets/images/bodytype/sedan.svg";
import targa from "../../assets/images/bodytype/targa.svg";
import van from "../../assets/images/bodytype/van.svg";
import minivan from "../../assets/images/bodytype/minivan.svg";
import other from "../../assets/images/bodytype/na.svg";

const BODY_TYPE_IMAGES = [
  offroad,
  coupe,
  minivan,
  station,
  sedan,
  targa,
  convertible,
  van,
  other,
];

import {
  GETMAKE,
  GETMAKE_RESULT,
  GETMAKE_ERROR,
  GETMODEL,
  GETMODEL_RESULT,
  GETMODEL_ERROR,
  GETFUELTYPE,
  GETFUELTYPE_RESULT,
  GETFUELTYPE_ERROR,
  GETBODYTYPE,
  GETBODYTYPE_RESULT,
  GETBODYTYPE_ERROR,
  GETVERSION,
  GETVERSION_RESULT,
  GETVERSION_ERROR,
  CREATEAD,
  CREATEAD_RESULT,
  CREATEAD_ERROR,
  SEARCH,
  SEARCH_RESULT,
  SEARCH_ERROR,
  SETSEARCHPROPS,
  RESETSEARCHPROPS,
  FINDADS,
  FINDADS_RESULT,
  FINDADS_ERROR,
  FINDADS2,
  FINDADS2_RESULT,
  FINDADS2_ERROR,
  FINDADS3,
  FINDADS3_RESULT,
  FINDADS3_ERROR,
  FINDDEALERS,
  FINDDEALERS_RESULT,
  FINDDEALERS_ERROR,
  CUSTOMSETFINDPROPS,
  SETDEALERFINDPROPS,
  SETFINDPROPS,
  RESETFINDPROPS,
  GETAD_RESULT,
  GETAD_ERROR,
  GETAD,
  RENDERSAVEDSEARCH,
  RESETSELECTEDAD,
  CHECKBOXCHANGEHANDLER,
  GETADSFOROWNER,
  GETADSFOROWNER_RESULT,
  GETADSFOROWNER_ERROR,
  DELETEADS,
  DELETEADS_RESULT,
  DELETEADS_ERROR,
  GETINTERIORCOLORFORLOCALE,
  GETINTERIORCOLORFORLOCALE_RESULT,
  GETINTERIORCOLORFORLOCALE_ERROR,
  GETLOCALE,
  GETLOCALE_RESULT,
  GETLOCALE_ERROR,
  GETBODYTYPEFORLOCALE,
  GETBODYTYPEFORLOCALE_RESULT,
  GETBODYTYPEFORLOCALE_ERROR,
  RESETCHECKBOX,
  RESETADS,
  GETCONDITIONFORLOCALE,
  GETCONDITIONFORLOCALE_RESULT,
  GETCONDITIONFORLOCALE_ERROR,
  GETUPHOLSTERYFORLOCALE,
  GETUPHOLSTERYFORLOCALE_RESULT,
  GETUPHOLSTERYFORLOCALE_ERROR,
  SETGENERALDATA,
  SETVEHICLEDATA,
  SETCONDITIONMAINTENANCE,
  SETOPTIONALEQUIPMENTSIDS,
  SETOPTIONALEURONORMIDS,
  SETENGINEENVIRONMENT,
  SETROOTADDATA,
  SET_ROOT_DATA_PROPS,
  SETFUELCONSUPTION,
  RESETPOWER,
  RESETDOORCOUNT,
  RESETNUMBEROFSEATS,
  REMOVEGENERALDATAPROPS,
  REMOVEVEHICLEDATAPROPS,
  ISADCREATED,
  RESETCARMODEL,
  RESETREADYTOREPORT,
  GETMOTORSFORMAKEMODELFUELBODY,
  GETMOTORSFORMAKEMODELFUELBODY_RESULT,
  GETMOTORSFORMAKEMODELFUELBODY_ERROR,
  GETTRANSMISSIONFORLOCALE,
  GETTRANSMISSIONFORLOCALE_RESULT,
  GETTRANSMISSIONFORLOCALE_ERROR,
  GETBODYCOLORFORLOCALE,
  GETBODYCOLORFORLOCALE_RESULT,
  GETBODYCOLORFORLOCALE_ERROR,
  RESETCHECKBOXESINOBJECT,
  GETCARDATAFORMOTORID,
  GETCARDATAFORMOTORID_RESULT,
  GETCARDATAFORMOTORID_ERROR,
  RESETCARDATA,
  RESETMOTORS,
  SETVEHICLELOCATION,
  SETDESCRIPTION,
  RESETADPROPS,
  RESETDEALERFINDPROPS,
  PUBLISHAD,
  PUBLISHAD_RESULT,
  PUBLISHAD_ERROR,
  UNPUBLISHAD,
  UNPUBLISHAD_RESULT,
  UNPUBLISHAD_ERROR,
  GETADSFORIDS,
  GETADSFORIDS_RESULT,
  GETADSFORIDS_ERROR,
  GETDOORS,
  GETDOORS_RESULT,
  GETDOORS_ERROR,
  GETSEATS,
  GETSEATS_RESULT,
  GETSEATS_ERROR,
  GETEURONORM,
  GETEURONORM_RESULT,
  GETEURONORM_ERROR,
  GETTRAILERHITCH,
  GETTRAILERHITCH_RESULT,
  GETTRAILERHITCH_ERROR,
  GETENERGYEFFICIENCE,
  GETENERGYEFFICIENCE_RESULT,
  GETENERGYEFFICIENCE_ERROR,
  GETVEHICLESCOUNTBYBODYTYPE,
  GETVEHICLESCOUNTBYBODYTYPE_RESULT,
  GETVEHICLESCOUNTBYBODYTYPE_ERROR,
  REMOVECARMODEL,
  REPORTAD,
  REPORTAD_RESULT,
  REPORTAD_ERROR,
  FETCHEPAPER,
  FETCHEPAPER_RESULT,
  FETCHEPAPER_ERROR,
  GETCARSOFTHEDAY,
  GETCARSOFTHEDAY_RESULT,
  GETCARSOFTHEDAY_ERROR,
  GETDRIVETYPE,
  GETDRIVETYPE_RESULT,
  GETDRIVETYPE_ERROR,
  FETCHARTICLES,
  FETCHARTICLES_RESULT,
  FETCHARTICLES_ERROR,
  GETCYLINDERS,
  GETCYLINDERS_RESULT,
  GETCYLINDERS_ERROR,
  GETTRAILERLOAD,
  GETTRAILERLOAD_RESULT,
  GETTRAILERLOAD_ERROR,
  GETOPT,
  GETOPT_RESULT,
  GETOPT_ERROR,
  GETMERCHANT2,
  GETMERCHANT2_RESULT,
  GETMERCHANT2_ERROR,
  GETVEHICLESFORSERIALNO,
  GETVEHICLESFORSERIALNO_RESULT,
  GETVEHICLESFORSERIALNO_ERROR,
  GETVEHICLESFORREGDOCNO,
  GETVEHICLESFORREGDOCNO_RESULT,
  GETVEHICLESFORREGDOCNO_ERROR,
  RESETERRORS18AND24,
  RESETVEHICLEDATA18AND24,
  SET_TARGA_VEHICLE_TYPE,
  GETENGINETYPE,
  GETENGINETYPE_RESULT,
  GETENGINETYPE_ERROR,
  GETENVIRONMENTALSTICKER,
  GETENVIRONMENTALSTICKER_RESULT,
  GETENVIRONMENTALSTICKER_ERROR,
} from "./constants";

import { sendMessage } from "../comm/sender";

let initialState = {
  make: [],
  makeWithAll: [],
  car_model: [],
  carModelWithAll: [],
  fuel_type: [],
  body_type: [],
  bodyTypeWithAll: [],
  fuelTypeWithAll: [],
  drive_type: [],
  driveTypeWithAll: [],
  search_data: { makeId: 0, modelId: 0, year: 0, price: 0 },
  find_data: { fromCount: 0, limitCount: 5 },
  dealer_find_data: { fromCount: 0, limitCount: 5 },
  dealers: [],
  dealers_found: 0,
  dealers_total: 0,
  ad_data: {},
  general_data: {},
  vehicle_data: {},
  condition_maintenance: {},
  optional_equipmentids: [],
  optional_euro_normids: [],
  engine_environment: {},
  fuel_consumption: {},
  vehicle_location: {},
  ad_description: null,
  version: [],
  isAuthorized: false,
  ads: [],
  ad: null,
  adId: null,
  loading: false,
  imageToUpload: null,
  favoriteAds: null,
  adCreated: false,
  readyToPublish: false,
  myCars: [],
  createAdFailed: false,
  power: [],
  withUpload: false,
  locales: [],
  idlocale: 2,
  interiorcolor: [],
  upholstery: [],
  condition: [],
  ads_total: null,
  ads_found: null,
  motors: [],
  doors: [],
  seats: [],
  euronorm: [],
  euronormWithAll: [],
  trailerhitch: [],
  trailerhitchWithAll: [],
  gears: [],
  gearbox: [],
  numberofcylinders: [],
  cylinders: [],
  cylindersWithAll: [],
  curbweight: [],
  transmission: [],
  transmissionWithAll: [],
  transformedTransmission: [],
  trailerload: [],
  trailerLoadWithAll: [],
  equipment: [],
  draftSaved: false,
  adUnpublished: false,
  bodyTypeCount: [],
  bodyColors: [],
  modelIndex: null,
  bothModel: true,
  energyEfficience: [],
  readyToReport: false,
  ePaperData: {},
  carsOfTheDay: [],
  articlesData: {},
  vehicleData18: [],
  vehicleData24: [],
  vehicleData18Error: false,
  vehicleData24Error: false,
  vehicleType: null,
  targaCode18: null,
  targaCode24: null,
  enginetype: [],
  emissionsticker: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    // car index
    case SETSEARCHPROPS: {
      return {
        ...state,
        search_data: { ...state.search_data, [action.key]: action.value },
      };
    }

    case RESETSEARCHPROPS: {
      return { ...state, search_data: [] };
    }

    case CUSTOMSETFINDPROPS: {
      const { key, value } = action;
      const data = { ...state.find_data };
      data[key] = value;
      return { ...state, find_data: data };
    }

    case SETFINDPROPS: {
      const { key, value, valueType } = action;
      const data = { ...state.find_data };
      if (valueType === "array") {
        if (!!value) {
          if (!!data[key]) {
            data[key] = [...data[key], value];
          }
          if (key === "bodyColor") {
            data[key] = [value];
          } else {
            data[key] = [value];
          }
        } else {
          data[key] = null;
        }
      } else {
        if (key === "equipmentLine" && data[key]) {
          data[key] = [value];
        } else {
          data[key] = value;
        }
      }
      return { ...state, find_data: data };
    }

    case REMOVECARMODEL: {
      const newModel = state.carModelWithAll.filter(
        (_, index) => index !== action.index
      );
      return { ...state, carModelWithAll: newModel };
    }

    case RESETCARMODEL: {
      if (acceptOnlyNumbers(action.index)) {
        const newModelWithAll = [...state.carModelWithAll];
        newModelWithAll[action.index] = null;
        return {
          ...state,
          carModelWithAll: newModelWithAll,
        };
      }
      return { ...state, car_model: [], carModelWithAll: [] };
    }

    case RESETREADYTOREPORT: {
      return {
        ...state,
        readyToReport: false,
      };
    }

    case RESETFINDPROPS: {
      const resetBodytype = transformData(
        state.body_type,
        ["checked"],
        [false]
      );
      const resetFueltype = transformData(
        state.fuel_type,
        ["checked"],
        [false]
      );
      return {
        ...state,
        find_data: {
          fromCount: 0,
          limitCount: 5,
          idMake: [null],
          idModel: [null],
          equipmentLine: [null],
        },
        body_type: resetBodytype,
        fuel_type: resetFueltype,
      };
    }

    case RESETDEALERFINDPROPS: {
      return {
        ...state,
        dealer_find_data: {
          fromCount: 0,
          limitCount: 5,
        },
      };
    }

    case REMOVEGENERALDATAPROPS: {
      const updatedData = { ...state.generaldata };
      action.keys.forEach((key) => {
        delete updatedData[key];
      });
      return { ...state, generaldata: updatedData };
    }

    case REMOVEVEHICLEDATAPROPS: {
      const updatedData = { ...state.vehicledata };
      action.keys.forEach((key) => {
        delete updatedData[key];
      });
      return { ...state, vehicledata: updatedData };
    }

    case RENDERSAVEDSEARCH: {
      return { ...state, find_data: action.search_data };
    }

    case RESETSELECTEDAD: {
      return {
        ...state,
        ad: null,
        car_model: [],
        ad_data: {},
        general_data: {},
        vehicle_data: {},
        condition_maintenance: {},
        optional_equipmentids: [],
        engine_environment: {},
        fuel_consumption: {},
        vehicle_location: {},
        ad_description: null,
        vehicleData18: [],
        vehicleData24: [],
        targaCode18: null,
        targaCode24: null,
      };
    }

    case CHECKBOXCHANGEHANDLER: {
      const { id, propName, arrayName } = action;
      return {
        ...state,
        [arrayName]: state[arrayName].map((item) =>
          id === item[propName]
            ? { ...item, checked: !item.checked }
            : { ...item, checked: false }
        ),
      };
    }

    case RESETCHECKBOX: {
      const { arrayName } = action;
      return {
        ...state,
        [arrayName]: state[arrayName].map((item) => {
          return { ...item, checked: false };
        }),
      };
    }

    case RESETADS: {
      return { ...state, ads: [] };
    }

    case RESETPOWER: {
      return { ...state, power: [] };
    }

    case RESETDOORCOUNT: {
      return { ...state, doorcount: [] };
    }

    case RESETNUMBEROFSEATS: {
      return { ...state, numberofseats: [] };
    }

    case ISADCREATED: {
      return { ...state, adCreated: action.payload };
    }

    case RESETCHECKBOXESINOBJECT: {
      const objectName = action.objectName;
      const data = state[objectName].map((item) => {
        return { ...item, checked: false };
      });
      return { ...state, [objectName]: data };
    }

    case RESETCARDATA: {
      return {
        ...state,
        doors: [],
        seats: [],
        numberofcylinders: [],
        gears: [],
        gearbox: [],
        curbweight: [],
        power: [],
      };
    }

    case RESETMOTORS: {
      return { ...state, motors: [] };
    }

    // case SETPUBLISHAD: {
    //   return { ...state, readyToPublish: action.boolean };
    // }

    //  Set ad data
    case SETROOTADDATA: {
      return { ...state, ad_data: action.params };
    }

    case SET_ROOT_DATA_PROPS: {
      return {
        ...state,
        ad_data: { ...(state.ad_data ?? {}), ...(action.params ?? {}) },
      };
    }

    case SETGENERALDATA: {
      return { ...state, general_data: action.params };
    }

    case SETVEHICLEDATA: {
      return { ...state, vehicle_data: action.params };
    }

    case SETCONDITIONMAINTENANCE: {
      return {
        ...state,
        condition_maintenance: {
          ...state.condition_maintenance,
          ...action.params,
        },
      };
    }

    case SETOPTIONALEQUIPMENTSIDS: {
      return { ...state, optional_equipmentids: action.params };
    }

    case SETOPTIONALEURONORMIDS: {
      return { ...state, optional_euro_normids: action.params };
    }

    case SETENGINEENVIRONMENT: {
      return { ...state, engine_environment: action.params };
    }

    case SETFUELCONSUPTION: {
      return { ...state, fuel_consumption: action.params };
    }

    case SETVEHICLELOCATION: {
      return { ...state, vehicle_location: action.params };
    }

    case SETDESCRIPTION: {
      return { ...state, ad_description: action.description };
    }
    case RESETADPROPS: {
      return {
        ...state,
        ad_data: {},
        general_data: {},
        vehicle_data: {},
        condition_maintenance: {},
        optional_equipmentids: [],
        engine_environment: {},
        fuel_consumption: {},
        vehicle_location: {},
        ad_description: null,
        vehicleData18: [],
        vehicleData24: [],
        targaCode18: null,
        targaCode24: null,
      };
    }

    // socket
    case GETMAKE: {
      sendMessage("getMake");
      return { ...state, loading: true };
    }

    case GETMAKE_RESULT: {
      const data = sortByAlphabet(action.params.params[0], "asc", "name");
      const makeWithAll = JSON.parse(JSON.stringify(data));
      makeWithAll.unshift({
        idmake: -1,
        name: {
          DE: "Alle",
          EN: "All",
          IT: "Tutti",
          FR: "Tous",
        },
      });
      return { ...state, make: data, makeWithAll, loading: false };
    }

    case GETMAKE_ERROR: {
      return { ...state, loading: false };
    }

    case GETMODEL: {
      sendMessage("getModel", [action.idmake]);
      return {
        ...state,
        modelIndex: action.index,
        bothModel: action.bothModel,
        loading: true,
      };
    }

    case GETMODEL_RESULT: {
      const data = sortByAlphabet(action.params.params[0], "asc", "name");
      const carModelWithAll = Array.isArray(data)
        ? JSON.parse(JSON.stringify(data))
        : [];
      if (carModelWithAll?.length > 0) {
        carModelWithAll.unshift({
          idmodel: -1,
          name: {
            DE: "Alle",
            EN: "All",
            IT: "Tutti",
            FR: "Tous",
          },
        });
      }
      if (acceptOnlyNumbers(state.modelIndex)) {
        const oldCarModelWithAll = [...state.carModelWithAll];
        oldCarModelWithAll[state.modelIndex] = carModelWithAll;
        return {
          ...state,
          car_model: data,
          carModelWithAll: oldCarModelWithAll,
          modelIndex: null,
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
        car_model: data,
        carModelWithAll: state.bothModel
          ? carModelWithAll
          : state.carModelWithAll,
      };
    }

    case GETMODEL_ERROR: {
      return { ...state, loading: false };
    }

    case GETFUELTYPE: {
      sendMessage("getFuelType");
      return state;
    }

    case GETFUELTYPE_RESULT: {
      const transformedData = transformData(
        action.params.params[0],
        ["checked"],
        [false]
      );
      const fuelTypeWithAll = JSON.parse(JSON.stringify(transformedData));
      fuelTypeWithAll.unshift({
        idfueltype: -1,
        name: {
          de: "Alle",
          en: "All",
          it: "Tutti",
          fr: "Tous",
        },
      });
      return { ...state, fuel_type: transformedData, fuelTypeWithAll };
    }

    case GETFUELTYPE_ERROR: {
      return state;
    }

    case GETBODYTYPE: {
      sendMessage("getBodyType");
      return state;
    }

    case GETBODYTYPE_RESULT: {
      const transformedData = transformData(
        action.params.params[0],
        ["checked", "src"],
        [false, BODY_TYPE_IMAGES],
        1
      );
      const bodyTypeWithAll = JSON.parse(JSON.stringify(transformedData));
      bodyTypeWithAll.unshift({
        idbodytype: -1,
        name: {
          de: "Alle",
          en: "All",
          it: "Tutti",
          fr: "Tous",
        },
      });
      return { ...state, body_type: transformedData, bodyTypeWithAll };
    }

    case GETBODYTYPE_ERROR: {
      return state;
    }

    case GETBODYTYPEFORLOCALE: {
      sendMessage("getBodyTypeForLocale", [state.idlocale]);
      return {
        ...state,
      };
    }

    case GETBODYTYPEFORLOCALE_RESULT: {
      return {
        ...state,
      };
    }

    case GETBODYTYPEFORLOCALE_ERROR: {
      return {
        ...state,
      };
    }

    case GETCONDITIONFORLOCALE: {
      sendMessage("getConditionForLocale", [state.idlocale]);
      return { ...state };
    }

    case GETCONDITIONFORLOCALE_RESULT: {
      return { ...state, condition: action.params.params[0] };
    }

    case GETCONDITIONFORLOCALE_ERROR: {
      return { ...state };
    }

    case GETUPHOLSTERYFORLOCALE: {
      sendMessage("getUpholsteryForLocale", [state.idlocale]);
      return { ...state };
    }

    case GETUPHOLSTERYFORLOCALE_RESULT: {
      return { ...state, upholstery: action.params.params[0] };
    }

    case GETUPHOLSTERYFORLOCALE_ERROR: {
      return { ...state };
    }

    case GETINTERIORCOLORFORLOCALE: {
      sendMessage("getInteriorColorForLocale", [state.idlocale]);
      return { ...state };
    }

    case GETINTERIORCOLORFORLOCALE_RESULT: {
      const interiorcolor = sortByNumeric(
        action.params.params[0],
        "asc",
        "idinteriorcolor"
      );
      return { ...state, interiorcolor };
    }

    case GETINTERIORCOLORFORLOCALE_ERROR: {
      return {
        ...state,
      };
    }

    case GETTRANSMISSIONFORLOCALE: {
      sendMessage("getTransmissionForLocale", [state.idlocale]);
      return { ...state };
    }

    case GETTRANSMISSIONFORLOCALE_RESULT: {
      const transmission = sortByAlphabet(
        action.params.params[0],
        "asc",
        "name"
      );

      const transformedData = transmission.map((item) => {
        return { ...item, checked: false };
      });
      const transmissionWithAll = JSON.parse(JSON.stringify(transformedData));
      transmissionWithAll.unshift({
        idtransmission: -1,
        name: {
          DE: "Alle",
          EN: "All",
          IT: "Tutti",
          FR: "Tous",
        },
      });
      return { ...state, transmission: transformedData, transmissionWithAll };
    }

    case GETTRANSMISSIONFORLOCALE_ERROR: {
      return { ...state };
    }

    case GETBODYCOLORFORLOCALE: {
      sendMessage("getBodyColorForLocale", [state.idlocale]);
      return { ...state };
    }

    case GETBODYCOLORFORLOCALE_RESULT: {
      const bodyColors = sortByNumeric(
        action.params.params[0],
        "asc",
        "idbodycolor"
      );
      return { ...state, bodyColors };
    }

    case GETBODYCOLORFORLOCALE_ERROR: {
      return { ...state };
    }

    case GETMOTORSFORMAKEMODELFUELBODY: {
      sendMessage("getMotorsForMakeModelFuelBody", action.params);
      return { ...state };
    }

    case GETMOTORSFORMAKEMODELFUELBODY_RESULT: {
      let data = [];

      if (action.params.params[0].length > 0) {
        data = action.params.params[0].map((item) => {
          return { ...item, name: item.name };
        });
      }
      return { ...state, motors: data };
    }

    case GETMOTORSFORMAKEMODELFUELBODY_ERROR: {
      return { ...state };
    }

    case GETCARDATAFORMOTORID: {
      sendMessage("getCarDataForMotorId", [action.idmotor]);
      return { ...state };
    }

    case GETCARDATAFORMOTORID_RESULT: {
      const data = action.params.params[0];

      const doors = [{ iddoors: data[0].iddoors, name: data[0].doorcount }];
      const seats = [{ idseats: data[0].idseats, name: data[0].numberofseats }];
      const gears = [{ idgears: data[0].idgears, name: data[0].numberofgears }];
      const gearbox = [{ idgears: data[0].idgearbox, name: data[0].gearbox }];
      const power = [
        { name: `${data[0].power.toString()} PS`, power: data[0].power },
      ];
      const curbweight = [
        { name: data[0].curbweight.toString(), curbweight: data[0].curbweight },
      ];
      const numberofcylinders = [
        {
          name: data[0].numberofcylinders.toString(),
          numberofcylinders: data[0].numberofcylinders,
        },
      ];
      return {
        ...state,
        doors: doors,
        seats: seats,
        gears: gears,
        gearbox: gearbox,
        power: power,
        curbweight: curbweight,
        numberofcylinders: numberofcylinders,
      };
    }

    case GETCARDATAFORMOTORID_ERROR: {
      return { ...state };
    }

    case GETLOCALE: {
      sendMessage("getLocale");
      return { ...state };
    }

    case GETLOCALE_RESULT: {
      const filteredLocales = action.params.params[0].filter(
        (locale) => locale.is_active
      );
      // Convert locale to lowercase for each item in filteredLocales
      const updatedLocales = filteredLocales.map((locale) => ({
        ...locale,
        locale: locale.locale.toLowerCase(),
      }));
      const locales = sortByNumeric(updatedLocales, "asc", "seq");
      const idlocale = getCurrentLocale(locales);
      return { ...state, locales: locales, idlocale: idlocale };
    }

    case GETLOCALE_ERROR: {
      return { ...state };
    }

    case GETVERSION: {
      sendMessage("getVersion", [action.idmake, action.idmodel]);
      return state;
    }

    case GETVERSION_RESULT: {
      return { ...state, version: action.params.params[0] };
    }

    case GETVERSION_ERROR: {
      return state;
    }

    case CREATEAD: {
      const withUpload = action.photosLength > 0 ? true : false;
      const adID = action.adData.adID;
      if (action.adData?.adID) {
        delete action.adData.adID;
      }
      if (action.adData?.photos) {
        delete action.adData.photos;
      }
      sendMessage("createAd", [action.adData, adID ? adID : null]);
      return { ...state, loading: true, withUpload };
    }

    case CREATEAD_RESULT: {
      return {
        ...state,
        adId: action.params.params[0],
        adCreated: !state.withUpload,
        loading: false,
        draftSaved: true,
      };
    }

    case CREATEAD_ERROR: {
      return { ...state, createAdFailed: true, loading: false };
    }

    case PUBLISHAD: {
      sendMessage("publishAd", [action.idad]);
      return { ...state, loading: true };
    }

    case PUBLISHAD_RESULT: {
      return {
        ...state,
        readyToPublish: false,
        draftSaved: false,
        loading: false,
      };
    }

    case PUBLISHAD_ERROR: {
      return { ...state, loading: false };
    }

    case UNPUBLISHAD: {
      sendMessage("unpublishAd", [action.idad]);
      return { ...state, loading: true, adUnpublished: false };
    }

    case UNPUBLISHAD_RESULT: {
      return { ...state, loading: false, adUnpublished: true };
    }

    case UNPUBLISHAD_ERROR: {
      return { ...state, loading: false, adUnpublished: false };
    }

    case DELETEADS: {
      sendMessage("deleteAds", [[action.idad]]);
      return { ...state, loading: true };
    }
    case DELETEADS_RESULT: {
      return { ...state, loading: false };
    }

    case DELETEADS_ERROR: {
      return { ...state, loading: false };
    }

    case SEARCH: {
      const searchPayload = prepareDataForSocetRequest(
        state.search_data,
        "search"
      );
      sendMessage("searchAds", searchPayload);
      return { ...state };
    }

    case SEARCH_RESULT: {
      return { ...state, ads: action.params.params[0], loading: false };
    }

    case SEARCH_ERROR: {
      return {
        ...state,
      };
    }

    case FINDADS: {
      const findPayload = prepareDataForSocetRequest(state.find_data, "find");
      if (Array.isArray(findPayload[1])) {
        const newMake = [];
        findPayload[1].forEach((item) => {
          if (item !== null) {
            newMake.push(item);
          }
        });
        findPayload[1] =
          newMake[0] === null || newMake.length === 0 ? null : newMake;
      }
      if (Array.isArray(findPayload[2])) {
        const newModel = [];
        findPayload[2].forEach((item) => {
          if (item !== null) {
            newModel.push(item);
          }
        });
        findPayload[2] =
          newModel[0] === null || newModel.length === 0 ? null : newModel;
      }
      if (Array.isArray(findPayload[3])) {
        const newBodyType = findPayload[3].filter((item) => item !== null);
        findPayload[3] = newBodyType.length === 0 ? null : newBodyType;
      }
      if (Array.isArray(findPayload[10])) {
        const newBodyType = [];
        findPayload[10].forEach((item) => {
          if (item !== null) {
            newBodyType.push(item);
          }
        });
        findPayload[10] =
          newBodyType[0] === null || newBodyType.length === 0
            ? null
            : newBodyType;
      }
      sendMessage("findAds", findPayload);
      return { ...state, loading: true };
    }

    case FINDADS_RESULT: {
      const { ads, stats } = action.params.params[0];
      const { ads_total, ads_found } = stats;
      return {
        ...state,
        ads: ads,
        ads_found: ads_found,
        ads_total: ads_total,
        loading: false,
      };
    }

    case FINDADS_ERROR: {
      return { ...state, loading: false };
    }
    case FINDADS2: {
      const findPayload = prepareDataForSocetRequest(state.find_data, "find");
      if (Array.isArray(findPayload[1])) {
        const newMake = [];
        findPayload[1].forEach((item) => {
          if (item !== null) {
            newMake.push(item);
          }
        });
        findPayload[1] =
          newMake[0] === null || newMake.length === 0 ? null : newMake;
      }
      if (Array.isArray(findPayload[2])) {
        const newModel = [];
        findPayload[2].forEach((item) => {
          if (item !== null) {
            newModel.push(item);
          }
        });
        findPayload[2] =
          newModel[0] === null || newModel.length === 0 ? null : newModel;
      }
      if (Array.isArray(findPayload[3])) {
        const newBodyType = findPayload[3].filter((item) => item !== null);
        findPayload[3] = newBodyType.length === 0 ? null : newBodyType;
      }
      if (Array.isArray(findPayload[10])) {
        const newBodyType = [];
        findPayload[10].forEach((item) => {
          if (item !== null) {
            newBodyType.push(item);
          }
        });
        findPayload[10] =
          newBodyType[0] === null || newBodyType.length === 0
            ? null
            : newBodyType;
      }
      sendMessage("findAds2", findPayload);
      return { ...state, loading: true };
    }

    case FINDADS2_RESULT: {
      const { ads, stats } = action.params.params[0];
      const { ads_total, ads_found } = stats;
      return {
        ...state,
        ads: ads,
        ads_found: ads_found,
        ads_total: ads_total,
        loading: false,
      };
    }

    case FINDADS2_ERROR: {
      return { ...state, loading: false };
    }

    case FINDADS3: {
      const findPayload = prepareDataForSocetRequest(state.find_data, "find");
      sendMessage("findAds3", [findPayload]);
      return { ...state, loading: true };
    }

    case FINDADS3_RESULT: {
      const { ads, stats } = action.params.params[0];
      const { ads_total, ads_found } = stats;
      return {
        ...state,
        ads: ads,
        ads_found: ads_found,
        ads_total: ads_total,
        loading: false,
      };
    }

    case FINDADS3_ERROR: {
      return { ...state, loading: false };
    }

    case FINDDEALERS: {
      const dealerName = state.dealer_find_data?.dealerName ?? "";
      const location = state.dealer_find_data?.location ?? "";
      const radius = state.dealer_find_data?.radius ?? null;
      const payload = {
        first: dealerName === "" ? null : dealerName,
        second: location === "" ? null : location,
        third: radius,
        fromCount: state.dealer_find_data?.fromCount,
        limitCount: state.dealer_find_data?.limitCount,
      };
      sendMessage("findDealers", [payload]);
      return { ...state, loading: true };
    }

    case FINDDEALERS_RESULT: {
      const { dealers, stats } = action.params.params[0];
      const { dealers_found, dealers_total } = stats;
      return {
        ...state,
        dealers,
        dealers_found,
        dealers_total,
        loading: false,
      };
    }

    case FINDDEALERS_ERROR:
      return { ...state, loading: false };

    case SETDEALERFINDPROPS: {
      const { key, value } = action;
      const data = { ...state.dealer_find_data };
      data[key] = value;
      return { ...state, dealer_find_data: data };
    }

    case GETMERCHANT2: {
      sendMessage("getMerchant2", [action.uid]);
      return { ...state, loading: true };
    }

    case GETMERCHANT2_RESULT: {
      return { ...state, merchant: action.params.params[0], loading: false };
    }

    case GETMERCHANT2_ERROR: {
      return { ...state, loading: false };
    }

    case GETAD: {
      sendMessage("getAd", [action.idad]);
      return { ...state, loading: true };
    }

    case GETAD_RESULT: {
      const res = action.params.params[0];
      let conditionmaintenance = mapConditionMaintenance(res);
      sendMessage("getMerchant2", [res[0]?.uidowner]);
      return {
        ...state,
        ad: res[0],
        loading: false,
        ad_data: {
          adID: res[0]?.idad,
          month: res[0]?.month,
          year: res[0]?.year,
          mileage: res[0]?.mileage,
          idmake: res[0]?.idmake,
          idmodel: res[0]?.idmodel,
          idfueltype: res[0]?.idfueltype,
          idbodytype: res[0]?.idbodytype,
          description: res[0]?.description ?? "",
          headline: res[0]?.headline ?? "",
          optionalequipmentids: res[0]?.optionalequipmentids
            ? [...res[0].optionalequipmentids]
            : [],
          photos: res[0]?.photos
            ? res[0].photos.map((item, index) => ({
                src: item,
                id: index,
                created: true,
              }))
            : [],
          price: res[0]?.price,
          generaldata: { ...res[0]?.generaldata },
          vehicledata: { ...res[0]?.vehicledata },
          engineenvironment: { ...res[0]?.engineenvironment },
          conditionmaintenance: { ...conditionmaintenance },
          vehiclelocation: { ...res[0]?.vehiclelocation },
        },
        photos: res[0]?.photos
          ? res[0].photos.map((item, index) => ({
              src: item,
              id: index,
              created: true,
            }))
          : [],
        general_data: { ...res[0]?.generaldata },
        vehicle_data: { ...res[0]?.vehicledata },
        condition_maintenance: { ...conditionmaintenance },
        optional_equipmentids: res[0]?.optionalequipmentids
          ? [...res[0].optionalequipmentids]
          : [],
        engine_environment: { ...res[0]?.engineenvironment },
        fuel_consumption: res[0]?.engineenvironment?.fuelconsumption
          ? { ...res[0].engineenvironment.fuelconsumption }
          : {},
        vehicle_location: { ...res[0]?.vehiclelocation },
        ad_description: res[0]?.description ?? "",
      };
    }

    case GETAD_ERROR: {
      return { ...state, loading: false };
    }

    case GETADSFOROWNER: {
      sendMessage("getAdsForOwner");
      return { ...state, loading: true };
    }

    case GETADSFOROWNER_RESULT: {
      const data = action.params.params[0];
      if (!Array.isArray(data)) {
        return { ...state, myCars: [], loading: false };
      }
      const newData = [];
      const pinAds = JSON.parse(localStorage.getItem("pinAds")) ?? [];
      data?.forEach((item) => {
        if (pinAds.includes(item.idad)) {
          newData.unshift({ ...item, pin: true });
        } else {
          newData.push({ ...item, pin: false });
        }
      });
      return { ...state, myCars: newData, loading: false };
    }

    case GETADSFOROWNER_ERROR: {
      return { ...state, loading: false };
    }

    case GETADSFORIDS: {
      sendMessage("getAdsForIds", [action.ids]);
      return { ...state, loading: true };
    }
    case GETADSFORIDS_RESULT: {
      return { ...state, loading: false, favoriteAds: action.params.params };
    }
    case GETADSFORIDS_ERROR: {
      return { ...state, loading: false };
    }

    case GETVEHICLESCOUNTBYBODYTYPE: {
      sendMessage("getVehiclesCountByBodyType");
      return { ...state, loading: true };
    }
    case GETVEHICLESCOUNTBYBODYTYPE_RESULT: {
      const data = action.params.params[0];
      if (!Array.isArray(data)) {
        return { ...state, loading: false };
      }
      return { ...state, bodyTypeCount: data, loading: false };
    }
    case GETVEHICLESCOUNTBYBODYTYPE_ERROR: {
      return { ...state, loading: false };
    }

    case GETDOORS: {
      sendMessage("getDoors", []);
      return { ...state };
    }

    case GETDOORS_RESULT: {
      return { ...state, doors: action.params.params[0] };
    }

    case GETDOORS_ERROR: {
      return {
        ...state,
      };
    }

    case GETSEATS: {
      sendMessage("getSeats", []);
      return state;
    }

    case GETSEATS_RESULT: {
      return { ...state, seats: action.params.params[0] };
    }

    case GETSEATS_ERROR: {
      return {
        ...state,
      };
    }

    case GETEURONORM: {
      sendMessage("getEuroNorm", []);
      return { ...state };
    }

    case GETEURONORM_RESULT: {
      const transformedData = transformData(
        action.params.params[0],
        ["checked"],
        [false]
      );
      const euronormWithAll = JSON.parse(JSON.stringify(transformedData));
      euronormWithAll.unshift({
        ideuronorm: -1,
        name: {
          de: "Alle",
          en: "All",
          it: "Tutti",
          fr: "Tous",
        },
      });
      return { ...state, euronorm: transformedData, euronormWithAll };
    }

    case GETEURONORM_ERROR: {
      return {
        ...state,
      };
    }

    case GETTRAILERHITCH: {
      sendMessage("getTrailerHitch", []);
      return { ...state };
    }

    case GETTRAILERHITCH_RESULT: {
      const transformedData = transformData(
        action.params.params[0],
        ["checked"],
        [false]
      );
      const trailerhitchWithAll = JSON.parse(JSON.stringify(transformedData));
      trailerhitchWithAll.unshift({
        idtrailerhitch: -1,
        name: {
          de: "Alle",
          en: "All",
          it: "Tutti",
          fr: "Tous",
        },
      });
      return { ...state, trailerhitch: transformedData, trailerhitchWithAll };
    }

    case GETTRAILERHITCH_ERROR: {
      return {
        ...state,
      };
    }

    case GETENERGYEFFICIENCE: {
      sendMessage("getEnergyEfficience", []);
      return { ...state };
    }

    case GETENERGYEFFICIENCE_RESULT: {
      return { ...state, energyEfficience: action.params.params[0] };
    }

    case GETENERGYEFFICIENCE_ERROR: {
      return {
        ...state,
      };
    }

    case REPORTAD: {
      sendMessage("reportAd", action.params);
      return { ...state, loading: true };
    }

    case REPORTAD_RESULT: {
      return {
        ...state,
        loading: false,
        readyToReport: true,
      };
    }

    case REPORTAD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case FETCHEPAPER: {
      sendMessage("fetchEpaper");
      return { ...state };
    }

    case FETCHEPAPER_RESULT: {
      return {
        ...state,
        ePaperData: action.params.params[0],
      };
    }

    case FETCHEPAPER_ERROR: {
      return {
        ...state,
        ePaperData: null,
      };
    }

    case GETCARSOFTHEDAY: {
      sendMessage("getCarsOfTheDay", []);
      return { ...state, loading: true };
    }

    case GETCARSOFTHEDAY_RESULT: {
      return {
        ...state,
        carsOfTheDay: action.params.params[0],
        loading: false,
      };
    }

    case GETCARSOFTHEDAY_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case GETDRIVETYPE: {
      sendMessage("getDriveType");
      return state;
    }

    case GETDRIVETYPE_RESULT: {
      const transformedData = transformData(
        action.params.params[0],
        ["checked"],
        [false]
      );
      const driveTypeWithAll = JSON.parse(JSON.stringify(transformedData));
      driveTypeWithAll.unshift({
        iddrivetype: -1,
        name: {
          de: "All",
          en: "All",
          it: "All",
          fr: "All",
        },
      });
      return { ...state, drive_type: transformedData, driveTypeWithAll };
    }

    case GETDRIVETYPE_ERROR: {
      return state;
    }

    case FETCHARTICLES: {
      sendMessage("fetchArticles", [action.lang]);
      return { ...state };
    }

    case FETCHARTICLES_RESULT: {
      return {
        ...state,
        articlesData: action.params.params,
      };
    }

    case FETCHARTICLES_ERROR: {
      return {
        ...state,
        articlesData: null,
      };
    }

    case GETCYLINDERS: {
      sendMessage("getCylinders", []);
      return { ...state };
    }

    case GETCYLINDERS_RESULT: {
      const transformedData = transformData(
        action.params.params[0],
        ["checked"],
        [false]
      );
      const cylindersWithAll = JSON.parse(JSON.stringify(transformedData));
      cylindersWithAll.unshift({
        idcylinder: -1,
        name: {
          de: "Alle",
          en: "All",
          it: "Tutti",
          fr: "Tous",
        },
      });
      return { ...state, cylinders: transformedData, cylindersWithAll };
    }

    case GETCYLINDERS_ERROR: {
      return {
        ...state,
      };
    }

    case GETTRAILERLOAD: {
      sendMessage("getTrailerLoad", []);
      return { ...state };
    }

    case GETTRAILERLOAD_RESULT: {
      const transformedData = action.params.params[0];

      const trailerLoadWithAll = JSON.parse(JSON.stringify(transformedData));
      trailerLoadWithAll.unshift({
        idtrailerload: -1,
        name: {
          de: "Alle",
          en: "All",
          it: "Tutti",
          fr: "Tous",
        },
      });
      return { ...state, trailerload: transformedData, trailerLoadWithAll };
    }

    case GETTRAILERLOAD_ERROR: {
      return {
        ...state,
      };
    }

    case GETOPT: {
      sendMessage("getOpt", []);
      return { ...state };
    }

    case GETOPT_RESULT: {
      return { ...state, equipment: action.params.params[0] };
    }

    case GETOPT_ERROR: {
      return {
        ...state,
      };
    }

    case GETVEHICLESFORSERIALNO: {
      const { code18, complete } = action.params;
      sendMessage("getVehiclesForSerialNo", [code18, complete]);
      return { ...state, vehicleData18Error: false };
    }

    case GETVEHICLESFORSERIALNO_RESULT: {
      const obj = {};
      // use this when we fetch only specific targa18 vehicle
      if (action.params.request.params[1]) {
        obj.selectedTargaData = action.params.params[0];
      } else {
        // use this when we fetch list of all targa18 vehicles
        obj.vehicleData18 = action.params.params[0];
      }
      return {
        ...state,
        ...obj,
        vehicleData18Error: false,
        targaCode18: action.params.request.params[0],
      };
    }

    case GETVEHICLESFORSERIALNO_ERROR: {
      return {
        ...state,
        vehicleData18Error: true,
      };
    }

    case GETVEHICLESFORREGDOCNO: {
      const { code24, complete } = action.params;
      sendMessage("getVehiclesForRegDocNo", [code24, complete]);
      return { ...state, vehicleData24Error: false };
    }

    case GETVEHICLESFORREGDOCNO_RESULT: {
      const obj = {};
      // use this when we fetch only specific targa24 vehicle
      if (action.params.request.params[1]) {
        obj.selectedTargaData = action.params.params[0];
      } else {
        // use this when we fetch list of all targa24 vehicles
        obj.vehicleData24 = action.params.params[0];
      }
      return {
        ...state,
        ...obj,
        vehicleData24Error: false,
        targaCode24: action.params.request.params[0],
      };
    }

    case GETVEHICLESFORREGDOCNO_ERROR: {
      return {
        ...state,
        vehicleData24Error: true,
      };
    }

    case SET_TARGA_VEHICLE_TYPE: {
      return {
        ...state,
        vehicleType: action.vehicleType,
      };
    }

    case RESETERRORS18AND24: {
      return { ...state, vehicleData24Error: false, vehicleData18Error: false };
    }

    case RESETVEHICLEDATA18AND24: {
      return { ...state, vehicleData24: [], vehicleData18: [] };
    }

    case GETENGINETYPE: {
      sendMessage("getEngineType", []);
      return { ...state };
    }

    case GETENGINETYPE_RESULT: {
      return { ...state, enginetype: action.params.params[0] };
    }

    case GETENGINETYPE_ERROR: {
      return {
        ...state,
      };
    }

    case GETENVIRONMENTALSTICKER: {
      sendMessage("getEnvironmentalSticker", []);
      return { ...state };
    }

    case GETENVIRONMENTALSTICKER_RESULT: {
      return { ...state, emissionsticker: action.params.params[0] };
    }

    case GETENVIRONMENTALSTICKER_ERROR: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}

export default reducer;
