import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import CheckMark from "../../../assets/images/check-mark.svg";
import Carindex from "../../../assets/images/new-logo.svg";
import { publishAd, resetAdProps } from "../../../store/actions";
import { ImageUploadStatus } from "../../../types";

import "./index.css";

const AdDetected = (props) => {
  const {
    dispatch,
    isCreateAdPage,
    adId,
    readyToPublish,
    draftSaved,
    ad_images,
  } = props;
  const { t } = useTranslation("sell");

  useEffect(() => {
    const notProcesedImages = (ad_images ?? []).filter(
      (image) =>
        image.status === ImageUploadStatus.NOT_PROCESSED || !image.status
    )?.length;
    if (adId !== null && draftSaved && notProcesedImages === 0) {
      dispatch(publishAd(adId));
    }
    return () => {
      dispatch(resetAdProps());
    };
  }, [adId, ad_images, draftSaved, dispatch]);

  return (
    <div className="ad-detected">
      <div className="header">
        <img src={CheckMark} alt="check-mark" />
        <span>{t("sell:approved")}</span>
      </div>
      <h5>{t("sell:congratulation")}</h5>
      <p>
        {isCreateAdPage
          ? t("sell:ad-successfully-detected")
          : t("sell:ad-successfully-updated")}
      </p>
      <div className="info">
        <p>{t("sell:ad-detected-instruction-pt1")}</p>
        <p>{t("sell:ad-detected-instruction-pt2")}</p>
      </div>
      <img src={Carindex} alt="carindex-logo" />
    </div>
  );
};

const stateToProps = (state) => {
  const { adId, readyToPublish, draftSaved } = state.car;
  const { ad_images } = state.common;
  return {
    adId,
    readyToPublish,
    draftSaved,
    ad_images,
  };
};

export default connect(stateToProps)(AdDetected);
